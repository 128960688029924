// ###############################
//
// General Page Layout
//
// ###############################

.ion-page {
    background-color: #eeeef2;
}

ion-header {
    background: rgba(5,118,176,1);
    border-bottom: rgba(53,131,102,1) 5px solid;
    box-shadow: 0 2px 5px rgba(0,0,0,0.25);

    &::after {
        display: none;
    }

    ion-toolbar {
        text-align: center;
        color: #fff;
        --background: rgba(0,0,0,0.15);

        .button, .button::part(native) {
            margin: 0;
            padding: 9px;
            font-size: 1.25em;
            background: none;
            /*border: 0;*/
            &::before {
                font-weight: 900;
            }
        }

        .button::part(native) {
            padding: 0;
        }

    }

}

ion-content {
    --padding-start: 15px;
    --padding-end: 15px;
    --padding-top: 15px;
    --padding-bottom: 15px;
    --background: none;

    div[class*=headerImage] {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 37.5%;
        height: 250px;
        overflow: hidden;

        img {
            /* Note: Images have a max-width constraint of 100% by default. */
            position: absolute;
            min-width: 100%;
            min-height: 100%;
            top: -9999em;
            bottom: -9999em;
            left: -9999em;
            right: -9999em;
            margin: auto;
        }

        /* The "&" markup assures deeper specificity without !important. */

        &.headerImageShort {
            max-height: 13.5%;
            height: 90px;
        }

        &.headerImageMedium {
            max-height: 37.5%;
            height: 250px;
        }

        &.headerImageTall {
            max-height: 60%;
            height: 400px;
        }

    }

    &.headerImageShort {
        --padding-top: 105px;
        .sectionMenu {
            margin-top: -45px;
        }
    }

    &.headerImageMedium {
        --padding-top: 265px;
        .sectionMenu {
            margin-top: -45px;
        }
    }

    &.headerImageTall {
        --padding-top: 415px;
        .sectionMenu {
            margin-top: -45px;
        }
    }

}

@media screen and (max-width: 600px) {
    
    ion-content {
        .headerImageTall {
            img {
                /* Switch from max-width to max-height constraint on small/thin screens for taller images. Header images are usually wide so this should work well in general. */
                max-width: none;
                max-height: 100%;
                width: auto;
            }
        }
    }

}

@media screen and (max-width: 480px) {
    
    ion-content {
        .headerImageMedium {
            img {
                /* Switch from max-width to max-height constraint on small/thin screens for taller images. Header images are usually wide so this should work well in general. */
                max-width: none;
                max-height: 100%;
                width: auto;
            }
        }
    }

}

.pageTemplateBlue01 {

    align-items: center;
    --ion-background-color: rgba(5,118,176,1);
    background-color: rgba(5,118,176,1);
    background-image: url("../assets/imgs/photos/photo_unsw2.png");
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;

    &::after {
        content: "";
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(5,118,176,0.85);
    }

    ion-header {
        border: 0;
        ion-toolbar {
            background-color: rgba(0,0,0,0.35);
        }
    }

    ion-content {
        z-index: 2;
        @extend .width-fixed;
        @extend .width-thin;
    }

    a {
        color: #fff;
        font-weight: bold;
    }

    .button {
        color: #00476e !important;
        background: #fff;
        &:hover {
            color: #fff !important;
        }
    }
    
    ion-item {
        color: #000;
    }

    ion-item.item-interactive {
        margin-bottom: 1em;
        padding-inline-start: 0;
        padding-inline-end: 0;
        border-bottom: rgba(255,255,255,0.5) 1px solid !important;
        background: rgba(255,255,255,0.25);
        border-radius: 3px;
    }

    ion-item::part(native) {
        --border-style: none;
        background: transparent;
    }

    .item-has-focus.label-stacked.sc-ion-label-md-h:not(.ion-color),
    .item-has-focus .label-stacked-sc-ion-label-md-h:not(.ion-color),
    .item-has-focus.label-floating.sc-ion-label-md-h:not(.ion-color),
    .item-has-focus .label-floating.sc-ion-label-md-h:not(.ion-color) {
        color: #fff;
    }

    .checkboxField {
        padding-left: 0;margin-bottom: 1em;
        padding-inline-start: 0;
        padding-inline-end: 0;
        color: #fff;
        text-align: center;
        /* border: rgba(255, 255, 255, 0.5) 1px solid !important; */
        /* background: rgba(255, 255, 255, 0.25); */
        border-radius: 3px;

        ion-checkbox {
            display: inline-block;
            vertical-align: middle;
            margin: 0 10px 0 0;
        }
    }

    .checkbox-icon {
        background: rgba(0,0,0,0.25) !important;
        border-color: #fff !important;
    }

    :host(.checkbox-checked) .checkbox-icon, 
    :host(.checkbox-indeterminate) .checkbox-icon {
        border-color: #000;
        background: #000;
    }

}

.splashPages {

    #AppTitle {
        margin-top: 30px;
        text-align: center;

        #AppIcon {
            display: inline-block; 
        
            img {
                width: 40%;
            }
        
            img + img {
                margin-left: 30px;
            }
        }

        h1 {
            color: #000;
        }
    }

    #IntroMessage {
        text-align: center;
        color: #f0f0f8;
        margin-bottom: 15px;
        &:last-child {
            /* Sometimes the message is one line of text and sometimes it has multiple paragraphs, so make sure spacing at the end is always the same. */
            margin-bottom: 0;
        }
    }
    
    .form {
        /* Includes: #LoginForm, #RegistrationForm, #PinForm, #PasswordForm, #KeyForm */
        text-align: center;
        color: #f0f0f8;
        margin-bottom: 15px;

        .button {
            margin-bottom: 15px;
        }

        #LoginLinks {
            text-align: center;
        }
        
    }

    #HelpMessage {
        text-align: center;
        color: #f0f0f8;

        .button {
            margin-bottom: 15px;
        }
    }

    #TermsMessage {
        text-align: center;
        color: #f0f0f8;
    }

    .alert-box {
        @extend .padding-all-thin;
    }
}

/*
    App Menu 
    - The main app fly out menu
*/

#AppMenu {
    ion-header {
        background: rgba(5, 118, 176, 1);
    }
    ion-content {
        padding: 0;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        ul {
            margin: 0;
            padding: 0 !important;
            li {
                position: relative;
                display: block !important;
                margin: 0 !important;
                padding: 0 !important;
                list-style: none !important;
                font-size: 16px;
                a {
                    display: block;
                    box-sizing: border-box;
                    width: calc(100% - 35px);
                    margin-left: 35px;
                    padding: 10px;
                    font-weight: 400;
                    color: #000;
                    text-decoration: none;
                    border-bottom: #ddc 1px solid;
                    &:hover {
                        color: #fff;
                        background: #0ba374;
                        transition: 100ms all ease-in-out;
                        &::before {
                            background: #0ba374;
                        }
                    }
                    &::before {
                        /*content: "\f870";*/
                        @include themeicon;
                        position: absolute;
                        left: 0;
                        top: 0;
                        padding: 9px 0 10px;
                        width: 40px;
                    }
                }
            }
        }
    }

}

/*
    Page Components
*/

.notificationsMenu {
    ul {
        @extend .menu-horizontal;
        margin-bottom: 15px !important;
        li {
            a {
                @extend .icon;
                justify-content: flex-start;
                ion-badge {
                    position: absolute;
                    right: 0;
                    --background: #7cc0d7;
                    color: #fff;
                }
            }
            &.notificationsInbox {
                a {
                    &::before {
                        content: "\f310";
                    }
                }
            }
            &.notificationsArchive {
                a {
                    &::before {
                        content: "\f64b";
                    }
                }
            }
        }
    }
}

.sectionMenu {
    ul {
        @extend .menu-horizontal;
        margin: 0 0 15px !important;
        a {
            @extend .icon;
            flex-direction: column;
            line-height: 1;
            font-size: 13px;
            text-transform: uppercase;
            &::before {
                font-size: 18px;
                margin-bottom: 2px;
            }
        }

    }
}

.mealsManagerNav {
    padding-top: 33px;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 82px;
        margin: 0 -15px;
        background-image: url("../assets/imgs/photos/bread_menu.jpg");
        background-image: url("../assets/imgs/photos/nc_kitchen_staff_2018.jpg");
        background-image: url("../assets/imgs/photos/meats_pasta_rice.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
        filter: brightness(0.75);
    }
    ul {
        li {
            /* 
                Just show the first four items.
                To do:  Remove this yucky hack because we can't hide pages from segments right now, but we need this pages that aren't in the tabs in the list so the pages themselves works correctly - e.g. to show tabs on the Meal Calendar page. 
            */
            display: none;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                display: list-item;
            }
        }
    }
}

#RollCall {
    .checkInState,
    .wellness {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .icon {
            width: 40px;
            margin-right: 10px;
            color: #0088b2 !important;
        }
        .status {
            margin-right: 15px;
        }
        .action {
            margin-left: auto;
        }
    }

    .wellness {
        .icon{
            width: 40px;
        }
        .action {
            width: calc(100% - 45px);
            a {
                width: 100%;
            }
        }
    }
}

/*
    Individual Pages

    - Ideally these should be in page-name.page.scss but we're having trouble with Ionic 5's CSS compilation and placing the rules here is a robust workaround for now.
    - .ion-page adds specificity to deal with different pages loading in different application scopes.
*/

app-authorise-add-pin {
    @extend .pageTemplateBlue01;
    @extend .splashPages;

    #PinForm {
        #PinInstructionMessage {
            margin-bottom: 15px;
        }
    }

    #IntroMessage {
        #BiometricOption {
            ion-item {
                margin-bottom: 15px;
                background: rgba(255,255,255,0.4);
                border-radius: 3px;
            }
            ion-toggle {
                --background: #fff;
                --background-checked: #fff;
                --handle-background: #000;
                --handle-background-checked: #0be374;
            }
        }
    }
}

app-authorise-email {
    @extend .pageTemplateBlue01;
    @extend .splashPages;

    #RegistrationForm {
        #EmailField {

        }
        #TermsField {
            color: #fff;
            &:hover {
                background: none;
            }
        }
    }
}

app-authorise-help {
    ion-content {
        .button {
            @extend .button-secondary;
        }
    }
}

app-authorise-key {
    @extend .pageTemplateBlue01;
    @extend .splashPages;

    #KeyForm {
        .button {
            @extend .icon;
            &:before {
                content: "\f058";
                font-weight: bold;
            }
        }
    }

    .emailUnlinked {
    }
}

app-content-update {
        
    #UpdateForm {
        ion-item {
            align-items: start;
            ion-checkbox {
                margin-right: 10px;
            }
            ion-label {
                padding-top: 8px;
            }
        }
    }

}

app-dynamic {
}

app-home {
    ion-header {
        position: absolute;
        z-index: 1;
        width: 100%;
        background: rgba(5,118,176,0.75);
    }

    #Banner {
        position: relative;
        // padding-top: calc(9 / 16 * 100%);
        height: 220px;
        max-height: 60%;
        margin-bottom: 15px;
        #FeatureImage {
            position: absolute;
            top: 0;
            margin: -15px;
            width: calc(100% + 30px);
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url('../assets/imgs/photos/Live-at-UNSW-Banner-1920x600.png');
            background-position: center top;
        }
        #Logo {
            position: absolute;
            z-index: 2;
            bottom: -2px;
            right: 0;
            max-width: 155px;
            img {
                width: calc(50% - 8px);
                &:last-child {
                    margin-left: 16px;
                }
            }
        }
        #WelcomeMessage {
            position: absolute;
            z-index: 1;
            bottom: 15px;
            width: calc(100% + 30px);
            margin: 0 -15px;
            background: rgba(15,118,175,0.65);
            padding: 20px 165px 20px 15px;
            h1 {
                margin: 0;
                font-size: 34px;
                color: #fff;
                text-shadow: 1px 1px 0 #000;
            }

        }
    }

    .notificationCheckRunning {
        @extend .alert-box;
        @extend .alert-information;
        padding: 15px;
        font-size: 14px;
        text-align: center;
        p {
            @extend .spin;
            display: inline-block;
        }
    }

    .notifications {
        margin-bottom: 2em;
        ion-list {
            ion-item {
                ion-icon:first-child {
                    height: 100%;
                    padding-top: 15px;
                    &::before {
                        content: "\f4a6";
                        font-size: 18px;
                    }
                }
            }
        }
    }

    ion-list {
        background: rgba(0,0,0,0.15);
        cursor: pointer;
    }

    ion-item {
        --background: transparent;
        --border-color: transparent;
    }

}

app-login {
    @extend .pageTemplateBlue01;
    @extend .splashPages;

    #PinForm {
        #LoginLinks {
            .forgottenPinPrompt {
                display: block;
            }
        }
    }

    #PasswordForm {
        #LoginLinks {
            a {
                display: inline-inline;
            }
            span {
                display: inline;
            }
        }
    }

    #LoggedIn {
        margin-bottom: 15px;
        text-align: center;
        color: #f0f0f8;
    }
}

app-meal-cafe-credits {

    .pageBody {
        @extend .box;
        @extend .background-white;
        margin-bottom: 0;
        h1.pageTitle {
            text-align: center;
            margin-top: 0;
            .prefix {
                display: block;
                font-size: 75%;
            }
        }
    }

    #CurrentBalance {
        @extend .margin-bottom-thick;
        h2 {
            display: none;
        }
        ul {
            @extend %values-list;
        }
        .lastUpdated {
            padding: 15px;
            text-align: center;
            font-size: 87.5%;
            border: #eef 2px solid;
            border-radius: 3px;
            .label {
            }
            .dateTime {
            }
        }
    }

}

app-meal-calendar {

    ion-item {
        h2 {
            text-align: left;
        }
    }

}

app-meal-credits {

    .pageBody {
        @extend .box;
        @extend .background-white;
        margin-bottom: 0;
        h1.pageTitle {
            text-align: center;
            margin-top: 0;
            .prefix {
                display: block;
                font-size: 75%;
            }
        }
    }

    #CurrentBalance {
        margin-bottom: 0.5em;
        h2 {
            display: none;
        }
        ul {
            @extend %values-list;
        }
        #MealCredits {
            .label {
                .creditsRemaining {
                }
                .creditsValidPeriod {
                    display: block;
                    color: #99a;
                    font-size: 87.5%;
                }
            }
            .value {

            }

        }
        .lastUpdated {
            padding: 15px;
            text-align: center;
            font-size: 87.5%;
            border: #eef 2px solid;
            border-radius: 3px;
            .label {
            }
            .dateTime {
            }
        }
    }

    #MealCreditRights {
        @extend .alert-box;
        @extend .alert-information;
        @extend .margin-bottom-thick;
        .mealCreditsAllowance {
        }
        .mealCreditsLocation {
        }
        .validityPeriod {
            .startDate {
                margin-bottom: 0.75em;
            }
            .label {
                display: inline-block;
                width: 100px;
                font-weight: bold;
            }
            .date {
            }
        }
    }


}

app-meal-current {

    .pageBody {
        @extend .box;
        @extend .background-white;
        text-align: center;
        margin-bottom: 0;
        padding-top: 15px;
        h1.pageTitle {
            margin-top: 0;
            margin-bottom: 0.25em;
            .prefix {
                display: block;
                font-size: 75%;
            }
        }
    }

    #NextMealPage {
        .mealDate {
            margin-bottom: 0.5em;
        }
        .mealTime {
        }
    }

    #NoCreditsPage {
        .mealCreditsToolbar {
            .manageMealCreditsLink {
                @extend .icon;
                &::before {
                    content: "\f788";
                }
            }
            .manageCafeCreditsLink {
                display: block;
                margin-top: 1em;
                @extend .icon;
                &::before {
                    content: "\f788";
                }
            }
        }
    }

    #MealTimePage {
        h1.pageTitle {
            margin-bottom: 0;
            .prefix {
                display: none;
            }
            .mealName {
                font-weight: 300;
            }
        }
        .mealDate {
            margin-bottom: 0.5em;
            font-weight: bold;
            font-size: 170%;
            font-family: Sommet, Arial, Helvetica, sans-serif;
        }
        .mealTime {
            margin-bottom: 0.75em;
        }
        .mealCreditOptions {
            @extend .margin-bottom;
            ul.menu {
                @extend .buttons;
                @extend .boxes;
                li {
                    a {
                        color:#000 !important;
                        background: #eaeaea ;
                        .number {
                            font-size: 200%;
                        }
                        &.disabled {
                            color: #ccd !important;
                            background: #f8f8fc;
                            box-shadow: none;
                            &:hover, &:hover * {
                                color: #ccd !important;
                                background: #f8f8fc;
                            }
                        }
                    }
                }
            }
        }
        .mealCredits {
            margin-bottom: 0.75em;
            .mealCreditsCount {
            }
            .mealCreditsRemaining {
            }
        }
        .mealCreditsToolbar {
            .manageMealCreditsLink {
                @extend .icon;
                &::before {
                    content: "\f788";
                }
            }
            .manageCafeCreditsLink {
                display: block;
                margin-top: 1em;
                @extend .icon;
                &::before {
                    content: "\f788";
                }
            }
        }
    }

    #MealConfirmPage, #MealConfirmPageSecondCreditExampleOnly {
        h1.pageTitle {
            margin-bottom: 0.25em;
            .prefix {
                display: none;
            }
        }
        .mealDate {
            font-weight: 100;
            font-size: 85%;
            font-weight: bold;
            font-family: Sommet, Arial, Helvetica, sans-serif;
        }

        .mealTimer {
            display: flex;
            justify-content: center;
            margin: 0.25em auto;
            font-weight: bold;
            font-size: 200%;
            line-height: 1em;
            font-family: Sommet, Arial, Helvetica, sans-serif;

            .hour {
                &::after {
                    content: ':';
                    display: inline-block;
                    margin: 0 5px;
                }
            }

            .minute {
                &::after {
                    content: ':';
                    display: inline-block;
                    margin: 0 5px;
                }

            }

            .second {
                width: 50px;
                text-align: left;
            }
        }

        .mealTime {
            margin-bottom: 0.75em;
        }
        .mealCreditsConfirmed {
            @extend .icon;
            @extend .icon-circle-dark;
            @extend .icon-user-check;
            color: #000;
            margin: 0 0 15px;
            font-size: 130%; /* The biggest it can be for the longest message on smallest screens. Even so the "extra credit used" message spills to two lines and looks ugly on many screens.  */
            &::before {
                background-color: #358366;
                font-size: 18px;
            }
            .mealCreditsCount {
            }
            .mealCreditsUsed {
            }
            @media screen and (max-width: 375px) {
                margin-bottom: 0.25em;
            }
        }
        .userInfo {
            padding: 0 30px;
            .userName {
                margin: 0 0 0.5em;
            }
            .userImage {
                @extend .ratio;
                @extend .ratio-square;
                @extend .margin-bottom;
                overflow: hidden;
                max-width: 200px;
                margin-left: auto;
                margin-right: auto;
                background: rgba(5,118,175,1);
                border-radius: 100%;
                .userInitials {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-size: 96px;
                }
            }
        }
        .mealCreditOptions {
            @extend .margin-bottom;
            .extraCreditPrompt {
                @extend .icon;
                @extend .icon-medium;
                @extend .icon-circle-dark;
                &::before {
                    content: "\f858";
                }
            }
            ul.menu {
                li {
                    a {
                        
                        &.disabled {
                            color: #ccd !important;
                            background: #f8f8fc;
                            box-shadow: none;
                            &:hover, &:hover *, &:after {
                                color: #ccd !important;
                                background: #f8f8fc;
                            }
                        }
                    }
                }
            }
        }
    }

    .pageBody + .pageBody {
        margin-top: 15px;
    }

}

app-meal-diet {
}

app-meal-request-add-edit {
}

app-meal-requests {
}

app-meal-schedule, app-meal-calendar {

    .pageBody {
        @extend .box;
        @extend .background-white;
        margin-bottom: 15px;
        h1.pageTitle {
            text-align: center;
            margin-top: 0;
            .prefix {
                display: block;
                font-size: 75%;
            }
        }
        h2 {
            text-align: center;
        }
    }

    ion-grid {
        padding: 0;
        margin-bottom: 15px;
        ion-row {
            margin: 5px 0;
            padding-bottom: 5px;
            border-bottom: #99a 1px solid;
        }
        .mealTime {
            .date {
            }
            .times {
                line-height: 125%;
                font-size: 14px;
                color: #99a;
                .startTime {
                }
                .endTime {
                    &::before {
                        content: " - ";
                    }
                }
            }
        }
        .mealInfo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .label {
            }
            .note {
                color: #99a;
                font-size: 15px;
            }
        }
        
        @media screen and (max-width: 600px) {
            font-size: 16px;
        }
    }

    #MealRequest {
        display: none; /* Not used in the NC app */

        text-align: center;
        margin-bottom: 15px;
    }

    #UpcomingMeals {
        .rosterOptions {
            text-align: center;
            .mealsCalendarLink {
                @extend .icon;
                &::before {
                    content: "\f073";
                }
            }
        }
    }

    #SpecialMeals {
        .noSpecialMeals {
            text-align: center;
            p {
                margin: 0;
            }
        }
    }

    #ScheduledMeals {
        #SelectSchedule {
            display: none; /* To do: This should be configurable via a setting. Turn it back on when it is. */
        }
        .scheduleDateRange {
            padding-bottom: 15px;
            text-align: center;
            font-size: 100%;
            font-weight: 500;
            border-bottom: #99a 1px solid;
            .dateRangeConnector {
                display: block;
                font-size: 87.5%;
                font-weight: normal;
            }
        }
        .mealTime {
            .times {
                font-size: 16px;
                color: inherit;
            }
        }
    }

}

app-roll-call {
    .rollCall {
        background-color: #fff;
        padding:20px;
        border-radius: 3px;
        .studentDetailsHeader{
            display: flex;
            font-weight: bold;
            margin-bottom: 10px;
            .spacer {
                width: 25px;
            }
            .nameHeader {
                flex: 1;
            }
            .adminHeader {
                flex:2;
            }
            .timeHeader {
                flex: 1;
                min-width: 70px;
                text-align: end;
                padding-right: 65px;
            }

            @media screen and (max-width: 600px) {
                display: none;
            }
        }
        .students {
            .studentItem {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0.75em 35px 0.75em 0;
                border-top: 2px solid #eaeaea;
                font-size: 90%;
                &:last-of-type {
                border-bottom: 2px solid #eaeaea;
                }
                &.checkedIn {
                    background-color: #358366;
                    color: #fff;
                }
                .rollCallData {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    .fullName {
                        flex: 1;
                    }
                    .rollAdmin {
                        display: flex;
                        flex-direction: column;
                        flex:2;
                        font-size: 90%;
                        font-style: italic;
                        .officerName {
                            
                        }
                        .note {
                        }
                    }
                    .rollCallTime {
                        flex:1;
                        text-align: end;
                        min-width: 70px;
                    }
                }
                
                &:after {
                    content: "\f105";
                    position: absolute;
                    right: 15px;
                    top: 0;
                    width: auto;
                    height: 100%;
                    z-index: 20;
                    display: inline-flex;
                    align-items: center;
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 300;
                    font-size: 30px;
                    color: #000;
                    opacity: 1;
                }
                &:hover {
                    cursor: pointer;
                    background: linear-gradient(0deg, #ffffff 0%, #fdfdfd 10%, #fdfdfd 90%, white 100%);
                    &:after {
                        
                    }
                }
                @media screen and (max-width: 600px ) {
                    .rollCallData {
                        flex-wrap: wrap;

                        .fullName {
                            flex: 1;
                            order: 1;
                        }

                        .rollCallTime {
                            order: 2;
                            text-align: start;
                        }

                        .rollAdmin {
                            order: 3;
                            flex: unset;
                            flex-direction: row;
                            width: 100%;
                            margin-right: 0 !important;
                            .officerName {
                                flex: 1;
                                box-sizing: border-box;
                                margin-right: 0.75em !important;
                            }
                            .note {
                                flex: 1;
                            }
                        }
                    }
                    &.Excused {
                        .rollCallData {
                            .rollAdmin {
                                flex: 1;
                                .officerName {
                                    display: none;
                                }
                            }
                            .rollCallTime {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 420px ) {
            padding: 20px 10px;
        }

        .dataSync {
            height: calc(100vh - 131px);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .loader {
            position: relative;
            color: #0576b0;
            font-size: 10px;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            position: relative;
            text-indent: -9999em;
            animation: mulShdSpin 1.3s infinite linear;
            transform: translateZ(0);
            margin:0 auto 65px auto;
        }
        
        @keyframes mulShdSpin {
            0%,
            100% {
                box-shadow: 0 -3em 0 0.2em, 
                2em -2em 0 0em, 3em 0 0 -1em, 
                2em 2em 0 -1em, 0 3em 0 -1em, 
                -2em 2em 0 -1em, -3em 0 0 -1em, 
                -2em -2em 0 0;
            }
            12.5% {
                box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 
                3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, 
                -2em 2em 0 -1em, -3em 0 0 -1em, 
                -2em -2em 0 -1em;
            }
            25% {
                box-shadow: 0 -3em 0 -0.5em, 
                2em -2em 0 0, 3em 0 0 0.2em, 
                2em 2em 0 0, 0 3em 0 -1em, 
                -2em 2em 0 -1em, -3em 0 0 -1em, 
                -2em -2em 0 -1em;
            }
            37.5% {
                box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
                3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, 
                -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
            }
            50% {
                box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
                3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, 
                -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
            }
            62.5% {
                box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
                3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, 
                -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
            }
            75% {
                box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 
                3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
                -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
            }
            87.5% {
                box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 
                3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
                -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
            }
        }
        
    }
}

app-roll-call-student {
    .rollCall {
        background-color: #fff;
        padding:20px;
        border-radius: 3px;
        .studentDetails {
            .mobile,
            .email {
                margin-bottom: 0.75em;
            }
        }

        .noteField {
            border: 1px solid #eaeaea;
            border-radius: 3px;
        }

        .userNote {
            margin-top: 10px;
            min-height:  1.5em;
        }
    }
}

.rollCallStatusIcon {

    &::after {
        content: '\f058';
        color: #666666;
        display: flex;
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        font-size: 25px;
    }

    &.Time,
    &.time {
        &::after { 
            color: #31cc64 !important;
        }
    }

    &.Late,
    &.late {
        &::after { 
            color: #f19840 !important;
        }
    }

    &.Missed,
    &.missed {
        &::after { 
            color: #C22F08 !important;
        }
    }

    &.Excused,
    &.excused {
        &::after { 
            content: '\f28b';
            color: #36e2e4 !important;
        }
    }
}

.windowPanel {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 0 auto;

    .windowIcon {
        position: absolute;
        left: -75px;
        top: 30px;
    }
    
    .windowIcon::after {
        content: '\f058';
        display: flex;
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        font-size: 60px;
    }
    
    .windowIcon.open::after {
        content: '\f058';
            color: #31cc64;
        }
    
        .windowIcon.late::after {
            content: '\f058';
            color: #f19840;
        }
    
        .windowIcon.closed::after {
            content: '\f058';
            color: #C22F08;
        }

    .windowText {
        h2.window {

        }

        h3.day {
            margin-top: 0 !important;
        }
    }

    @media screen and (max-width:420px) {
        margin-top: 70px;

        .windowIcon {
            top: -50px;
            left: unset;
        }
    }

}



app-notification-modal {

    &.ion-page {
        background-color: #fff;
    }

    h1 {
        margin-top: 30px;
    }

    .readMore {
        margin: 15px 0;
        button {
            @extend .button;
            @extend .icon;
        }
        .button-readmore {
            &::after {
                content: "\f054";
                display: inline-block;
                vertical-align: middle;
                margin-top: -4px;
                margin-left: 15px;
                margin-right: -15px;
                color: #fff;
                font-weight: 600;
                opacity: 1;
            }
            &.external-url {

            }
            &.app-page {

            }
        }
    }

    .manageToolbar {
        button {
            @extend .button;
            @extend .button-secondary;
            @extend .button-small;
            @extend .icon;
        }
        .button-unpin::before {
            content: "\f60c";
        }
        .button-archive::before {
            content: "\f187";
        }
    }

}

app-notifications-archive {

    .notificationsMenu {
        ul {
            li.notificationsArchive {
                @extend .currentPage;
            }
        }
    }

}

app-notifications-inbox {

    .notificationsMenu {
        ul {
            li.notificationsInbox {
                @extend .currentPage;
            }
        }
    }

}

app-roster-add-edit-shift {
}

app-roster-agenda {
}

app-roster-calendar {
}

app-roster-check {
}

app-roster-manager {
}

app-roster-share {
}

app-search {
}

app-settings {

    ion-item.userNameImage {
        ion-label {
            order: 1;
        }
        .userImage {
            position: relative;
            margin: 5px 0;
            width: 75px;
            height: 75px;
            padding: 0;
            background: rgba(5,118,176,1);
            border-radius: 100%;
            overflow: hidden;
            order: 2;
            .userInitials {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 150%;
                color: #fff;
            }
            img {
                position: absolute;
                top: -10000em;
                bottom: -10000em;
                left: -10000em;
                right: -10000em;
                margin: auto;
            }
        }
    }

    #RequestDataToBeDeletedContainer {
        margin-bottom: 15px;
    }

}

app-settings-roster {
}

app-settings-roster-calendar {
}

app-settings-troubleshooting {
    #DeveloperTools {
        margin: 1.5em 0;
        padding-top: 1.5em;
        border-top: #e0dcdb 3px solid;
    }
}

app-splash {
    @extend .pageTemplateBlue01;
    @extend .splashPages;
}

app-terms-and-conditions {
}

.safe-area {
    padding-top: env(safe-area-inset-top);
  --ion-safe-area-top: calc(9 / 16 * 100%); 
}

