@mixin themeicon($iconfont: "Font Awesome 5 Pro") {
    position: relative;
    color: inherit;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    vertical-align: middle;
    height: 1.25em;
    width: 1.25em;
    margin-right: 0.25em;
    text-align: center;
    font-family: $iconfont;
    font-weight: 500;
    font-size: 1em;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1.25em;
}
