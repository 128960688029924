// ##############################
//
// Columns
// 
// ##############################

/*
    Default setup for columns blocks
*/

div[class*="cols-"] {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    clear: both;
    box-sizing: border-box;
    width: 100%;
}

    div[class*="cols-"] > .col {
        position: relative;
        box-sizing: border-box;
        margin-bottom: 1.5em;
        /*overflow: hidden;*/ /* So we can contain things inside the column boxes, like images. */
    }

/* 2 Columns */

div.cols-2 {
}

    div.cols-2 > .col:nth-child(2n-1) {
        position: relative;
        float: left;
        clear: left;
        width: 49%;
        margin-right: 1%;
    }

    div.cols-2 > .col:nth-child(2n-0) {
        position: relative;
        float: right;
        width: 49%;
        margin-left: 1%;
    }

    div.cols-2.layout-1 {
    }

        div.cols-2.layout-1 > .col:nth-child(2n-1) {
            width: 33%;
        }

        div.cols-2.layout-1 > .col:nth-child(2n-0) {
            width: 65%;
        }

    div.cols-2.layout-2 {
    }

        div.cols-2.layout-2 > .col:nth-child(2n-1) {
            width: 42%;
        }

        div.cols-2.layout-2 > .col:nth-child(2n-0) {
            width: 56%;
        }

    div.cols-2.layout-3 {
    }

        div.cols-2.layout-3 > .col:nth-child(2n-1) {
            width: 56%;
        }

        div.cols-2.layout-3 > .col:nth-child(2n-0) {
            width: 42%;
        }

    div.cols-2.layout-4 {
    }
    
        div.cols-2.layout-4 > .col:nth-child(2n-1) {
            width: 65%;
        }

        div.cols-2.layout-4 > .col:nth-child(2n-0) {
            width: 33%;
        }

    div.cols-2.gutters-none {
    }

        div.cols-2.gutters-none > .col { 
            margin-right: 0;
            margin-left: 0;
            width: 50%;
        }

    div.cols-2.layout-1.gutters-none {
    }

        div.cols-2.layout-1.gutters-none > .col:nth-child(2n-1) {
            width: 34%;
        }

        div.cols-2.layout-1.gutters-none > .col:nth-child(2n-0) {
            width: 66%;
        }

    div.cols-2.layout-2.gutters-none {
    }

        div.cols-2.layout-2.gutters-none > .col:nth-child(2n-1) {
            width: 43%;
        }

        div.cols-2.layout-2.gutters-none > .col:nth-child(2n-0) {
            width: 57%;
        }

    div.cols-2.layout-3.gutters-none {
    }

        div.cols-2.layout-3.gutters-none > .col:nth-child(2n-1) {
            width: 57%;
        }

        div.cols-2.layout-3.gutters-none > .col:nth-child(2n-0) {
            width: 43%;
        }

    div.cols-2.layout-4.gutters-none {
    }

        div.cols-2.layout-4.gutters-none > .col:nth-child(2n-1) {
            width: 66%;
        }

        div.cols-2.layout-4.gutters-none > .col:nth-child(2n-0) {
            width: 34%;
        }

    div.cols-2.gutters-thick {
    }

        div.cols-2.gutters-thick > .col:nth-child(2n-1) {
            width: 48%;
            margin-right: 2%;
        }

        div.cols-2.gutters-thick > .col:nth-child(2n-0) {
            width: 48%;
            margin-left: 2%;
        }

    div.cols-2.layout-1.gutters-thick {
        /* Note: The columns width balance for .cols-2 special layouts is a little different to the higher columns so that they line up nicely with the .cols-3 options when used side by side.  */
    }

        div.cols-2.layout-1.gutters-thick > .col:nth-child(2n-1) {
            width: 32%;
        }

        div.cols-2.layout-1.gutters-thick > .col:nth-child(2n-0) {
            width: 64%;
        }

    div.cols-2.layout-2.gutters-thick {
    }

        div.cols-2.layout-2.gutters-thick > .col:nth-child(2n-1) {
            width: 41%;
        }

        div.cols-2.layout-2.gutters-thick > .col:nth-child(2n-0) {
            width: 53%;
        }

    div.cols-2.layout-3.gutters-thick {
    }

        div.cols-2.layout-3.gutters-thick > .col:nth-child(2n-1) {
            width: 53%;
        }

        div.cols-2.layout-3.gutters-thick > .col:nth-child(2n-0) {
            width: 41%;
        }
        
    div.cols-2.layout-4.gutters-thick {
    }

        div.cols-2.layout-4.gutters-thick > .col:nth-child(2n-1) {
            width: 64%;
        }

        div.cols-2.layout-4.gutters-thick > .col:nth-child(2n-0) {
            width: 32%;
        }

    div.cols-2.divider::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        background-color: #dcd6d0;
        width: 1px;
        height: 100%;
    }

    div.cols-2.layout-1.divider::after {
        left: 33%;
    }

    div.cols-2.layout-2.divider::after {
        left: 42%;
    }

    div.cols-2.layout-3.divider::after {
        left: 54%;
    }

    div.cols-2.layout-4.divider::after {
        left: 65%;
    }
    
/* 3 Columns */

div.cols-3 {
}

    div.cols-3 > .col:nth-child(3n-2) {
        position: relative;
        float: left;
        width: 32%;
        margin-right: 1%;
    }

    div.cols-3 > .col:nth-child(3n-1) {
        position: relative;
        float: left;
        width: 32%;
        margin-right: 1%;
        margin-left: 1%;
    }

    div.cols-3 > .col:nth-child(3n-0) {
        position: relative;
        float: right;
        width: 32%;
        margin-left: 1%;
    }

    div.cols-3.layout-1 {
    }

        div.cols-3.layout-1 > .col:nth-child(3n-2) {
            width: 24%;
        }

        div.cols-3.layout-1 > .col:nth-child(3n-1) {
            width: 48%;
        }

        div.cols-3.layout-1 > .col:nth-child(3n-0) {
            width: 24%;
        }
        
    div.cols-3.layout-2 {
    }

        div.cols-3.layout-2 > .col:nth-child(3n-2) {
            width: 48%;
        }

        div.cols-3.layout-2 > .col:nth-child(3n-1) {
            width: 24%;
        }

        div.cols-3.layout-2 > .col:nth-child(3n-0) {
            width: 24%;
        }

    div.cols-3.layout-3 {
    }

        div.cols-3.layout-3 > .col:nth-child(3n-2) {
            width: 24%;
        }

        div.cols-3.layout-3 > .col:nth-child(3n-1) {
            width: 24%;
        }

        div.cols-3.layout-3 > .col:nth-child(3n-0) {
            width: 48%;
        }

    div.cols-3.gutters-none {
    }

        div.cols-3.gutters-none > .col { 
            margin-right: 0;
            margin-left: 0;
            width: 33.33%;
        }

    div.cols-3.layout-1.gutters-none {
    }

        div.cols-3.layout-1.gutters-none > .col:nth-child(3n-2) {
            width: 25%;
        }

        div.cols-3.layout-1.gutters-none > .col:nth-child(3n-1) {
            width: 50%;
        }

        div.cols-3.layout-1.gutters-none > .col:nth-child(3n-0) {
            width: 25%;
        }

    div.cols-3.layout-2.gutters-none {
    }

        div.cols-3.layout-2.gutters-none > .col:nth-child(3n-2) {
            width: 50%;
        }

        div.cols-3.layout-2.gutters-none > .col:nth-child(3n-1) {
            width: 24%;
        }

        div.cols-3.layout-2.gutters-none > .col:nth-child(3n-0) {
            width: 24%;
        }

    div.cols-3.layout-1.gutters-none {
    }

        div.cols-3.layout-3.gutters-none > .col:nth-child(3n-2) {
            width: 24%;
        }

        div.cols-3.layout-3.gutters-none > .col:nth-child(3n-1) {
            width: 24%;
        }

        div.cols-3.layout-3.gutters-none > .col:nth-child(3n-0) {
            width: 50%;
        }

    div.cols-3.gutters-thick {
    }

        div.cols-3.gutters-thick > .col:nth-child(3n-2) {
            width: 30.67%;
            margin-right: 2%;
        }

        div.cols-3.gutters-thick > .col:nth-child(3n-1) {
            width: 30.66%;
            margin-right: 2%;
            margin-left: 2%;
        }

        div.cols-3.gutters-thick > .col:nth-child(3n-0) {
            width: 30.67%;
            margin-left: 2%;
        }

    div.cols-3.layout-1.gutters-thick {
    }

        div.cols-3.layout-1.gutters-thick > .col:nth-child(3n-2) {
            width: 23%;
        }

        div.cols-3.layout-1.gutters-thick > .col:nth-child(3n-1) {
            width: 46%;
        }

        div.cols-3.layout-1.gutters-thick > .col:nth-child(3n-0) {
            width: 23%;
        }

    div.cols-3.layout-2.gutters-none {
    }

        div.cols-3.layout-2.gutters-thick > .col:nth-child(3n-2) {
            width: 46%;
        }

        div.cols-3.layout-2.gutters-thick > .col:nth-child(3n-1) {
            width: 23%;
        }

        div.cols-3.layout-2.gutters-thick > .col:nth-child(3n-0) {
            width: 23%;
        }

    div.cols-3.layout-1.gutters-thick {
    }

        div.cols-3.layout-3.gutters-thick > .col:nth-child(3n-2) {
            width: 23%;
        }

        div.cols-3.layout-3.gutters-thick > .col:nth-child(3n-1) {
            width: 23%;
        }

        div.cols-3.layout-3.gutters-thick > .col:nth-child(3n-0) {
            width: 46%;
        }

    /* 4 Columns */

    div.cols-4 > .col:nth-child(4n-3) {
        position: relative;
        float: left;
        width: 24%;
        margin-right: 0.6666%;
    }

    div.cols-4 > .col:nth-child(4n-2) {
        position: relative;
        float: left;
        width: 24%;
        margin-right: 0.6666%;
        margin-left: 0.6666%;
    }

    div.cols-4 > .col:nth-child(4n-1) {
        position: relative;
        float: left;
        width: 24%;
        margin-right: 0.6666%;
        margin-left: 0.6666%;
    }

    div.cols-4 > .col:nth-child(4n-0) {
        position: relative;
        float: left;
        width: 24%;
        margin-left: 0.6666%;
    }
    
    div.cols-4.gutters-none {
    }

        div.cols-4.gutters-none > .col { 
            margin-right: 0;
            margin-left: 0;
            width: 25%;
        }

    div.cols-4.gutters-thick {
    }

        div.cols-4.gutters-thick > .col:nth-child(4n-3) {
            width: 23%;
            margin-right: 1.33%;
        }

        div.cols-4.gutters-thick > .col:nth-child(4n-2) {
            width: 23%;
            margin-right: 1.33%;
            margin-left: 1.34%;
        }

        div.cols-4.gutters-thick > .col:nth-child(4n-1) {
            width: 23%;
            margin-right: 1.34%;
            margin-left: 1.33%;
        }

        div.cols-4.gutters-thick > .col:nth-child(4n-0) {
            width: 23%;
            margin-left: 1.33%;
        }

    /* 5 Columns */

    div.cols-5 > .col:nth-child(5n-4) {
        position: relative;
        float: left;
        width: 19%;
        margin-right: 0.625%;
    }

    div.cols-5 > .col:nth-child(5n-3) {
        position: relative;
        float: left;
        width: 19%;
        margin-right: 0.625%;
        margin-left: 0.625%;
    }

    div.cols-5 > .col:nth-child(5n-2) {
        position: relative;
        float: left;
        width: 19%;
        margin-right: 0.625%;
        margin-left: 0.625%;
    }

    div.cols-5 > .col:nth-child(5n-1) {
        position: relative;
        float: left;
        width: 19%;
        margin-right: 0.625%;
        margin-left: 0.625%;
    }

    div.cols-5 > .col:nth-child(5n-0) {
        position: relative;
        float: right;
        width: 19%;
        margin-left: 0.625%;
    }
    
    div.cols-5.gutters-none {
    }

        div.cols-5.gutters-none > .col { 
            margin-right: 0;
            margin-left: 0;
            width: 20%;
        }

    div.cols-5.gutters-thick {
    }

        div.cols-5.gutters-thick > .col:nth-child(5n-3) {
            width: 18%;
            margin-right: 1.25%;
            margin-left: 1.25%;
        }

        div.cols-5.gutters-thick > .col:nth-child(5n-2) {
            width: 18%;
            margin-right: 1.25%;
            margin-left: 1.25%;
        }

        div.cols-5.gutters-thick > .col:nth-child(5n-1) {
            width: 18%;
            margin-right: 1.25%;
            margin-left: 1.25%;
        }

        div.cols-5.gutters-thick > .col:nth-child(5n-0) {
            width: 18%;
            margin-left: 1.25%;
        }

    /* 6 Columns */

    div.cols-6 > .col:nth-child(6n-5) {
        position: relative;
        float: left;
        width: 16%;
        margin-right: 0.4%;
    }

    div.cols-6 > .col:nth-child(6n-4) {
        position: relative;
        float: left;
        width: 16%;
        margin-right: 0.4%;
        margin-left: 0.4%;
    }

    div.cols-6 > .col:nth-child(6n-3) {
        position: relative;
        float: left;
        width: 16%;
        margin-right: 0.4%;
        margin-left: 0.4%;
    }

    div.cols-6 > .col:nth-child(6n-2) {
        position: relative;
        float: left;
        width: 16%;
        margin-right: 0.4%;
        margin-left: 0.4%;
    }
    
    div.cols-6 > .col:nth-child(6n-1) {
        position: relative;
        float: left;
        width: 16%;
        margin-right: 0.4%;
        margin-left: 0.4%;
    }

    div.cols-6 > .col:nth-child(6n-0) {
        position: relative;
        float: left;
        width: 16%;
        margin-left: 0.4%;
    }
        
    div.cols-6.gutters-none {
    }

        div.cols-6.gutters-none > .col { 
            margin-right: 0;
            margin-left: 0;
            width: 16.665%;
        }

    div.cols-6.gutters-thick {
    }

        div.cols-6.gutters-thick > .col:nth-child(6n-5) {
            width: 15.33%;
            margin-right: 0.8%;
        }

        div.cols-6.gutters-thick > .col:nth-child(6n-4) {
            width: 15.33%;
            margin-right: 0.8%;
            margin-left: 0.8%;
        }

        div.cols-6.gutters-thick > .col:nth-child(6n-3) {
            width: 15.33%;
            margin-right: 0.8%;
            margin-left: 0.8%;
        }

        div.cols-6.gutters-thick > .col:nth-child(6n-2) {
            width: 15.33%;
            margin-right: 0.8%;
            margin-left: 0.8%;
        }

        div.cols-6.gutters-thick > .col:nth-child(6n-1) {
            width: 15.33%;
            margin-right: 0.8%;
            margin-left: 0.8%;
        }

        div.cols-6.gutters-thick > .col:nth-child(6n-0) {
            width: 15.33%;
            margin-left: 0.8%;
        }

/*
    Margins
*/

div.cols-2 > .col,
div.cols-3 > .col,
div.cols-4 > .col,
div.cols-5 > .col,
div.cols-6 > .col {
    /* Margins are on the columns rather than the column block so they work better in stacking scenarios. */
    margin-bottom: 1.5em;
}

div.cols-2.margins-none > .col, 
div.cols-3.margins-none > .col, 
div.cols-4.margins-none > .col, 
div.cols-5.margins-none > .col, 
div.cols-6.margins-none > .col {
    margin-bottom: 0;
}

/*
    Reverse the visible order without changing the semantic order
*/

div.cols-2.reverse > .col:nth-child(2n) {
    order: -1;
}

div.cols-2[class*=reverse]:not(.gutters-none) > .col:nth-child(2n) {
    margin-left: 0;
    margin-right: 1%;
}

div.cols-2[class*=reverse]:not(.gutters-none) > .col:nth-child(2n-1) {
    margin-left: 1%;
    margin-right: 0;
}

div.cols-2[class*=reverse].gutters-thick > .col:nth-child(2n) {
    margin-left: 0;
    margin-right: 2%;
}

div.cols-2[class*=reverse].gutters-thick > .col:nth-child(2n-1) {
    margin-left: 2%;
    margin-right: 0;
}

/* 
    "Medium" screen sizes and below 
*/

@media screen and (max-width: 1200px) {

    /* 
        Stack at different widths
    */

    div.cols-2.stack-medium > .col:nth-child(1n), /* 1n override trick to strengthen specificity (but not too much) to catch all classes */
    div.cols-3.stack-medium > .col:nth-child(1n),
    div.cols-4.stack-medium > .col:nth-child(1n),
    div.cols-5.stack-medium > .col:nth-child(1n),
    div.cols-6.stack-medium > .col:nth-child(1n) {
        clear: left !important;
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    div.cols-2.stack-medium.divider::after {
        display: none; /* No divider when stacked. */
    }

    /*
        When stacked, margins between the cols are gutters.
    */

    div.cols-2.stack-medium.gutters-none:not(.reverse-medium) > .col:not(:last-child),
    div.cols-2.stack-medium.gutters-none.reverse-medium > .col:last-child,
    div.cols-3.stack-medium.gutters-none > .col:not(:last-child),
    div.cols-4.stack-medium.gutters-none > .col:not(:last-child),
    div.cols-5.stack-medium.gutters-none > .col:not(:last-child),
    div.cols-6.stack-medium.gutters-none > .col:not(:last-child) {
        margin-bottom: 0 !important;
    }

    div.cols-2.stack-medium.gutters-thick > .col:not(:last-child),
    div.cols-3.stack-medium.gutters-thick > .col:not(:last-child),
    div.cols-4.stack-medium.gutters-thick > .col:not(:last-child),
    div.cols-5.stack-medium.gutters-thick > .col:not(:last-child),
    div.cols-6.stack-medium.gutters-thick > .col:not(:last-child) {
        /* How do we calculate this? Is it needed? */
    }

    /*
        Reverse at different widths (useful for alternative image/text rows) 
    */
    div.cols-2.reverse-medium > .col:nth-child(2n) {
        order: -1;
    }

}

/* 
    "Small" screen sizes and below 
    Note that the higher column numbers begin to shrink and partially stack at this size irrespective of stacking classes
*/

@media screen and (max-width: 900px) { 

    div.cols-2 > .col, 
    div.cols-3 > .col, 
    div.cols-4 > .col,
    div.cols-5 > .col,
    div.cols-6 > .col {
        float: left !important;
        width: 49% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

        div.cols-2 > .col:nth-child(2n-1), 
        div.cols-3 > .col:nth-child(2n-1), 
        div.cols-4 > .col:nth-child(2n-1),
        div.cols-5 > .col:nth-child(2n-1),
        div.cols-6 > .col:nth-child(2n-1) {
            clear: left !important;
            margin-right: 2% !important;
        }

        div.cols-2.gutters-none > .col,
        div.cols-3.gutters-none > .col,
        div.cols-4.gutters-none > .col,
        div.cols-5.gutters-none > .col,
        div.cols-6.gutters-none > .col {
            width: 50% !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        div.cols-2.gutters-thick > .col,
        div.cols-3.gutters-thick > .col,
        div.cols-4.gutters-thick > .col,
        div.cols-5.gutters-thick > .col,
        div.cols-6.gutters-thick > .col {
            width: 48% !important;
        }

            div.cols-2.gutters-thick > .col:nth-child(2n-1),
            div.cols-3.gutters-thick > .col:nth-child(2n-1),
            div.cols-4.gutters-thick > .col:nth-child(2n-1),
            div.cols-5.gutters-thick > .col:nth-child(2n-1),
            div.cols-6.gutters-thick > .col:nth-child(2n-1) {
                clear: left !important;
                margin-right: 4% !important;
            }

    div.cols-3 > .col:last-child, 
    div.cols-5 > .col:last-child {
        margin-left: 25% !important;
    }

        div.cols-3.gutters-thick > .col:last-child,
        div.cols-5.gutters-thick > .col:last-child {
            margin-left: 26% !important;
        }

    /* 
        Second layer of columns 
    */

    div.cols-2 > .col .col,
    div.cols-3 > .col .col,
    div.cols-4 > .col .col,
    div.cols-5 > .col .col,
    div.cols-6 > .col .col {
        width: 100% !important;
    }

    /* 
        Stack at different widths
    */

    div.cols-2.stack-small > .col:nth-child(1n), /* 1n override trick to strengthen specificity (but not too much) to catch all classes */
    div.cols-3.stack-small > .col:nth-child(1n),
    div.cols-4.stack-small > .col:nth-child(1n),
    div.cols-5.stack-small > .col:nth-child(1n),
    div.cols-6.stack-small > .col:nth-child(1n) {
        clear: left !important;
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    /*
        When stacked, margins between the cols are gutters.
    */

    div.cols-2.stack-small.gutters-none:not(.reverse-small) > .col:not(:last-child),
    div.cols-2.stack-small.gutters-none.reverse-small > .col:last-child,
    div.cols-3.stack-small.gutters-none > .col:not(:last-child),
    div.cols-4.stack-small.gutters-none > .col:not(:last-child),
    div.cols-5.stack-small.gutters-none > .col:not(:last-child),
    div.cols-6.stack-small.gutters-none > .col:not(:last-child) {
        margin-bottom: 0 !important;
    }

    div.cols-2.stack-small.gutters-thick > .col:not(:last-child),
    div.cols-3.stack-small.gutters-thick > .col:not(:last-child),
    div.cols-4.stack-small.gutters-thick > .col:not(:last-child),
    div.cols-5.stack-small.gutters-thick > .col:not(:last-child),
    div.cols-6.stack-small.gutters-thick > .col:not(:last-child) {
        /* How do we calculate this? Is it needed? */
    }

    /*
        Reverse at different widths (useful for alternative image/text rows) 
    */

    div.cols-2.reverse-small > .col:nth-child(2n) {
        order: -1;
    }

}

/* 
    "Tiny" screen sizes and below
    Note: Everything needs to stack by now unless we told it not to
*/

@media screen and (max-width: 600px) {

    /* 
        Stack at different widths
    */

    div.cols-2:not(.stack-none) > .col:nth-child(1n), /* 1n override trick to strengthen specificity (but not too much) to catch all classes */
    div.cols-3:not(.stack-none) > .col:nth-child(1n),
    div.cols-4:not(.stack-none) > .col:nth-child(1n),
    div.cols-5:not(.stack-none) > .col:nth-child(1n),
    div.cols-6:not(.stack-none) > .col:nth-child(1n) {
        clear: left !important;
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

}

/* 
    "Micro" screen sizes and below 
*/

@media screen and (max-width: 375px) {
    
}

/*
    Elements in all columns
*/

.col > :first-child {
    margin-top: 0 !important;
}

.col > :last-child {
    margin-bottom: 0 !important;
}

/* ####################################
   Custom Columns Blocks
   ################################## */

/*
    Image Fill
    Note: Integrates with div.image-cover rules in CustomElementsExtended.css
*/

div[class*="cols-"] > .col div.image-cover {
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 1200px) {

    div[class*="cols-"].stack-medium > .col div.image-cover {
        position: relative;
        top: auto;
        left: auto;
    }

}

@media screen and (max-width: 900px) {

    div[class*="cols-"].stack-small > .col div.image-cover {
        position: relative;
        top: auto;
        left: auto;
    }

}

@media screen and (max-width: 600px) {

    div[class*="cols-"]:not(.stack-none) > .col div.image-cover {
        position: relative;
        top: auto;
        left: auto;
    }

}

/*
    Sequence
    Note: Leave enough padding for the sequence graphic to avoid overlapping content. We could calculate all the different gutter size options, but that's really hard work and authors probably won't care.
    To do: Due to padding calculation, first and last columns are wider at the moment. Fix this.
*/

div[class*="cols-"].sequence {
}

    div[class*="cols-"].sequence > .col {
        box-sizing: border-box;
    }

        div[class*="cols-"].sequence > .col:not(:first-child) {
            padding-left: 48px;
        }

        div[class*="cols-"].sequence > .col:not(:last-child) {
            padding-right: 48px;
        }

        div[class*="cols-"].sequence > .col:after {
            content: '\f0a9';
            right: -32px;
            position: absolute;
            top: calc(50% - 32px);
            font-size: 64px;
            font-family: 'Font Awesome 5 Free';
            color: #c0bcbb;
        }

        div[class*="cols-"].sequence > .col:last-child:after {
            display: none;
        }

@media screen and (max-width: 900px) {

    div[class*="cols-"].sequence:not(.stack-none) > .col {
        float: left !important;
        clear: left !important;
        width: 100% !important;
        padding: 0 !important;
        margin: 0 0 20px !important;
    }

        div[class*="cols-"].sequence:not(.stack-none) > .col:after {
            display: none;
        }

}
