/* ############################################################################
   ## 
   ##  Content Elements (General)
   ## 
   ##  - This might look like a miscellaneous category but it's not, watch yourself!
   ##  - Special/custom site elements that aren't in the core should go in CustomElements.scss
   ## 
   ######################################################################### */

hr {
    margin: 1.5em 0 3em;
    height: 3px;
    width: auto; /* Don't be tempted to use a set width for the default hr layout, it messes with floated images and other elements. */
    background: #e0dcdb;
    border: 0;
}

    hr.short {
        width: 50%;
        margin-left: 25%;
    }

    hr.white {
        background: #fff;
    }

    hr.blac {
        background: #000;
    }

    hr.dark {
        background: #0088b2;
    }

    hr.dark-alternate {
        background: #358366;
    }

a.link-cta,
a.linkCallToAction {
    /* .linkCallToAction is only for backward compatibility. */
    font-size: 110.53%;
    font-weight: 700 !important;
}

    a.link-cta::before,
    a.linkCallToAction::before {
        content: "\f35a";
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        font-family: 'Font Awesome 5 Free';
        font-weight: 700;
    }

/*
    Loading Spinner
*/

@keyframes spinner {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}

.spin {
    position: relative;
    padding-left: 2.5em;
    &::before {
        content: "";
        position: absolute;
        top: 0.65em;
        left: 0.75em;
        height: 1.25em;
        width: 1.25em;
        border: solid 5px rgba(5,115,176,0.25);
        border-bottom-color: #358366;
        border-radius: 100%;
        box-shadow: 0 0 1px rgb(0 0 0 / 10%);
        animation: 1.5s linear infinite spinner;
        animation-play-state: inherit;
        transform: translate3d(-50%, -50%, 0);
        will-change: transform;
      }

}

/*
    Ionic Elements
*/
    
ion-item {
    padding-inline-start: calc(var(--ion-safe-area-right, 0px) + var(--inner-padding-end)); /* Match the right padding of .item-inner for consistency. */
}

ion-badge {
    --padding-start: 5px;
    --padding-end: 5px;
}

button ion-badge, .button ion-bade {
    position: absolute;
    right: 5px;
    bottom: 0.15em;
}

/*
    Ionic Lists
    - Ionic "lists" are not HTML lists. 
*/

ion-list, .list-md, list-ios {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 15px;
    background: #fff;
    border-radius: 3px;
    ion-list-header {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
        background: rgba(5,118,176,1);
    }
    ion-item, .item {
        padding-inline-start: 0;
        &:last-child {
            &::part(native) {
                --inner-border-width: 0;
            }
        }
        &.displayMore {
            font-weight: bold;
            color: #0088b2;
            border-top: #dee3e6 1px solid;
        }
    }
    ion-list-footer {

    }
}

/*
    Ionic Tabs / Segments
*/

ion-segment {
    /* All segments */
    ion-segment-button {
        display: flex;
        justify-content: start;
        white-space: normal;
        box-sizing: border-box;
        min-width: 0 ;
        line-height: 100%;
        font-size: 12px;
        font-weight: normal;
        padding: 7px 0;
        --color-checked: #fff;
        --padding-start: 0;
        --padding-end: 0;
        --background-hover: none;
        &.segment-button-checked_DISABLED {
            color: #fff;
            --indicator-color: transparent;
            /*
            background: #eeeef2;
            .icon {
                color: rgba(5,118,175,1);
            }
            */
        }
        .icon {
            display: inline-block;
            box-sizing: border-box;
            width: 100%;
            padding: 0 4px;
            &::before {
                @include themeicon;
                display: block;
                margin: 0 auto 3px;
                font-weight: 500;
                font-size: 18px;
            }
        }
    }
}

ion-header {
    /* When segments are in the header */
    ion-segment {
        --color-checked: #fff;

    }
}

ion-content {
    /* When segments are in the content area */
    ion-segment {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        margin-bottom: 15px;
        ion-segment-button {
            --color-checked: #fff;
            background: rgba(5,118,175,1);
            border-radius: 3px;
            &.segment-button-checked {
                color: #fff;
                --indicator-color: transparent;
                /*
                background: #fff;
                .icon {
                    color: rgba(5,118,175,1);
                }
                */
            }
        }
    }
}

/*
    Notifications
*/

.notifications {
    ion-list {
        ion-list-header {

        }
        ion-item {
            ion-icon:first-child {
                @extend .icon;
                margin-right: 10px;
            }
            .title {
                font-size: 16px;
                font-weight: bold;
            }
            .date {
                text-transform: uppercase;
            }
        }
    }
}