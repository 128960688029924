/* ####################################
   Counter

   - Critical Rendering Path: Recommended
   ################################## */

.counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

    .counter .label {
        order: 2;
        padding-bottom: 30px;
        text-align: center;
        font-size: 150%;
        font-weight: bold;
    }

    .counter .count {
        order: 1;
        text-align: center;
        font-weight: bold;
        font-size: 350%;
    }

    .counter + .counter {
        border-top-width: 1px;
    }

    .counter.box {
        margin: 0 auto; /* Centred within container. */
        width: 240px;
        height: 240px;
        background-color: #faf8f7;
    }

    .counter.circle {
        margin: 0 auto; /* Centred within container. */
        width: 240px;
        height: 240px;
        background-color: #faf8f7;
        border-radius: 100%;
    }

    .counter.border {
        border: #dcd6d0 1px solid;
    }

    .counter.background-none {
        background-color: transparent;
    }

    .counter.small {
        font-size: 75%;
    }

    .counter.box.small,
    .counter.circle.small {
        width: 180px;
        height: 180px;
    }

    .counter.big {
        font-size: 133.33%;
    }

    .counter.box.big,
    .counter.circle.big {
        width: 320px;
        height: 320px;
    }

