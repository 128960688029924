/* ####################################
   Animations

   - Critical Rendering Path: Required
   - Animations attached to interaction events below the fold might be managed separately
   ################################## */

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.bounceInUp {
    animation-name: bounceInUp;
}

.spin {
    animation-name: spin;
}

@keyframes bounceInUp {

    0% {
        opacity: 0;
        -webkit-transform: translateY(2000px);
        transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    80% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

}

@keyframes spin {

    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }

}
