/* ####################################
   Animations

   - Critical Rendering Path: Optional
   ################################## */

.rating {
    position: relative;
    width: 100%;
    height: 32px;
    background: none;
}

    .rating .label {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        font-weight: bold;
    }

    .rating .value {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 160px;
        height: 32px;
        text-indent: -10000em;
    }

        .rating .value::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 32px;
            top: 0;
            left: 0;
            background-image: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Capa_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2047.94%2047.94%22%20style%3D%22enable-background%3Anew%200%200%2047.94%2047.94%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20style%3D%22fill%3A%23ED8A19%3B%22%20d%3D%22M26.285%2C2.486l5.407%2C10.956c0.376%2C0.762%2C1.103%2C1.29%2C1.944%2C1.412l12.091%2C1.757%0A%09c2.118%2C0.308%2C2.963%2C2.91%2C1.431%2C4.403l-8.749%2C8.528c-0.608%2C0.593-0.886%2C1.448-0.742%2C2.285l2.065%2C12.042%0A%09c0.362%2C2.109-1.852%2C3.717-3.746%2C2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403%2C0l-10.814%2C5.685%0A%09c-1.894%2C0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528%0A%09c-1.532-1.494-0.687-4.096%2C1.431-4.403l12.091-1.757c0.841-0.122%2C1.568-0.65%2C1.944-1.412l5.407-10.956%0A%09C22.602%2C0.567%2C25.338%2C0.567%2C26.285%2C2.486z%22%2F%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A');
            background-position: left top;
            background-size: auto 32px;
            background-repeat: repeat-x;
            opacity: 0.35;
        }

        .rating .value::after {
            content: "";
            position: absolute;
            height: 32px;
            top: 0;
            left: 0;
            background-image: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Capa_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2047.94%2047.94%22%20style%3D%22enable-background%3Anew%200%200%2047.94%2047.94%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20style%3D%22fill%3A%23ED8A19%3B%22%20d%3D%22M26.285%2C2.486l5.407%2C10.956c0.376%2C0.762%2C1.103%2C1.29%2C1.944%2C1.412l12.091%2C1.757%0A%09c2.118%2C0.308%2C2.963%2C2.91%2C1.431%2C4.403l-8.749%2C8.528c-0.608%2C0.593-0.886%2C1.448-0.742%2C2.285l2.065%2C12.042%0A%09c0.362%2C2.109-1.852%2C3.717-3.746%2C2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403%2C0l-10.814%2C5.685%0A%09c-1.894%2C0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528%0A%09c-1.532-1.494-0.687-4.096%2C1.431-4.403l12.091-1.757c0.841-0.122%2C1.568-0.65%2C1.944-1.412l5.407-10.956%0A%09C22.602%2C0.567%2C25.338%2C0.567%2C26.285%2C2.486z%22%2F%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3Cg%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A');
            background-position: left top;
            background-size: auto 32px;
            background-repeat: repeat-x;
        }

.rating-0-0 .value::after { width: 0 !important; }
.rating-0-1 .value::after { width: 2% !important; }
.rating-0-2 .value::after { width: 4% !important; }
.rating-0-3 .value::after { width: 6% !important; }
.rating-0-4 .value::after { width: 8% !important; }
.rating-0-5 .value::after { width: 10% !important; }
.rating-0-6 .value::after { width: 12% !important; }
.rating-0-7 .value::after { width: 14% !important; }
.rating-0-8 .value::after { width: 16% !important; }
.rating-0-9 .value::after { width: 18% !important; }
.rating-1 .value::after { width: 20% !important; }
.rating-1-0 .value::after { width: 20% !important; }
.rating-1-1 .value::after { width: 22% !important; }
.rating-1-2 .value::after { width: 24% !important; }
.rating-1-3 .value::after { width: 26% !important; }
.rating-1-4 .value::after { width: 28% !important; }
.rating-1-5 .value::after { width: 30% !important; }
.rating-1-6 .value::after { width: 32% !important; }
.rating-1-7 .value::after { width: 34% !important; }
.rating-1-8 .value::after { width: 36% !important; }
.rating-1-9 .value::after { width: 38% !important; }
.rating-2 .value::after { width: 40% !important; }
.rating-2-0 .value::after { width: 40% !important; }
.rating-2-1 .value::after { width: 42% !important; }
.rating-2-2 .value::after { width: 44% !important; }
.rating-2-3 .value::after { width: 46% !important; }
.rating-2-4 .value::after { width: 48% !important; }
.rating-2-5 .value::after { width: 50% !important; }
.rating-2-6 .value::after { width: 52% !important; }
.rating-2-7 .value::after { width: 54% !important; }
.rating-2-8 .value::after { width: 56% !important; }
.rating-2-9 .value::after { width: 58% !important; }
.rating-3 .value::after { width: 60% !important; }
.rating-3-0 .value::after { width: 60% !important; }
.rating-3-1 .value::after { width: 62% !important; }
.rating-3-2 .value::after { width: 64% !important; }
.rating-3-3 .value::after { width: 66% !important; }
.rating-3-4 .value::after { width: 68% !important; }
.rating-3-5 .value::after { width: 70% !important; }
.rating-3-6 .value::after { width: 72% !important; }
.rating-3-7 .value::after { width: 74% !important; }
.rating-3-8 .value::after { width: 76% !important; }
.rating-3-9 .value::after { width: 78% !important; }
.rating-4 .value::after { width: 80% !important; }
.rating-4-0 .value::after { width: 80% !important; }
.rating-4-1 .value::after { width: 82% !important; }
.rating-4-2 .value::after { width: 84% !important; }
.rating-4-3 .value::after { width: 86% !important; }
.rating-4-4 .value::after { width: 88% !important; }
.rating-4-5 .value::after { width: 90% !important; }
.rating-4-6 .value::after { width: 92% !important; }
.rating-4-7 .value::after { width: 94% !important; }
.rating-4-8 .value::after { width: 96% !important; }
.rating-4-9 .value::after { width: 98% !important; }
.rating-5 .value::after { width: 100% !important; }
.rating-5-0 .value::after { width: 100% !important; }
