/* ####################################
   Lists
   ################################## */

/* 
    All Lists 
    - It's usually best to leave these alone. :)
*/

ul {
}

    ul li {
    }

ol {
}

    ol li {
    }

/* 
    Lists in page content areas that aren't special lists. Include .editorTinyMCE so they look right in the visual editor.
*/

ion-content ul:not([class]),
ion-content ol:not([class]),
.editorTinyMCE ul:not([class]),
.editorTinyMCE ol:not([class]) {
    margin: 0 0 1.5em;
}

    ion-content ul:not([class]) li,
    ion-content ol:not([class]) li,
    .editorTinyMCE ul:not([class]) li,
    .editorTinyMCE ol:not([class]) li {
        margin: 0.75em 0;
    }

ion-content li ul:not([class]),
ion-content li ol:not([class]),
.editorTinyMCE li ul:not([class]),
.editorTinyMCE li ol:not([class]) {
    /* Second level lists and below. */
    margin: 0;
}

/*
    Warnings, checks, crosses
    Note: Use [class] to target some items to strengthen specificity without using !important rules.
*/

ul.warnings,
ul.checks,
ul.crosses,
ul.info,
ul.help,
ul.pointers,
ul.circles,
ul[style*="circle"],
ul.discs,
ul[style*="disc"],
ul.squares,
ul[style*="square"] {
    list-style: none;
}

    ul.warnings > li,
    ul.checks > li,
    ul.crosses > li,
    ul.info > li,
    ul.help > li,
    ul.pointers > li,
    ul.circles > li,
    ul[style*="circle"] > li,
    ul.discs > li,
    ul[style*="disc"] > li,
    ul.squares > li,
    ul[style*="square"] > li {
        margin: 0.75em 0;
    }

        ul.warnings > li::before,
        ul > li.warning::before,
        ul.checks > li::before,
        ul > li.check::before,
        ul.crosses > li::before,
        ul > li.cross::before,
        ul.info > li::before,
        ul > li.info::before,
        ul.help > li::before,
        ul > li.help::before,
        ul.pointers > li::before,
        ul > li.pointer::before,
        ul.circles > li::before,
        ul > li.circle::before,
        ul[style*="circle"] > li::before,
        ul.discs > li::before,
        ul > li.disc::before,
        ul[style*="disc"] > li::before,
        ul.squares > li::before,
        ul > li.square::before,
        ul[style*="square"] > li::before {
            position: relative;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            vertical-align: baseline;
            height: 1.25em; /* Must use dynamic units to help the text sizer. */
            width: 1.25em; /* Must use dynamic units to help the text sizer. */
            margin-right: 0.5em;
            margin-left: -1.25em;
            text-align: center;
            font-family: 'Font Awesome 5 Pro'; /* The default font collection for the site. The collection can be changed manually using secondary classes - e.g. .fa, .fas, .far, .fal, .fad, .fab for Font Awesome. */
            font-weight: 700; /* The default icon weight for the site. It can be changed manually on each icon if your icon font supports it. Note: Font Awesome free version only supports 700 for the full icon set. */
            font-size: 1em;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1.25em;
        }

ul.circles,
ul[style*="circle"] {
}

    ul.circles > li,
    ul[style*="circle"] > li,
    ul > li.circle {
        list-style: none;
    }

        ul.circles > li::before,
        ul[style*="circle"] > li::before,
        ul > li.circle::before,
        ul[class] > li.circle::before {
            content: "\2022";
            color: #dcd6d0;
            text-shadow: 1px 1px #6c6862;
        }

        ul.circles:not(.warnings):not(.checks):not(.crosses):not(.info):not(.help) > li:not([class])::before,
        ul[style*="circle"]:not(.warnings):not(.checks):not(.crosses):not(.info):not(.help) > li:not([class])::before,
        ul > li.circle::before,
        ul[class] > li.circle::before {
            /* Only transform dots that aren't icons. */
            transform: scale(1.5) translateY(-0.1em);
        }

ul.discs,
ul[style*="disc"] {
}

    ul.discs > li,
    ul[style*="disc"] > li,
    ul > li.disc {
        list-style: none;
    }

        ul.discs > li::before,
        ul[style*="disc"] > li::before,
        ul > li.disc::before,
        ul[class] > li.disc::before {
            content: "\25cb";
            color: #dcd6d0;
            text-shadow: 1px 1px #6c6862;
        }

        ul.discs:not(.warnings):not(.checks):not(.crosses):not(.info):not(.help) > li:not([class])::before,
        ul[style*="disc"]:not(.warnings):not(.checks):not(.crosses):not(.info):not(.help) > li:not([class])::before,
        ul > li.disc::before,
        ul[class] > li.disc::before {
            /* Only transform dots that aren't icons. */
            transform: scale(1.1) translateY(-0.1em);
        }

ul.squares,
ul[style*="square"] {
}

    ul.squares > li,
    ul[style*="square"] > li,
    ul > li.square {
        list-style: none;
    }

        ul.squares > li::before,
        ul[style*="square"] > li::before,
        ul > li.square::before,
        ul[class] > li.square::before {
            content: "\025aa";
            color: #dcd6d0;
            text-shadow: 1px 1px #6c6862;
        }

        ul.squares:not(.warnings):not(.checks):not(.crosses):not(.info):not(.help) > li:not([class])::before,
        ul[style*="square"]:not(.warnings):not(.checks):not(.crosses):not(.info):not(.help) > li:not([class])::before,
        ul > li.square::before,
        ul[class] > li.square::before {
            /* Only transform squares that aren't icons. */
            transform: scale(1.5) translateY(-0.1em);
        }

ul.pointers {
}

    ul.pointers > li,
    ul > li.pointer {
        list-style: none;
    }

        ul.pointers > li::before,
        ul > li.pointer::before,
        ul[class] > li.pointer::before {
            content: "\f054";
            color: #dcd6d0;
            text-shadow: 1px 1px #6c6862;
        }

        ul.circles.pointers > li::before,
        ul[style*="circle"].pointers > li::before,
        ul.circles > li.pointer::before {
            content: "\f138";
            color: #6c6862;
            text-shadow: none;
        }

        ul.discs.pointers > li::before,
        ul[style*="disc"].pointers > li::before,
        uldiscs > li.pointer::before {
            font-family: 'Font Awesome 5 Pro'; /* Requires licensing - doesn't exist in Font Awesome Free. */
            content: "\f138";
            font-weight: 400;
            color: #6c6862;
            text-shadow: none;
        }

        ul.squares.pointers > li::before,
        ul[style*="square"].pointers > li::before,
        ul.squares > li.pointer::before {
            font-family: 'Font Awesome 5 Pro'; /* Requires licensing - doesn't exist in Font Awesome Free. */
            content: "\f32b";
            color: #6c6862;
            text-shadow: none;
        }

ul.warnings {
}

    ul.warnings > li,
    ul > li.warning {
        list-style: none;
    }

        ul.warnings > li::before,
        ul > li.warning::before,
        ul[class] > li.warning::before {
            content: "\f071";
            color: #ff9900;
            text-shadow: 1px 1px #994800;
        }

        ul.circles.warnings > li::before,
        ul[style*="circle"].warning > li::before,
        ul.circles > li.warning::before {
            content: "\f06a";
            text-shadow: none;
        }

        ul.discs.warnings > li::before,
        ul[style*="disc"].warning > li::before,
        ul.discs > li.warning::before {
            font-family: 'Font Awesome 5 Pro'; /* Requires licensing - doesn't exist in Font Awesome Free. */
            content: "\f06a";
            font-weight: 400;
            text-shadow: none;
        }

        ul.squares.warnings > li::before,
        ul[style*="square"].warning > li::before,
        ul.squares > li.warning::before {
            font-family: 'Font Awesome 5 Pro'; /* Requires licensing - doesn't exist in Font Awesome Free. */
            content: "\f321";
            text-shadow: none;
        }

ul.checks {
}

    ul.checks > li,
    ul > li.check {
        list-style: none;
    }

        ul.checks > li::before,
        ul > li.check::before,
        ul[class] > li.check::before {
            content: "\f00c";
            color: #33cc33;
            text-shadow: 1px 1px #004800;
        }

        ul.circles.checks > li::before,
        ul[style*="circle"].checks > li::before,
        ul.circles > li.check::before {
            content: "\f058";
            text-shadow: none;
        }

        ul.discs.checks > li::before,
        ul[style*="disc"].checks > li::before,
        ul.discs > li.check::before {
            content: "\f058";
            font-weight: 400;
            text-shadow: none;
        }

        ul.squares.checks > li::before,
        ul[style*="square"].checks > li::before,
        ul.squares > li.check::before {
            content: "\f14a";
            text-shadow: none;
        }

ul.crosses {
}

    ul.crosses > li,
    ul > li.cross {
        list-style: none;
    }

        ul.crosses > li::before,
        ul > li.cross::before,
        ul[class] > li.cross::before {
            content: "\f00d";
            color: #cc0000;
            text-shadow: 1px 1px #330000;
        }

        ul.circles.crosses > li::before,
        ul[style*="circle"].crosses > li::before,
        ul.circles > li.cross::before {
            content: "\f057";
            text-shadow: none;
        }

        ul.discs.crosses > li::before,
        ul[style*="disc"].crosses > li::before,
        ul.discs > li.cross::before {
            content: "\f057";
            font-weight: 400;
            text-shadow: none;
        }

        ul.squares.crosses > li::before,
        ul[style*="square"].crosses > li::before,
        ul.squares > li.cross::before {
            content: "\f410"; /* Available in Font Awesome Free. */
            font-family: 'Font Awesome 5 Pro'; /* Requires licensing - doesn't exist in Font Awesome Free. */
            content: "\f2d3";
            text-shadow: none;
        }

ul.info {
}

    ul.info > li,
    ul > li.info {
        list-style: none;
    }

        ul.info > li::before,
        ul > li.info::before,
        ul[class] > li.info::before {
            content: "\f129";
            color: #A0C2AD;
            text-shadow: 1px 1px #40624d;
        }

        ul.circles.info > li::before,
        ul[style*="circle"].info > li::before,
        ul.circles > li.info::before {
            content: "\f05a";
            text-shadow: none;
        }

        ul.discs.info > li::before,
        ul[style*="disc"].info > li::before,
        ul.discs > li.info::before {
            font-family: 'Font Awesome 5 Pro'; /* Requires licensing - doesn't exist in Font Awesome Free. */
            content: "\f05a";
            font-weight: 400;
            text-shadow: none;
        }

        ul.squares.info > li::before,
        ul[style*="square"].info > li::before,
        ul.squares > li.info::before {
            font-family: 'Font Awesome 5 Pro'; /* Requires licensing - doesn't exist in Font Awesome Free. */
            content: "\f30f";
            text-shadow: none;
        }

ul.help {
}

    ul.help > li,
    ul > li.help {
        list-style: none;
    }

        ul.help > li::before,
        ul > li.help::before,
        ul[class] > li.help::before {
            content: "\f128";
            /*color: #7CB8E1;
            text-shadow: 1px 1px #5C88b1;*/
            color: #2a52be;
            text-shadow: 1px 1px rgba(204,204,255,1);
        }

        ul.circles.help > li::before,
        ul[style*="circle"].help > li::before,
        ul.circles > li.help::before {
            content: "\f059";
            text-shadow: none;
        }

        ul.discs.help > li::before,
        ul[style*="disc"].help > li::before,
        ul.discs > li.help::before {
            font-family: 'Font Awesome 5 Pro'; /* Requires licensing - doesn't exist in Font Awesome Free. */
            content: "\f059";
            font-weight: 400;
            text-shadow: none;
        }

        ul.squares.help > li::before,
        ul[style*="square"].help > li::before,
        ul.squares > li.help::before {
            font-family: 'Font Awesome 5 Pro'; /* Requires licensing - doesn't exist in Font Awesome Free. */
            content: "\f2fd";
            text-shadow: none;
        }

/*
    Menu lists
*/

ul.menu {
    margin: 0 0 0.25em;
    padding: 0 !important;
    li {
        position: relative;
        display: block !important;
        margin: 0 0 8px !important;
        padding: 0 !important;
        list-style: none !important;
        font-size: 20px;
        a {
            @extend .button;
            width: 100%;
            height: 100%;
            padding: 10px 20px;
            margin: 0;
            text-transform: none !important;
            text-align: left;
            line-height: normal;
            &::after {
                content: "\f105";
                position: absolute;
                right: 15px;
                top: 0;
                width: auto;
                height: 100%;
                z-index: 20;
                display: inline-flex;
                align-items: center;
                font-family: "Font Awesome 5 Pro";
                font-weight: 300;
                font-size: 30px;
                color: #fff;
                opacity: 1;
            }
        }
    }
    &.buttons {
        li {
            a {
                @extend .button-bright;
                .secondaryText {
                    display: block; 
                    font-size: 14px; 
                    font-weight: normal; 
                    color: #99a;
                }
                &::after {
                    color: #99a;
                }
                &:hover {
                    &::after {
                        color: #fff;
                    }
                }
            }
        }
    }
    &.boxes {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        li {
            width: 100%;
            a {
                display: inline-flex;
                height: 100%;
                min-height: 120px;
                gap: 0;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                &::after {
                    display: none;
                }
            }
            .icon::before {
                margin-bottom: 0 !important;
                margin-left: auto;
                margin-right: auto;
            }
            @media screen and (max-width: 600px) {
                max-width: 50%;
                margin-left: auto !important;
                margin-right: auto !important;
            }
        }
    }
    .icon::before {
        font-size: 1.5em !important;
    }
}

ul.horizontal {
    margin: 0 0 1.5em !important;
    padding: 0 !important;
}

    ul.horizontal li {
        display: inline-block !important;
        margin: 0 !important;
        padding: 0 !important;
        list-style: none !important;
        vertical-align: middle !important;
    }

        ul.horizontal li:last-child {
            margin-right: 0 !important;
        }
    
    ul.horizontal.padTop {
        margin: 1.5em 0 0 !important;
    }

    ul.horizontal.padBottom {
        margin: 0 0 1.5em !important;
    }

    ul.horizontal.menu {
        margin: 0 0 1.5em;
    }

        ul.horizontal.menu li {
            margin: 0px 15px 15px 0 !important;
        }

            ul.horizontal.menu li:not(:last-child)::after {
            }

        ul.horizontal.menu li:last-child {
            padding-right: 0 !important;
            margin-right: 0 !important;
            border-right: 0 !important;
        }

    ul.horizontal.gutters-none {
    }

        ul.horizontal.gutters-none li {
            margin: 0 !important;
        }

    ul.horizontal.margins-none {
        margin: 0 !important;
    }

        ul.horizontal.margins-none li {
        }

    ul.horizontal.separators-none {
    }

        ul.horizontal.separators-none li::after {
            content: "" !important;
        }

@media screen and (max-width: 600px) {

    ul.horizontal.menu:not(.stack-none) {
    }

        ul.horizontal.menu:not(.stack-none) li {
            width: 100%;
            padding-right: 0 !important;
            margin-right: 0 !important;
            margin-bottom: 1.5em !important;
            border-right: none;
        }

            ul.horizontal.menu:not(.stack-none) li:not(:last-child)::after {
                display: none;
            }

}

/*
    Menu Lists
*/

ul.menu-horizontal {
    position: relative;
    display: flex;
    margin: 0 0 15px; 
    padding: 0;
    line-height: 135%;
    list-style: none;
    justify-content: space-evenly;
    gap: 5px;
}

    ul.menu-horizontal li {
        width: 100%;
        flex-grow: 1;
        margin: 0 !important;
        padding: 20px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        background-color: rgba(5,118,176,1);
        border-radius: 3px;
    }

        ul.menu-horizontal li a {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: -10px;
            height: calc(100% + 20px);
            color: #fff;
            text-decoration: none;
        }

        ul.menu-horizontal li.currentPage {
            color: rgba(5,118,176,1);
            background-color: #fff;
            /*box-shadow: 0 0 2px rgb(0 0 0 / 25%);*/
            a {
                background-color: transparent;
                color: inherit;
            }
        }

ul.menu-vertical {
    position: relative;
    display: block;
    margin: 0 0 1.5em;
    padding: 0;
    list-style: none;
    background-color: #fff;
    border: #eeeef2 1px solid; /* NC design: Same as the page background, so we get an outline if the element is placed on a white background but it's not visible in a standard page. */
    border-radius: 3px;
}

    ul.menu-vertical li {
        position: relative;
        padding: 15px;
        font-size: 90%;
        border-bottom: 1px solid #dee3e6;
        &:last-child {
            border-bottom: 0;
        }
    }

        ul.menu-vertical li a {
            /* Note: In the standard app design approach, we make the vertical menu look and work the same as regular app navigation lists as much as we can. This is so links lists can be created manually on any page and look consistent with the rest of the app's navigation pages. */
            display: block;
            margin: -11px 0 -10px -15px;
            padding: 11px 0px 10px 15px;
            color: #000;
            text-decoration: none;
            @extend .icon;
            &::after {
                content: "\f054";
                position: absolute;
                right: 10px;
                top: calc(50% - 12px);
                font-weight: 600;
            }
        }

    ul.menu-vertical li.currentPage {
        background-color: #dee3e6;
        border-left: #0576af 3px solid;
        a {
            color: #006aa2;
        }
    }

/*
    Values List
    - To do: Should this be a definition list instead, or even a table?
*/

ul.values-list, %values-list {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        margin: 0 0 0.5em;
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
        .label {
        }
        .value {
            flex-shrink: 0;
            width: 150px;
            max-width: 50%;
            padding: 20px;
            text-align: center;
            font-weight: bold;
            font-size: 150%;
            line-height: 1;
            background: #eef;
            border-radius: 3px;
        }
    }
}

/*
    Remove bullets and indents from lists
*/

ul.bullets-none {
    margin-left: 0 !important;
    padding-left: 0 !important;
    list-style: none;
}

    ul.bullets-none li {
        margin-left: 0 !important;
        padding-left: 0 !important;
        background: none !important;
    }
    
ul.indent-none, 
ol.indent-none {
    margin-left: 0 !important;
    padding-left: 0 !important;
}

/*
    Table of Contents
*/

.mce-toc,
.toc {
    display: inline-block;
    width: auto;
    padding: 15px;
    background: #fff;
    border: #d0cccb 1px solid;
    border-radius: 3px;
}

    .mce-toc h2,
    .toc h2 {
        font-family: Roboto, Arial, Helvetica, sans-serif;
        font-size: 100%;
        margin: -15px -15px 15px;
        padding: 15px;
        background: #dee3e6;
    }

    .mce-toc ul,
    .toc ul {
        font-size: 90%;
        margin: 0 !important;
        padding: 0;
    }

        .mce-toc ul li,
        .toc ul li {
            list-style: none;
        }

            .mce-toc ul ul,
            .toc ul ul {
            }

                .mce-toc ul ul li, 
                .toc ul ul li {
                    list-style: disc;
                    margin-left: 2em;
                }
                
        .mce-toc > ul > li:last-child,
        .toc > ul > li:last-child {
            margin-bottom: 0;
        }

@media screen and (max-width: 600px) {
    
    .mce-toc,
    .toc { 
        width: 100%; 
    }

}

/*
    Lists within Centered Blocks
*/

.text-center ul { display: inline-block; width: auto; }
.text-center ol { display: inline-block; width: auto; }
.text-center dl { display: inline-block; width: auto; }

@media screen and (min-width: 901px) and (max-width: 1200px) {
    .text-center-medium-only ul  { display: inline-block; width: auto; }
    .text-center-medium-only ol { display: inline-block; width: auto; }
    .text-center-medium-only dl { display: inline-block; width: auto; }
}

@media screen and (max-width: 1200px) {
    .text-center-medium ul { display: inline-block; width: auto; }
    .text-center-medium ol { display: inline-block; width: auto; }
    .text-center-medium dl { display: inline-block; width: auto; }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
    .text-center-small-only ul { display: inline-block; width: auto; }
    .text-center-small-only ol { display: inline-block; width: auto; }
    .text-center-small-only dl { display: inline-block; width: auto; }
}

@media screen and (max-width: 900px) {
    .text-center-small ul { display: inline-block; width: auto; }
    .text-center-small ol { display: inline-block; width: auto; }
    .text-center-small dl { display: inline-block; width: auto; }
}


@media screen and (min-width: 376px) and (max-width: 600px) {
    .text-center-tiny-only ul { display: inline-block; width: auto; }
    .text-center-tiny-only ol { display: inline-block; width: auto; }
    .text-center-tiny-only dl { display: inline-block; width: auto; }
}

@media screen and (max-width: 600px) {
    .text-center-tiny ul { display: inline-block; width: auto; }
    .text-center-tiny ol { display: inline-block; width: auto; }
    .text-center-tiny dl { display: inline-block; width: auto; }
}

@media screen and (max-width: 375px) {
    .text-center-micro ul { display: inline-block; width: auto; }
    .text-center-micro ol { display: inline-block; width: auto; }
    .text-center-micro dl { display: inline-block; width: auto; }
}

/*
    Ionic Lists
    - Ionic "lists" are not HTML lists. 
*/

ion-list {
    /* See ContentElements.scss */
}