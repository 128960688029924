/* ####################################
   Countdown Widget

   - Critical Rendering Path: Recommended
   - The most popular widget of all time
   ################################## */

.countdown {
    font-size: 200%;
}

    .countdown .counter {
        display: inline-block;
        box-sizing: border-box;
        min-width: 50px; /* Enough room for the "Minutes" label underneath. */
        margin-bottom: 30px; /* Account for the bottom position of .counter::after if it's absolutely positioned. */
        padding: 30px 40px;
        text-align: center;
        color: #ffffff;
        background: #000000;
        border-radius: 4px;
    }

        .countdown .counter::after {
            position: absolute;
            display: inline-block;
            width: 100%;
            text-align: center;
            font-size: 11px;
            font-weight: bold;
            color: #000;
            left: 0;
            bottom: -30px;
        }

        .countdown .counter.days {
            position: relative;
            margin-right: 5px;
        }

            .countdown .counter.days::after {
                content: "Days";
            }

        .countdown .counter.hours {
            position: relative;
            margin-right: 5px;
        }

            .countdown .counter.hours::after {
                content: "Hours";
            }

        .countdown .counter.minutes {
            position: relative;
            margin-right: 5px;
        }

            .countdown .counter.minutes::after {
                content: "Minutes";
            }

        .countdown .counter.seconds {
            background: rgba(0,0,0,0.75);
            position: relative;
        }

            .countdown .counter.seconds::after {
                content: "Seconds";
            }

    .countdown > :last-child {
        margin-right: 0 !important;
    }

    .countdown .separator {
        /* Separators come before the associated element, so there isn't one for days. */
        margin-left: -5px; /* Remove the margin after the units, there is some text leading that looks like a margin within the text block anyway. */
    }

        .countdown .separator.hours {
        }

        .countdown .separator.minutes {
        }

        .countdown .separator.seconds {
        }

    .countdown.expired {
        /* When the countdown is at or below 0. */
    }

        .countdown.expired .counter {
            background: #990000;
        }