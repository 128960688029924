// ##############################
//
// Alert boxes
// 
// ##############################

.alert-box {
    position: relative;
    clear: left;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 15px;
    padding: 30px;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
}

fieldset .alert-box {
    margin-left: 4px;
    margin-right: 4px;
    padding: 4px;
}

    .alert-box a:link {
    }

    .alert-box a:visited {
    }

    .alert-box h1,
    .alert-box h2,
    .alert-box h3,
    .alert-box h4,
    .alert-box p,
    .alert-box ul,
    .alert-box ol {
        margin: 0 0 0.75em;
    }

    .alert-box > h1:first-child,
    .alert-box > h2:first-child,
    .alert-box > h3:first-child,
    .alert-box > h4:first-child {
        box-sizing: border-box;
        font-size: 125%;
        font-weight: bold;
        margin: 0 0 0.25em;
        padding-bottom: 0;
        border: none;
        background: none;
    }

    .alert-box > ul li,
    .alert-box > ol li {
        margin: 0.25em 0 !important;
    }

    .alert-box > :first-child {
        margin-top: 0 !important;
    }

    .alert-box > :last-child {
        margin-bottom: 0 !important;
    }

.alert-information {
    color: #0c5460;
    background: #D4EFF2;
    border-color: #8ec5cb;
}

    .alert-information h1:first-child,
    .alert-information h2:first-child,
    .alert-information h3:first-child,
    .alert-information h4:first-child {
        color: #0c5460;
    }

.alert-success {
    color: #165A26;
    background: #E0F1E4;
    /**border-color: #c3e6cb;*/
    border-color: #86cA96;
}

    .alert-success h1:first-child,
    .alert-success h2:first-child,
    .alert-success h3:first-child,
    .alert-success h4:first-child {
        color: #165A26;
    }

.alert-warning {
    color: #684018;
    background: #FFF7E0;
    border-color: #cfc0aa;
}

    .alert-warning h1:first-child,
    .alert-warning h2:first-child,
    .alert-warning h3:first-child,
    .alert-warning h4:first-child {
        color: #684018;
    }

.alert-error {
    font-size: 87.5%;
    background: #ffeff0;
    border: #f2bcc4 1px solid;
}

    .alert-error h1:first-child,
    .alert-error h2:first-child,
    .alert-error h3:first-child,
    .alert-error h4:first-child {
        color: #721c24;
    }

.alert-inline {
    display: inline-block;
}

@media screen and (max-width: 375px) {

    .alert-box {
        padding: 15px;
    }

}
