/* ####################################
   Images
   ################################## */

img {
    max-width: 100%;
    height: auto;
    border: 0;
}

    img[style*="height"] {
        height: auto;
    }

    img[style*="display:block"],
    img[style*="display: block"] {
        /* This is how the image editor places an image in the middle of the page. Actually it's "display: block;margin-left: auto;margin-right: auto;" but that's a difficult CSS rule and this should catch the right images. */
        margin-top: 1.5em;
        margin-bottom: 1.5em;
    }

figure {
}

    figure.image {
        display: table; /* This + caption handling below is the only way to make all browsers show the image and caption together in a nice box. */
        box-sizing: border-box;
        max-width: 100%;
        margin: 0 0 20px;
        padding: 0;
        line-height: 0;
    }

        img[style*="float:left"],
        img[style*="float: left"],
        figure.image.align-left {
            float: left;
            max-width: 50%;
            height: auto;
            margin: 0 20px 20px 0;
        }

        img[style*="float:right"],
        img[style*="float: right"],
        figure.image.align-right {
            float: right;
            max-width: 50%;
            height: auto;
            margin: 0 0 20px 20px;
        }

        figure.image.align-center {
            /* Note: The visual editor will place this outside <p> blocks. */
            margin: 0 auto 20px; /* margin-left|right: auto; is the centering trick. */
        }

        figure.image img {
            float: none !important;
            max-width: 100%; /* Fix the max-width applied to floated images above, in case the image in the caption is still floated. */
            margin: 0;
        }

        figure.image figcaption {
            display: table-caption;
            caption-side: bottom;
            padding: 20px;
            text-align: left;
            line-height: normal;
            background: #faf8f7; /* Better than putting a background on the caption, to avoid an occasional 1px top gap in Chrome. */
        }

            figure.image figcaption > :first-child {
                margin-top: 0 !important;
            }

            figure.image figcaption > :last-child {
                margin-bottom: 0 !important;
            }

@media all and (-ms-high-contrast:none) {
    /* Rules for IE only. */

    figure.image.align-left,
    figure.image.align-right,
    figure.image.align-left figcaption,
    figure.image.align-right figcaption {
        display: block; /* IE doesn't like table in this context, but float handles the layout okay. */
    }

}

@media all and (-ms-high-contrast:none) and (max-width: 1200px) {
    /* Rules for IE only. */

    figure.image,
    figure.image figcaption {
        display: block;
    }

        figure.image img {
            width: 100%; /* Fill up the space so the caption looks okay. Not great for small images in centred captions, but if they're small why are they centred? */
        }

}

@media screen and (max-width: 900px) {

    img[style*="float:left"],
    img[style*="float: left"],
    figure.align-left,
    img[style*="float:right"],
    img[style*="float: right"],
    figure.align-right {
        /*max-width: 40%;*/ /* Depending on how much padding is in the design, this might need tweaking. */
    }

}

@media screen and (max-width: 600px) { /* Could consider going to 375px - It depends on the site content and design. */

    figure.align-left,
    figure.align-right,
    img[style*="float:left"],
    img[style*="float: left"],
    img[style*="float:right"],
    img[style*="float: right"] {
        float: none !important;
        max-width: 100% !important;
        margin: 0 0 1.5em !important;
    }

    figure.image img {
        margin: 0 !important; /* Fix the margin applied to floated images above, in case the image in the caption is still floated. */
    }

}
