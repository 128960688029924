/* ####################################
   Content Expander

   - Critical Rendering Path: Optional
   ################################## */

.expanderSection {
    display: inline-block; /* Behave better around floated elements, i.e. drop below them with width=100% */
    width: 100%;
    margin-bottom: 1.5em;
    border: #f0eceb 1px solid;
}

    .expanderSection .expanderSection {
        background-color: #fff; /* They can be placed inside each other to create a tree-like effect. */
    }

    .expanderSection + .expanderSection {
        margin-top: calc(-1.5em + 2px); /* When there's one after another, this creates a nice accordion-like effect. */
    }

    .expanderLink {
        position: relative;
        margin: 0;
        padding: 0;
    }

        .expanderLink a {
            position: relative;
            display: inline-block;
            box-sizing: border-box;
            width: 100%;
            margin: 0;
            padding: 20px;
        }

            .expanderLink a:hover {
            }

            .expanderLink a.expanded::after {
                content: "-";
            }

            .expanderLink a.collapsed::after {
                content: "+";
            }

            .expanderLink a::after {
                position: absolute;
                display: inline-block;
                text-indent: 0;
                top: 17px;
                right: 17px;
                font-family: sans-serif;
                font-size: 24px;
                font-weight: bold;
                width: 24px;
                line-height: 24px;
                text-align: center;
                border-color: inherit;
                border-width: 2px;
                border-style: solid;
                border-radius: 100%;
            }

    .expanderContent {
	    display: none; /* This will be switched on dynamically. */
        padding: 10px 20px 20px;
    }

        .expanderContent > :first-child {
            margin-top: 0 !important;
        }

        .expanderContent > :last-child {
            margin-bottom: 0 !important;
        }