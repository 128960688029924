// ###############################
//
// Boxes
//
// ###############################

.box {
    /* When added to a content element on its own, it doesn't do much. Should we include a standard background or something? We'd need to think about different contexts then. */
    box-sizing: border-box;
    width: 100%;
    padding: 30px;
    margin-bottom: 1.5em;
    border-radius: 3px;
    > :first-child {
        margin-top: 0 !important;
    }
    > :last-child {
        margin-bottom: 0 !important;
    }
    &.wide {
        /* Use wide boxes when you want the background colour to fill the screen but the content is inside a fixed width block. */
        box-sizing: content-box;
        margin-left: -10000em !important;
        margin-right: -10000em !important;
        padding-left: 10000em !important;
        padding-right: 10000em !important;
        border-radius: 0;
    }
    > .box-title {
        margin: 0 -30px 15px;
        padding: 20px 30px;
        color: #00243d;
        background: #cce7f0;
        &:first-child {
            margin-top: -30px;
        }
    }
}

@media screen and (max-width: 375px) {

    .box {
        padding: 15px;
    }

}

/*
    Box Styles
    - Intended for blocks/boxes/columns but could be used on anything.
    - Remember the helper classes (padding, margins, align, etc.) can be used with these as a general principle, so if you're creating highly specific block styles then inform the authors or override everything.
    - It may or may not be possible to combine block styles, depending on how they are set up. It's okay to create styles that shouldn't be combined with other styles.
    - To do: A cool colour spectrum rule set, incorporating backgrounds and borders and all sorts of stuff. Until then, we've got some basic block style ideas and suggestions and you can create your own set of styles.
    - Block styles are after background styles in the cascade in case they apply specific background colours.
*/

.box-outline {
    border: #d0cccb 1px solid;
}

.box-shadow-soft {
    box-shadow: 0 0 5px rgba(0,0,0,0.15);
}

.box-highlight {
    box-sizing: border-box;
    overflow: hidden;
    margin: 3em 0;
    font-size: 125%;
    font-style: italic;
    font-family: 'Times New Roman', Times, serif;
    background: #faf8f7;
    border-top: #d0cccb 3px solid;
    border-bottom: #d0cccb 3px solid;
    box-shadow: 5px 5px 20px rgb(0 0 0 / 15%);
    transform: rotate( -1.25deg );
}
