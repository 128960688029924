// ###############################
//
// Typopgrahy
//
// ###############################

ion-content {
    /* Just put typography rules here, look for page layout in layout related CSS files. */
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 18px;
    /*font-size: 118.75%;*/ /* 100% is 16px in default browser settings, so this is 19px which helps us achieve a baseline of "large" text for accessibility purposes (> 14pt/18.66px and bold, or 18pt/24px). */
}

a {
    cursor: pointer;
    color: #0088b2;
    text-decoration: underline;
}

    a:link {
        color: #0088b2;
        text-decoration: none;
    }

    a:visited {
        color: #0088b2;
        text-decoration: none;
    }

    a:hover {
        color: #000;
        text-decoration: underline;
    }

    a:active {
        text-decoration: underline;
    }

h1, h2, h3, h4, h5, h6, p, ul, ol {
    /* Give all the main elements position so we can inject stuff around them without stomping on them. */
    position: relative;
    z-index: 1;
}

p {
    margin: 0 0 1em;
    line-height: 135%;
}

    p.feature {
        padding: 30px;
        font-size: 116.7%;
        background-color: #fff;
        border-radius: 3px;
    }

    p.feature + p.feature {
        margin-top: -1.75em;
        padding-top: 0;
    }

    p a:link,
    li a:link {
        text-decoration: underline;
    }

h1 {
    margin: 0.25em 0 0.5em 0;
    line-height: 110%;
    font-size: 200%;
    font-weight: bold;
    font-family: Sommet, Arial, Helvetica, sans-serif;
}

h2 {
    clear: both; /* Make sure spacing doesn't go weird in small screen views. */
    margin: 1em 0 0.5em 0;
    font-size: 150%;
    font-weight: bold;
    font-family: Sommet, Arial, Helvetica, sans-serif;
}

h3 {
    margin: 1.5em 0 0.75em 0;
    font-size: 125%;
    font-weight: 500;
}

h4 {
    margin: 1.5em 0 0.75em 0;
    font-size: 100%;
}

h5 {
    margin: 1.5em 0 0.75em 0;
    color: #777777;
    font-size: 100%;
}

h6 {
    margin: 1.5em 0 0.75em 0;
    font-size: 91.7%;
    border-bottom: #e0dcdb 1px solid;
}

    h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
        font-weight: inherit;
    }

    h1.feature,
    h2.feature,
    h3.feature,
    h4.feature,
    h5.feature,
    h6.feature {
        position: relative;
        margin-top: 2.5em;
        margin-bottom: 1.5em;
        padding-bottom: 0.75em;
    }

        h1.feature::after,
        h2.feature::after,
        h3.feature::after,
        h4.feature::after,
        h5.feature::after,
        h6.feature::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            width: 270px;
            background: rgba(93, 125, 141, 0.25);
        }

        h1.feature[style*="text-align: center"],
        h1.feature[style*="text-align:center"],
        h2.feature[style*="text-align: center"],
        h2.feature[style*="text-align:center"],
        h3.feature[style*="text-align: center"],
        h3.feature[style*="text-align:center"],
        h4.feature[style*="text-align: center"],
        h4.feature[style*="text-align:center"],
        h5.feature[style*="text-align: center"],
        h5.feature[style*="text-align:center"],
        h6.feature[style*="text-align: center"],
        h6.feature[style*="text-align:center"] {
            text-align: center;
        }

            h1.feature[style*="text-align: center"]::after,
            h1.feature[style*="text-align:center"]::after,
            h2.feature[style*="text-align: center"]::after,
            h2.feature[style*="text-align:center"]::after,
            h3.feature[style*="text-align: center"]::after,
            h3.feature[style*="text-align:center"]::after,
            h4.feature[style*="text-align: center"]::after,
            h4.feature[style*="text-align:center"]::after,
            h5.feature[style*="text-align: center"]::after,
            h5.feature[style*="text-align:center"]::after,
            h6.feature[style*="text-align: center"]::after,
            h6.feature[style*="text-align:center"]::after {
                left: calc(50% - 135px);
            }

        h1.feature[style*="text-align: right"],
        h1.feature[style*="text-align:right"],
        h2.feature[style*="text-align: right"],
        h2.feature[style*="text-align:right"],
        h3.feature[style*="text-align: right"],
        h3.feature[style*="text-align:right"],
        h4.feature[style*="text-align: right"],
        h4.feature[style*="text-align:right"],
        h5.feature[style*="text-align: right"],
        h5.feature[style*="text-align:right"],
        h6.feature[style*="text-align: right"],
        h6.feature[style*="text-align:right"] {
            text-align: center;
        }

            h1.feature[style*="text-align: right"]::after,
            h1.feature[style*="text-align:right"]::after,
            h2.feature[style*="text-align: right"]::after,
            h2.feature[style*="text-align:right"]::after,
            h3.feature[style*="text-align: right"]::after,
            h3.feature[style*="text-align:right"]::after,
            h4.feature[style*="text-align: right"]::after,
            h4.feature[style*="text-align:right"]::after,
            h5.feature[style*="text-align: right"]::after,
            h5.feature[style*="text-align:right"]::after,
            h6.feature[style*="text-align: right"]::after,
            h6.feature[style*="text-align:right"]::after {
                right: 0;
            }

.huge {
    font-size: 150%;
    line-height: 125%;
}

    p.huge {
        margin-bottom: 1em;
    }

.big, big {
    font-size: 116.7%;
}

.small, small {
    font-size: 91.7%;
}

.tiny {
    font-size: 83.3%;
}

@media screen and (max-width: 1200px) {

    body {
        -webkit-text-size-adjust: none; /* Without this iPhone messes around with font sizes in portrait/landscape. */
    }
}

@media screen and (max-width: 600px) {

    body {
        font-size: 100%;
    }

}

@media screen and (max-width: 375px) {

    body {
        font-size: 93.75%;
    }

}