// ##############################
//
// Content Collections
// 
// ##############################

/* Content Items these usually appear in widgets on the website */

.contentItem {
    position: relative; /* Make sure other stuff happens. */
    .header {

    }
    .thumbnail {
        line-height: 0;
        margin-right: 15px; /* Note: Works with flex, causes problems with floats. */
        img {

        }
        &.original {
            img {
                max-width: 100%;
                max-height: none;
                width: auto;
                height: auto;
            }
        }
        &:not(.original) {
            img {
                width: 100%;
                max-width: 100%;
                height: auto;
                max-height: none;
            }
        }
        &.huge {
            width: 320px;
        }
        &.large {
            width: 240px;
        }
        &.medium {
            width: 160px;
        }
        &.small {
            width: 100px;
        }
        &.tiny {
            width: 80px;
        }
    }
    .text {
        .title {
            .publishInfo {
                margin-bottom: 10px;
                .date,
                .author {
                    display: inline-block;
                }
            }
        }
        .summary {
            margin: 0 0 1.5em;
            .readMore {
                display: block;
                margin-top: 0.75em;
            }
        }
    }
    &:first-child,
    &.last-child {
        margin-top: 0 !important;
    }
}

/* .imageLeft and .imageRight for content items */

.imageLeft,
.imageRight {
    .contentItem {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
        margin-bottom: 1.5em;
        .thumbnail {
            max-width: 25%;
            flex-shrink: 0; /* Don't get smaller. */
        }
        .text {
            width: 100%;
            flex-grow: 0; /* Don't grow and push the image around. */
            .title {
                margin-top: 0;
            }
        }
    }
}

.imageLeft {
    .contentItem {
        .thumbnail {
            order: 1;
        }
        .text {
            order: 2;
        }
    }
}

.imageRight {
    .contentItem {
        .thumbnail {
            order: 2;
            margin-left: 15x;
            margin-right: 0;
        }
        .text {
            order: 1;
        }
    }
}

/* Cards stlying for contentItems */

.cards {
    display: inline-block;
    width: 100%; /* For IE11, which we still support. */
    .paging {
    }
    .content {
        .contentItem {
            position: relative;
            box-sizing: border-box;
            float: left;
            clear: none;
            width: 32%;
            margin: 0 0 1.5em;
            overflow: hidden;
            background: #fff;
            box-shadow: 0 3px 5px rgb(0 0 0 / 15%);
            border-radius: 3px;
            &:nth-child(3n-1) {
                /* The middle one */
                margin: 0 2% 1.5em; 
            }
            &:nth-child(3n+1) {
                clear: left;
            }
            .thumbnail {
                position: relative;
                float: left;
                max-width: 100%;
                width: 100%;
                /*height: 200px;*/
                padding: 0 !important;
                box-sizing: border-box;
                text-align: center;
                overflow: hidden;
                a {
                    img {
                        display: block;
                        min-width: 100%;
                        min-height: 100%;
                        max-width: 100% !important;
                    }
                }
            }
            .text {
                display: flex;
                flex-flow: wrap;
                box-sizing: border-box;
                position: relative;
                float: left;
                width: 100%;
                padding: 20px;
                * {
                    width: 100%;
                }
                .title {
                    margin-top: 0;
                    a {
                        display: block;
                        margin: 0;
                    }
                }
                .publishInfo {
                    display: inline-block;
                    width: 100%; /* Otherwise it does odd things in .masonry views */
                    box-sizing: border-box;
                    /*order: -1;*/ /* Make sure it's first in the flex wrap .text box */ 
                    * {

                    }
                }
                .summary {
                    .readMore {
                        display: block;
                        margin-top: 10px;
                    }
                }
                &:last-child {
                    margin-bottom:  0 !important;
                }
            }
        }
    }
    .noContent {
        margin: 0 0 1.5em;
    }
    &.panorama .content .contentItem {
        .thumbnail {
            position: relative;
            height: 150px;
            max-height: 150px;
            img,
            a img {
                position: absolute;
                top: -99999em;
                right: -99999em;
                left: -99999em;
                bottom: -99999em;
                margin: auto !important;
                min-width: 100%;
                min-height: 100%;
                max-width: none;
                max-height: none;
                width: auto;
                height: auto;
                margin: auto;
            }
        }
    }
    &.panorama.fullWidth .content .contentItem {
        width: 100% !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    &.padded .content .contentItem {
        .thumbnail {
            width: calc(100% - 40px);
            margin: 20px 20px 0 !important;
        }
        .text {
            .date {
            }
        }
    }
    &.dateOverlay .content .contentItem {
        .text {
            .publishInfo {
                /* Note: Won't work well when there's no thumbnail, it'll disappear outside the .card box boundary */
                position: absolute;
                left: 0;
                top: -60px;
                /*background-color: rgba(255,255,255,0.75);*/
                padding: 15px 20px;
                font-size: 0.925rem;
                font-weight: bold;
                line-height: 125%;
            }
        }
    }
    &.padded.dateOverlay .content .contentItem {
        .text {
            .publishInfo {
                padding-left: 45px;
                * {
                    color: #fff;
                    text-shadow: 1px 1px 3px rgba(0,0,0,1); 
                }
            }
        }
    }
}

/* Responsive styling for cards */

@media screen and (max-width: 767px) {
    .cards .content .contentItem {
        width: 47.5%;
        &:nth-child(3n+1) {
            clear: none;
        }
        &:nth-child(3n-1) {
            margin: 0 0 40px;
        }
        &:nth-child(2n-1) {
            margin: 0 5% 40px 0;
        }
    }
}

@media screen and (max-width: 480px) {
    .cards .content .contentItem {
        width: 100%;
        &:nth-child(3n-1),
        &:nth-child(2n-1) {
            margin: 0 0 40px 0 !important;
        }
    }
}

/*
    Sub Pages
*/

.subpages-links {
    /* The standard Ionic list layout applies to .subpages-links. */
}

.subpages-buttons {
    /* The standard Ionic list layout applies to .subpages-buttons. */
    ion-thumbnail {
        position: relative;
        width: 200px;
        max-width: 25%;
        margin-right: 15px;
        &:after {
            content: "";
            position: relative;
            padding-top: 100%;
        }
    }
}

.subpages-summary {
    @extend .imageLeft;
    background-color: #fff;
    .contentItem {
        margin-bottom: 0;
        padding: 20px;

        &:not(:first-child) {
            border-top: #decee6 1px solid
        }

        .thumbnail {
            width: 200px;
            img {
                border-radius: 3px;
            }
        }

        .text {
            .title {
                font-size: 115%;
            }
            .summary {
                font-size: 90%;
                margin-bottom: 0;
            }
        }

        &::after {
            content: "\f054";
            order: 3;
            margin-left: 15px;
            font-family: "Font Awesome 5 Pro";
            font-weight: 600;
        }

    }

}

@media screen and (max-width: 375px) {
    .subpages-summary {
        /*padding: 0 15px;*/
    }
}

.subpages-tiles {
    @extend .cards;
    button {
        @extend .button;
    }
}

.subpages-video {
    @extend .cards;
    button {
        @extend .button;
        @extend .icon-video;
    }
}