// ##############################
//
// Forms
// 
// ##############################

/* ####################################
   Ionic Form Controls
   ################################## */

ion-input {
    border-radius: 3px;
}

ion-checkbox {
    /*--background-checked: transparent;*/
    --size: 24px;
    --checkmark-width: 5px;
}

ion-toggle {
  --background: rgb(172, 172, 172);
  --background-checked: rgb(172, 172, 172);
  --handle-background: #333;
  --handle-background-checked: #0ba374;
}

ion-select {
    border: #99a 1px solid;
    border-radius: 3px;
}

ion-segment-button {
    --color: #fff;
    --color-hover: #fff;
    --color-checked: #0ba374;
    justify-content: space-between;
}

input,
select {
    background-color: #fff;
    padding: 5px;
    max-width: 100%;
    width: 100%;
}

/* ####################################
   CMS Forms Layout
   ################################## */

   .formPanel, 
   .dataContainer {
       position: relative;
       float: none;
       clear: both; /* RW: Is this a good idea? */
       margin: 0;
       padding: 0;
       width: 100%;
       margin-bottom: 1.5em;
   }
   
   fieldset, 
   .fieldset {
       position: relative;
       display: inline-block;
       box-sizing: border-box;
       width: 100%;
       max-width: 100%;
       min-width: 100%; /* Important for some browsers (including Firefox and Chrome) to stop growing too wide with visual editors and other content with stubborn width settings. */
       padding: 20px;
       margin: 0 0 1.5em;
       border: #ebebeb 2px solid;
       border-radius: 0;
   }
   
       fieldset legend, 
       .fieldset .legend {
           position: relative;
           float: left;
           box-sizing: content-box;
           width: 100%;
           padding: 10px 22px;
           margin: -22px -22px 22px;
           color: #000000;
           font-weight: bold;
           font-size: 125%; /* Default is equivalent to h3. */
           background: #ebebeb;
       }
   
       fieldset .summary, fieldset p, fieldset h1, fieldset h2, fieldset h3, fieldset h4, fieldset h5, fieldset h6,
       .fieldset .summary, .fieldset p, .fieldset h1, .fieldset h2, .fieldset h3, .fieldset h4, .fieldset h5, .fieldset h6 {
           clear: left; /* Fix floating next to div.legend */
       }
   
   /* ####################################
      Form & Data Fields Lists
      ################################## */
   
   .formFields, 
   .dataFields {
       display: inline-block !important; /* Nothing should mess with this. Make sure floated elements inside the form don't cause headaches for things around the form. */
       box-sizing: border-box;
       width: 100% !important; /* Nothing should mess with this. */
       padding: 25px !important; /* Nothing should mess with this. */
       margin: 0 !important;  /* Nothing should mess with this. */
       list-style: none !important;  /* Nothing should mess with this. */
        background: #fff;
        border-radius: 3px;
        box-shadow: 0 0 px rgba(0,0,0,0.15);
   }
   
       .formFields > li, 
       .dataFields > li {
           list-style: none;
       }
   
       /* Don't inherit list-style for child lists of .formFields by default, because they're probably regular content. */
   
       .formFields ol, 
       .dataFields ol {
           list-style: decimal;
           margin-bottom: 1.5em;
       }
   
       .formFields ul, 
       .dataFields ul {
           list-style: disc;
           margin-bottom: 1.5em;
       }
   
   /* ####################################
      Form & Data Field Wrappers (usually inside lists)
      ################################## */
   
   .fieldContainer, 
   .formFields > li, 
   .dataFields > li {
       position: relative;
       float: left;
       clear: left;
       box-sizing: border-box;
       width: 100%;
       padding: 0;
       margin: 0.5em 0;
   }
   
       /* The following wrappers should be inside a .fieldContainer wrapper if the .fieldContainer class is used. However the CSS below does not specify .fieldContainer so that the following classes can also be used when .fieldContainer is not used (e.g. in simple forms). */
   
       .fieldTitle, 
       .formFields label, 
       .dataFields label {
           position: relative;
           float: left;
           box-sizing: border-box;
           width: 20%;
           padding: 10px 10px 10px 0;
           font-weight: bold;

           @media screen and (max-width: 900px) {
            width: 100%;
        }
       }
   
       .fieldContent, 
       .fieldControls, 
       .fieldData, 
       .fieldText {
           /* Legacy support: It's possible due to old code that .fieldControls/.fieldData/.fieldText exists without a .fieldContent wrapper. */
           display: block;
           position: relative;
           float: left;
           box-sizing: border-box;
           width: 100%;
           max-width: 80%;

           @media screen and (max-width: 900px) {
               max-width: 100%;
           }
       }
   
               .fieldContent .fieldControls, 
               .fieldContent .fieldData, 
               .fieldContent .fieldText {
                   /* When these fields ARE inside the .fieldContent wrapper. Might need to override settings from .fieldControls/.fieldData above. */
                   display: inline-block;
                   float: none;
                   box-sizing: border-box;
                   padding: 0;
                   width: 100%; /* Make sure the contents are contained and don't spill outside. Adjust this and .helperControl if you want help notes floating to the right of a field. */
                   max-width: 100%;
                   border-radius: 0;
                   background: none;
               }
   
               .fieldContent .fieldData, 
               .fieldContent .fieldText {
                   padding-top: 10px;
               }
   
               .fieldContent .fieldData > :last-child, 
               .fieldContent .fieldText > :last-child {
                   margin-bottom: 0 !important;
               }
   
               .fieldData ~ *, .fieldText ~ * {
                   /* Default separation for elements below .fieldText. You might want to override this in some cases. */
                   margin-top: 1em !important;
               }
   
                   .fieldContent .fieldData ul, 
                   .fieldContent .fieldText ul, 
                   .fieldContent .fieldData ol, 
                   .fieldContent .fieldText ol {
                       margin: 0.75em 0;
                   }
                                           
                       .fieldContent .fieldData ul li, 
                       .fieldContent .fieldText ul li {
                           list-style: disc;
                           margin: 0 0 5px !important;
                       }
   
               .fieldContent .fieldContentTop {
                   display: inline-block;
                   margin: 0 0 4px;
                   width: 100%;
               }
   
               .fieldContent .fieldContentBottom {
                   display: inline-block;
                   clear: left;
                   margin: 4px 0 0;
                   width: 100%;
               }
   
               .fieldContent .helperControl {
                   margin-top: 10px;
                   padding-left: 24px;
               }
   
                   .fieldContent .helperControl:before {
                       content: "i";
                       position: relative;
                       display: inline-block;
                       vertical-align: middle;
                       box-sizing: border-box;
                       padding: 1px 3px;
                       margin: -3px 3px 0 -24px;
                       width: 16px;
                       font-family: Courier New, Courier, monospace;
                       font-weight: bold;
                       font-size: 13px;
                       line-height: 11px;
                       color: #0000d8;
                       border: #0000d8 2px solid;
                       border-radius: 100%;
                   }
   
                   .fieldControls > .helperControl {
                       vertical-align: top;
                   }
   
               .fieldContent > h1,
               .fieldContent > h2,
               .fieldContent > h3,
               .fieldContent > h4,
               .fieldContent > h5,
               .fieldContent > h6,
               .fieldContent > p,
               .fieldContent > ul, 
               .fieldContent > ol {
                   margin: 0.25em 0;
               }

               .fieldControls.fileUpload {
                   .file-input-container {
                       label {
                        //   text-align: center; 
                       } 
                   }
                   h2 {
                        //    text-align: right;
                       }
                    .list-md {
                    }
               }