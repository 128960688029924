// ###############################
//
// Blocks
//
// ###############################

/*
    Background Styles
    - Intended for blocks/boxes/columns but could be used on anything.
    - 9.6.1 note: Link to text to background colors are not all accessible without additional link styles in the default styleshet right now. Do your own tests.
*/

/* Main Color */

.background-lighter {
    color: #000;
    background-color: #f8fbfc;
}

.background-light {
    color: #000;
    /*background-color: #e1e1e5;*/
    background-color: #f0f7ff;
}

    .background-light a:link,
    .background-penumbra a:visited {
        color: #006A8A;
    }

.background-penumbra {
    color: #000;
    background-color: #7cc0d7;
}

    .background-penumbra h1,
    .background-penumbra h2,
    .background-penumbra h3,
    .background-penumbra h4,
    .background-penumbra h5,
    .background-penumbra h6 {
        color: #000;
    }

    .background-penumbra a:link,
    .background-penumbra a:visited {
        color: #004A61;
        &:hover {
            color: #000;
        }
    }

    .background-penumbra .cards .contentItem {
        background: #fff;
    }

.background-dark {
    color: #fff;
    /*background-color: #0088b2;
    background-color: #007da3;*/
    background-color: #0081A8;
}

    .background-dark h1,
    .background-dark h2,
    .background-dark h3,
    .background-dark h4,
    .background-dark h5,
    .background-dark h6 {
        color: #fff;
    }

    .background-dark a:link,
    .background-dark a:visited {
        color: #000;
        &:hover {
            color: #d0ffff;
        }
    }

    .background-dark .cards .contentItem {
        background: #fff;
    }

.background-darker {
    color: #fff;
    background-color: #005e7a;
}

    .background-darker h1,
    .background-darker h2,
    .background-darker h3,
    .background-darker h4,
    .background-darker h5,
    .background-darker h6 {
        color: #fff;
    }

    .background-darker a:link,
    .background-darker a:visited {
        color: #80defa;
        &:hover {
            color: #d0ffff !important;
        }
    }

    .background-darker .cards .contentItem {
        background-color: #fff;
    }

/* Alternate Color */

.background-lighter.background-alternate {
    background-color: #f6fbf8;
}

.background-light.background-alternate {
    background-color: #e5f3e6;
}

.background-penumbra.background-alternate {
    background-color: #8DBBA6; 
}

    .background-penumbra.background-alternate h1,
    .background-penumbra.background-alternate h2,
    .background-penumbra.background-alternate h3,
    .background-penumbra.background-alternate h4,
    .background-penumbra.background-alternate h5,
    .background-penumbra.background-alternate h6 {
        color: #000; 
    }

    .background-penumbra.background-alternate a:link,
    .background-penumbra.background-alternate a:visited {
        color: #074639;
        &:hover {
            color: #000 !important;
        }
    }

.background-dark.background-alternate {
    background-color: #358366;
}

    .background-dark.background-alternate a:link,
    .background-dark.background-alternate a:visited {
        color: #ababab; /* Fails AA compared to #fff body text. Be sure to use additional link highlighting methods, e.g. underline. */
    }

    .background-dark.background-alternate a:link,
    .background-dark.background-alternate a:visited {
        color: #000;
        &:hover {
            color: #d0ffff;
        }
    }

    .background-dark.background-alternate .cards .contentItem {
        background: #fff;
    }

.background-darker.background-alternate {
    background-color: #156346;
}

    .background-darker.background-alternate a:link,
    .background-darker.background-alternate a:visited {
        color: #80fade;
        &:hover {
            color: #d0ffff !important;
        }
    }

    .background-darker.background-alternate .cards .contentItem {
        background-color: #fff;
    }

/* 
    Lighten and Darken Backgrounds
    - E.g. when a box is inside another box, or when you want hover or other special effects.
    - By default we use black and white tones, but the shading colours might need tweaking for use in different scenarios.
*/

.background-lighten {
    background-color: rgba(255,255,255,0.25);
}

.background-darken {
    background-color: rgba(0,0,0,0.25);
}

/* Black and White + Lighten and Darken */

.background-white, %background-white {
    color: #000;
    background-color: #fff;

    /* White boxes often appear inside dark colour boxes, so be sure to update the heading and link styles. */

    h1, h2, h3, h4, h5, h6 {
        color: #000;
    }

    a:link,
    a:visited {
        color: #006cb7;
        &:hover {
            color: #000;
        }
    }
}

.background-lightest-grey {
    background-color: #f9f9fb;
}

.background-light-grey {
    background-color: #edf0f1;
}

.background-grey {
    background-color: #dee3e6;
}

.background-black {
    color: #fff;
    background-color: #000;
}

    .background-black h1,
    .background-black h2,
    .background-black h3,
    .background-black h4,
    .background-black h5,
    .background-black h6 {
        color: #fff;
    }

    .background-black a:link,
    .background-black a:visited {
        color: #80defa;
        &:hover {
            color: #d0ffff !important;
        }
    }

    .background-black .cards .contentItem {
        background-color: #fff;
    }

/* Gradients */

.background-gradient-light {
    background-color: #eae8e7;
    background: linear-gradient(0deg, #eae8e7 0%, #fff 75%)
}

/*
    Block ratios
    - Direct descendants of a block are absolutely positioned, so it only makes sense to have one. Put other content inside if needed.
    - .video is deprecated and only included for backward CMS compatibility if you really need to be lazy, but it's better to upgrade your content.
*/

.video, 
.ratio {
    /* When an iframe is the only child of a paragraph or div and doesn't have a style attribute. We're trying to catch video embeds. This isn't a perfect rule but should catch video-only blocks while missing floated/styled elements. */
    position: relative;
}

    .video > *,
    .ratio > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .ratio:before {
        content: "";
        display: block;
    }

        .ratio-1x1:before, 
        .ratio-square:before {
            padding-top: 100%;
        }

        .ratio-3x4:before, 
        .ratio-portrait:before {
            padding-top: calc(4 / 3 * 100%);
        }

        .ratio-4x3:before, 
        .ratio-landscape:before {
            padding-top: calc(3 / 4 * 100%);
        }

        .video:before,
        .ratio-16x9:before, 
        .ratio-wide:before {
            padding-top: calc(9 / 16 * 100%); /* 16:9 suits common Internet video formats - Youtube, Vimeo, DailyMotion, etc. */
        }

        .ratio-21x9:before, 
        .ratio-panorama:before {
            padding-top: calc(9 / 21 * 100%);
        }

        .ratio-21x7:before, 
        .ratio-ultra-wide:before {
            padding-top: calc(7 / 21 * 100%);
        }

// ###############################
//
// Content Classes
//
// ###############################

.borders-none { border: 0; }
.border-top-none { border-top: 0; }
.border-right-none { border-right: 0; }
.border-bottom-none { border-bottom: 0; }
.border-left-none { border-left: 0; }

.rectangle, .squared { border-radius: 0 !important; }
.rounded-less { border-radius: 3px !important; }
.rounded { border-radius: 6px !important; }
.rounded-more { border-radius: 10px !important; }
.ellipse { border-radius: 100% !important; }

.text-left { text-align: left !important; }
.text-center { text-align: center !important; }
.text-right { text-align: right !important; }

.padding-all { padding: 30px !important; }
.padding-left { padding-left: 30px !important; }
.padding-right { padding-right: 30px !important; }
.padding-top { padding-top: 30px !important; }
.padding-bottom { padding-bottom: 30px !important; }
.padding-all-thin { padding: 15px !important; }
.padding-left-thin { padding-left: 15px !important; }
.padding-right-thin { padding-right: 15px !important; }
.padding-top-thin { padding-top: 15px !important; }
.padding-bottom-thin { padding-bottom: 15px !important; }
.padding-all-thick { padding: 60px !important; }
.padding-left-thick { padding-left: 60px !important; }
.padding-right-thick { padding-right: 60px !important; }
.padding-top-thick { padding-top: 60px !important; }
.padding-bottom-thick { padding-bottom: 60px !important; }
.padding-all-none { padding: 0 !important; }
.padding-left-none { padding-left: 0 !important; }
.padding-right-none { padding-right: 0 !important; }
.padding-top-none { padding-top: 0 !important; }
.padding-bottom-none { padding-bottom: 0 !important; }

.margins-all { margin: 1.5em !important; }
.margins-vertical { margin-top: 1.5em !important; margin-bottom: 1.5em !important; }
.margins-horizontal { margin-left: 1.5em !important; margin-right: 1.5em !important; }
.margin-left { margin-left: 1.5em !important; }
.margin-right { margin-right: 1.5em !important; }
.margin-top { margin-top: 1.5em !important; }
.margin-bottom { margin-bottom: 1.5em !important; }
.margins-all-thin { margin: 0.75em !important; }
.margins-vertical-thin { margin-top: 0.75em !important; margin-bottom: 0.75em !important; }
.margins-horizontal-thin { margin-left: 0.75em !important; margin-right: 0.75em !important; }
.margin-left-thin { margin-left: 0.75em !important; }
.margin-right-thin { margin-right: 0.75em !important; }
.margin-top-thin { margin-top: 0.75em !important; }
.margin-bottom-thin { margin-bottom: 0.75em !important; }
.margins-all-thick { margin: 3em !important; }
.margins-vertical-thick { margin-top: 3em !important; margin-bottom: 3em !important; }
.margins-horizontal-thick { margin-left: 3em !important; margin-right: 3em !important; }
.margin-left-thick { margin-left: 3em !important; }
.margin-right-thick { margin-right: 3em !important; }
.margin-top-thick { margin-top: 3em !important; }
.margin-bottom-thick { margin-bottom: 3em !important; }
.margins-all-thicker { margin: 6em !important; }
.margins-vertical-thicker { margin-top: 6em !important; margin-bottom: 6em !important; }
.margins-horizontal-thicker { margin-left: 6em !important; margin-right: 6em !important; }
.margin-left-thicker { margin-left: 6em !important; }
.margin-right-thicker { margin-right: 6em !important; }
.margin-top-thicker { margin-top: 6em !important; }
.margin-bottom-thicker { margin-bottom: 6em !important; }
.margins-none,.margins-all-none { margin: 0 !important; }
.margin-left-none { margin-left: 0 !important; }
.margin-right-none { margin-right: 0 !important; }
.margin-top-none { margin-top: 0 !important; }
.margin-bottom-none { margin-bottom: 0 !important; }
.margins-vertical-none { margin-top: 3em !important; margin-bottom: 3em !important; }
.margins-horizontal-none { margin-left: 3em !important; margin-right: 3em !important; }

.align-items-top { align-items: flex-start; }
.align-items-center { align-items: center; }
.align-items-bottom { align-items: flex-end; }
.align-items-stretch { align-items: stretch; }
.align-items-baseline { align-items: baseline; }

.justify-content-start { justify-content: flex-start; }
.justify-content-center { justify-content: center; }
.justify-content-end { justify-content: flex-end; }
// .width-fixed wide { max-width: 1920px; width: 100%; margin: 0 auto; }
// .width-fixed partial { max-width: 1200px; width: 100%; margin: 0 auto; }
/*.width-fixed .wide.visible { opacity: 1; transition: opacity 1.5s; }
.width-fixed .wide.hidden { opacity: 0; }
.width-fixed .partial.visible { opacity: 1; transition: opacity 1.5s; }
.width-fixed .partial.hidden { opacity: 0; }*/

.width-full {
    display: inline-block !important;
    box-sizing: border-box;
    width: 100% !important;
    float: none !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.width-fixed {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin-left: auto !important;
    margin-right: auto !important;
}

    .width-wide {
        max-width: 1920px;
    }

    .width-thin {
        max-width: 800px;
    }

.maxwidth-contain { max-width: 100% !important; }

@media screen and (min-width: 901px) and (max-width: 1200px) {

    .text-left-medium-only { text-align: left !important; }
    .text-center-medium-only { text-align: center !important; }
    .text-right-medium-only { text-align: right !important; }

    .padding-all-medium-only { padding: 30px !important; }
    .padding-left-medium-only { padding-left: 30px !important; }
    .padding-right-medium-only { padding-right: 30px !important; }
    .padding-top-medium-only { padding-top: 30px !important; }
    .padding-bottom-medium-only { padding-bottom: 30px !important; }
    .padding-vertical-medium-only { padding-top: 30px !important; padding-bottom: 30px !important; }
    .padding-horizontal-medium-only { padding-left: 30px !important; padding-right: 30px !important; }
    .padding-all-thin-medium-only { padding: 15px !important; }
    .padding-left-thin-medium-only { padding-left: 15px !important; }
    .padding-right-thin-medium-only { padding-right: 15px !important; }
    .padding-top-thin-medium-only { padding-top: 15px !important; }
    .padding-bottom-thin-medium-only { padding-bottom: 15px !important; }
    .padding-vertical-thin-medium-only { padding-top: 30px !important; padding-bottom: 30px !important; }
    .padding-horizontal-thin-medium-only { padding-left: 30px !important; padding-right: 30px !important; }
    .padding-thick-all-medium-only { padding: 60px !important; }
    .padding-left-thick-medium-only { padding-left: 60px !important; }
    .padding-right-thick-medium-only { padding-right: 60px !important; }
    .padding-top-thick-medium-only { padding-top: 60px !important; }
    .padding-bottom-thick-medium-only { padding-bottom: 60px !important; }
    .padding-vertical-thick-medium-only { padding-top: 60px !important; padding-bottom: 60px !important; }
    .padding-horizontal-thick-medium-only { padding-left: 60px !important; padding-right: 60px !important; }
    .padding-all-none-medium-only { padding: 0 !important; }
    .padding-left-none-medium-only { padding-left: 0 !important; }
    .padding-right-none-medium-only { padding-right: 0 !important; }
    .padding-top-none-medium-only { padding-top: 0 !important; }
    .padding-bottom-none-medium-only { padding-bottom: 0 !important; }
    .padding-vertical-none-medium-only { padding-top: 0 !important; padding-bottom: 0 !important; }
    .padding-horizontal-none-medium-only { padding-left: 0 !important; padding-right: 0 !important; }

    .margins-all-medium-only { margin: 1.5em; }
    .margins-vertical-medium-only { margin-top: 1.5em; margin-bottom: 1.5em; }
    .margins-horizontal-medium-only { margin-left: 1.5em; margin-right: 1.5em; }
    .margin-left-medium-only { margin-left: 1.5em; }
    .margin-right-medium-only { margin-right: 1.5em; }
    .margin-top-medium-only { margin-top: 1.5em; }
    .margin-bottom-medium-only { margin-bottom: 1.5em; }
    .margins-all-thin-medium-only { margin: 0.75em; }
    .margins-vertical-thin-medium-only { margin-top: 0.75em; margin-bottom: 0.75em; }
    .margins-horizontal-thin-medium-only { margin-left: 0.75em; margin-right: 0.75em; }
    .margin-left-thin-medium-only { margin-left: 0.75em; }
    .margin-right-thin-medium-only { margin-right: 0.75em; }
    .margin-top-thin-medium-only { margin-top: 0.75em; }
    .margin-bottom-thin-medium-only { margin-bottom: 0.75em; }
    .margins-all-thick-medium-only { margin: 3em; }
    .margins-vertical-thick-medium-only { margin-top: 3em; margin-bottom: 3em; }
    .margins-horizontal-thick-medium-only { margin-left: 3em; margin-right: 3em; }
    .margin-left-thick-medium-only { margin-left: 3em; }
    .margin-right-thick-medium-only { margin-right: 3em; }
    .margin-top-thick-medium-only { margin-top: 3em; }
    .margin-bottom-thick-medium-only { margin-bottom: 3em; }
    .margins-all-thicker-medium-only { margin: 6em; }
    .margins-vertical-thicker-medium-only { margin-top: 6em; margin-bottom: 6em; }
    .margins-horizontal-thicker-medium-only { margin-left: 6em; margin-right: 6em; }
    .margin-left-thicker-medium-only { margin-left: 6em; }
    .margin-right-thicker-medium-only { margin-right: 6em; }
    .margin-top-thicker-medium-only { margin-top: 6em; }
    .margin-bottom-thicker-medium-only { margin-bottom: 6em; }
    .margins-none-medium-only,.margins-all-none-medium-only { margin: 0; }
    .margin-left-none-medium-only { margin-left: 0; }
    .margin-right-none-medium-only { margin-right: 0; }
    .margin-top-none-medium-only { margin-top: 0; }
    .margin-bottom-none-medium-only { margin-bottom: 0; }
    .margins-vertical-none-medium-only { margin-top: 3em; margin-bottom: 3em; }
    .margins-horizontal-none-medium-only { margin-left: 3em; margin-right: 3em; }

    .align-items-top-medium-only { align-items: flex-start; }
    .align-items-center-medium-only { align-items: center; }
    .align-items-bottom-medium-only { align-items: flex-end; }
    .align-items-stretch-medium-only { align-items: stretch; }
    .align-items-baseline-medium-only { align-items: baseline; }

    .width-full-medium-only {
        display: inline-block !important; 
        box-sizing: border-box; 
        width: 100% !important; 
        float: none !important; 
        margin-right: 0 !important; 
        margin-left: 0 !important;
    }
    .maxwidth-contain-medium-only { max-width: 100% !important; }

    .hide-medium-only { display: none; }

}

@media screen and (max-width: 1200px) {

    .text-left-medium { text-align: left !important; }
    .text-center-medium { text-align: center !important; }
    .text-right-medium { text-align: right !important; }

    .padding-all-medium { padding: 30px !important; }
    .padding-left-medium { padding-left: 30px !important; }
    .padding-right-medium { padding-right: 30px !important; }
    .padding-top-medium { padding-top: 30px !important; }
    .padding-bottom-medium { padding-bottom: 30px !important; }
    .padding-vertical-medium { padding-top: 30px !important; padding-bottom: 30px !important; }
    .padding-horizontal-medium { padding-left: 30px !important; padding-right: 30px !important; }
    .padding-all-thin-medium { padding: 15px !important; }
    .padding-left-thin-medium { padding-left: 15px !important; }
    .padding-right-thin-medium { padding-right: 15px !important; }
    .padding-top-thin-medium { padding-top: 15px !important; }
    .padding-bottom-thin-medium { padding-bottom: 15px !important; }
    .padding-vertical-thin-medium { padding-top: 30px !important; padding-bottom: 30px !important; }
    .padding-horizontal-thin-medium { padding-left: 30px !important; padding-right: 30px !important; }
    .padding-thick-all-medium { padding: 60px !important; }
    .padding-left-thick-medium { padding-left: 60px !important; }
    .padding-right-thick-medium { padding-right: 60px !important; }
    .padding-top-thick-medium { padding-top: 60px !important; }
    .padding-bottom-thick-medium { padding-bottom: 60px !important; }
    .padding-vertical-thick-medium { padding-top: 60px !important; padding-bottom: 60px !important; }
    .padding-horizontal-thick-medium { padding-left: 60px !important; padding-right: 60px !important; }
    .padding-all-none-medium { padding: 0 !important; }
    .padding-left-none-medium { padding-left: 0 !important; }
    .padding-right-none-medium { padding-right: 0 !important; }
    .padding-top-none-medium { padding-top: 0 !important; }
    .padding-bottom-none-medium { padding-bottom: 0 !important; }
    .padding-vertical-none-medium { padding-top: 0 !important; padding-bottom: 0 !important; }
    .padding-horizontal-none-medium { padding-left: 0 !important; padding-right: 0 !important; }

    .margins-all-medium { margin: 1.5em; }
    .margins-vertical-medium { margin-top: 1.5em; margin-bottom: 1.5em; }
    .margins-horizontal-medium { margin-left: 1.5em; margin-right: 1.5em; }
    .margin-left-medium { margin-left: 1.5em; }
    .margin-right-medium { margin-right: 1.5em; }
    .margin-top-medium { margin-top: 1.5em; }
    .margin-bottom-medium { margin-bottom: 1.5em; }
    .margins-all-thin-medium { margin: 0.75em; }
    .margins-vertical-thin-medium { margin-top: 0.75em; margin-bottom: 0.75em; }
    .margins-horizontal-thin-medium { margin-left: 0.75em; margin-right: 0.75em; }
    .margin-left-thin-medium { margin-left: 0.75em; }
    .margin-right-thin-medium { margin-right: 0.75em; }
    .margin-top-thin-medium { margin-top: 0.75em; }
    .margin-bottom-thin-medium { margin-bottom: 0.75em; }
    .margins-all-thick-medium { margin: 3em; }
    .margins-vertical-thick-medium { margin-top: 3em; margin-bottom: 3em; }
    .margins-horizontal-thick-medium { margin-left: 3em; margin-right: 3em; }
    .margin-left-thick-medium { margin-left: 3em; }
    .margin-right-thick-medium { margin-right: 3em; }
    .margin-top-thick-medium { margin-top: 3em; }
    .margin-bottom-thick-medium { margin-bottom: 3em; }
    .margins-all-thicker-medium { margin: 6em; }
    .margins-vertical-thicker-medium { margin-top: 6em; margin-bottom: 6em; }
    .margins-horizontal-thicker-medium { margin-left: 6em; margin-right: 6em; }
    .margin-left-thicker-medium { margin-left: 6em; }
    .margin-right-thicker-medium { margin-right: 6em; }
    .margin-top-thicker-medium { margin-top: 6em; }
    .margin-bottom-thicker-medium { margin-bottom: 6em; }
    .margins-none-medium,.margins-all-none-medium { margin: 0; }
    .margin-left-none-medium { margin-left: 0; }
    .margin-right-none-medium { margin-right: 0; }
    .margin-top-none-medium { margin-top: 0; }
    .margin-bottom-none-medium { margin-bottom: 0; }
    .margins-vertical-none-medium { margin-top: 3em; margin-bottom: 3em; }
    .margins-horizontal-none-medium { margin-left: 3em; margin-right: 3em; }

    .align-items-top-medium { align-items: flex-start; }
    .align-items-center-medium { align-items: center; }
    .align-items-bottom-medium { align-items: flex-end; }
    .align-items-stretch-medium { align-items: stretch; }
    .align-items-baseline-medium { align-items: baseline; }

    .width-full-medium {
        display: inline-block !important; 
        box-sizing: border-box; 
        width: 100% !important; 
        float: none !important; 
        margin-right: 0 !important; 
        margin-left: 0 !important;
    }
    .maxwidth-contain-medium { max-width: 100% !important; }

    .hide-medium { display: none; }

}

@media screen and (min-width: 601px) and (max-width: 900px) {

    .text-left-small-only { text-align: left !important; }
    .text-center-small-only { text-align: center !important; }
    .text-right-small-only { text-align: right !important; }

    .padding-all-small-only { padding: 30px !important; }
    .padding-left-small-only { padding-left: 30px !important; }
    .padding-right-small-only { padding-right: 30px !important; }
    .padding-top-small-only { padding-top: 30px !important; }
    .padding-bottom-small-only { padding-bottom: 30px !important; }
    .padding-vertical-small-only { padding-top: 30px !important; padding-bottom: 30px !important; }
    .padding-horizontal-small-only { padding-left: 30px !important; padding-right: 30px !important; }
    .padding-all-thin-small-only { padding: 15px !important; }
    .padding-left-thin-small-only { padding-left: 15px !important; }
    .padding-right-thin-small-only { padding-right: 15px !important; }
    .padding-top-thin-small-only { padding-top: 15px !important; }
    .padding-bottom-thin-small-only { padding-bottom: 15px !important; }
    .padding-vertical-thin-small-only { padding-top: 15px !important; padding-bottom: 15px !important; }
    .padding-horizontal-thin-small-only { padding-left: 15px !important; padding-right: 15px !important; }
    .padding-thick-all-small-only { padding: 60px !important; }
    .padding-left-thick-small-only { padding-left: 60px !important; }
    .padding-right-thick-small-only { padding-right: 60px !important; }
    .padding-top-thick-small-only { padding-top: 60px !important; }
    .padding-bottom-thick-small-only { padding-bottom: 60px !important; }
    .padding-vertical-thick-small-only { padding-top: 60px !important; padding-bottom: 60px !important; }
    .padding-horizontal-thick-small-only { padding-left: 60px !important; padding-right: 60px !important; }
    .padding-all-none-small-only { padding: 0 !important; }
    .padding-left-none-small-only { padding-left: 0 !important; }
    .padding-right-none-small-only { padding-right: 0 !important; }
    .padding-top-none-small-only { padding-top: 0 !important; }
    .padding-bottom-none-small-only { padding-bottom: 0 !important; }
    .padding-vertical-none-small-only { padding-top: 0 !important; padding-bottom: 0 !important; }
    .padding-horizontal-none-small-only { padding-left: 0 !important; padding-right: 0 !important; }

    .margins-all-small-only { margin: 1.5em; }
    .margins-vertical-small-only { margin-top: 1.5em; margin-bottom: 1.5em; }
    .margins-horizontal-small-only { margin-left: 1.5em; margin-right: 1.5em; }
    .margin-left-small-only { margin-left: 1.5em; }
    .margin-right-small-only { margin-right: 1.5em; }
    .margin-top-small-only { margin-top: 1.5em; }
    .margin-bottom-small-only { margin-bottom: 1.5em; }
    .margins-all-thin-small-only { margin: 0.75em; }
    .margins-vertical-thin-small-only { margin-top: 0.75em; margin-bottom: 0.75em; }
    .margins-horizontal-thin-small-only { margin-left: 0.75em; margin-right: 0.75em; }
    .margin-left-thin-small-only { margin-left: 0.75em; }
    .margin-right-thin-small-only { margin-right: 0.75em; }
    .margin-top-thin-small-only { margin-top: 0.75em; }
    .margin-bottom-thin-small-only { margin-bottom: 0.75em; }
    .margins-all-thick-small-only { margin: 3em; }
    .margins-vertical-thick-small-only { margin-top: 3em; margin-bottom: 3em; }
    .margins-horizontal-thick-small-only { margin-left: 3em; margin-right: 3em; }
    .margin-left-thick-small-only { margin-left: 3em; }
    .margin-right-thick-small-only { margin-right: 3em; }
    .margin-top-thick-small-only { margin-top: 3em; }
    .margin-bottom-thick-small-only { margin-bottom: 3em; }
    .margins-all-thicker-small-only { margin: 6em; }
    .margins-vertical-thicker-small-only { margin-top: 6em; margin-bottom: 6em; }
    .margins-horizontal-thicker-small-only { margin-left: 6em; margin-right: 6em; }
    .margin-left-thicker-small-only { margin-left: 6em; }
    .margin-right-thicker-small-only { margin-right: 6em; }
    .margin-top-thicker-small-only { margin-top: 6em; }
    .margin-bottom-thicker-small-only { margin-bottom: 6em; }
    .margins-none-small-only,.margins-all-none-small-only { margin: 0; }
    .margin-left-none-small-only { margin-left: 0; }
    .margin-right-none-small-only { margin-right: 0; }
    .margin-top-none-small-only { margin-top: 0; }
    .margin-bottom-none-small-only { margin-bottom: 0; }
    .margins-vertical-none-small-only { margin-top: 3em; margin-bottom: 3em; }
    .margins-horizontal-none-small-only { margin-left: 3em; margin-right: 3em; }

    .align-items-top-small-only { align-items: flex-start; }
    .align-items-center-small-only { align-items: center; }
    .align-items-bottom-small-only { align-items: flex-end; }
    .align-items-stretch-small-only { align-items: stretch; }
    .align-items-baseline-small-only { align-items: baseline; }

    .width-full-small-only {
        display: inline-block !important; 
        box-sizing: border-box; 
        width: 100% !important; 
        float: none !important; 
        margin-right: 0 !important; 
        margin-left: 0 !important;
    }
    .maxwidth-contain-small-only { max-width: 100% !important; }

    .hide-small-only { display: none; }

}

@media screen and (max-width: 900px) {

    .text-left-small { text-align: left !important; }
    .text-center-small { text-align: center !important; }
    .text-right-small { text-align: right !important; }

    .padding-all-small { padding: 30px !important; }
    .padding-left-small { padding-left: 30px !important; }
    .padding-right-small { padding-right: 30px !important; }
    .padding-top-small { padding-top: 30px !important; }
    .padding-bottom-small { padding-bottom: 30px !important; }
    .padding-vertical-small { padding-top: 30px !important; padding-bottom: 30px !important; }
    .padding-horizontal-small { padding-left: 30px !important; padding-right: 30px !important; }
    .padding-all-thin-small { padding: 15px !important; }
    .padding-left-thin-small { padding-left: 15px !important; }
    .padding-right-thin-small { padding-right: 15px !important; }
    .padding-top-thin-small { padding-top: 15px !important; }
    .padding-bottom-thin-small { padding-bottom: 15px !important; }
    .padding-vertical-thin-small { padding-top: 30px !important; padding-bottom: 30px !important; }
    .padding-horizontal-thin-small { padding-left: 30px !important; padding-right: 30px !important; }
    .padding-thick-all-small { padding: 60px !important; }
    .padding-left-thick-small { padding-left: 60px !important; }
    .padding-right-thick-small { padding-right: 60px !important; }
    .padding-top-thick-small { padding-top: 60px !important; }
    .padding-bottom-thick-small { padding-bottom: 60px !important; }
    .padding-vertical-thick-small { padding-top: 60px !important; padding-bottom: 60px !important; }
    .padding-horizontal-thick-small { padding-left: 60px !important; padding-right: 60px !important; }
    .padding-all-none-small { padding: 0 !important; }
    .padding-left-none-small { padding-left: 0 !important; }
    .padding-right-none-small { padding-right: 0 !important; }
    .padding-top-none-small { padding-top: 0 !important; }
    .padding-bottom-none-small { padding-bottom: 0 !important; }
    .padding-vertical-none-small { padding-top: 0 !important; padding-bottom: 0 !important; }
    .padding-horizontal-none-small { padding-left: 0 !important; padding-right: 0 !important; }

    .margins-all-small { margin: 1.5em; }
    .margins-vertical-small { margin-top: 1.5em; margin-bottom: 1.5em; }
    .margins-horizontal-small { margin-left: 1.5em; margin-right: 1.5em; }
    .margin-left-small { margin-left: 1.5em; }
    .margin-right-small { margin-right: 1.5em; }
    .margin-top-small { margin-top: 1.5em; }
    .margin-bottom-small { margin-bottom: 1.5em; }
    .margins-all-thin-small { margin: 0.75em; }
    .margins-vertical-thin-small { margin-top: 0.75em; margin-bottom: 0.75em; }
    .margins-horizontal-thin-small { margin-left: 0.75em; margin-right: 0.75em; }
    .margin-left-thin-small { margin-left: 0.75em; }
    .margin-right-thin-small { margin-right: 0.75em; }
    .margin-top-thin-small { margin-top: 0.75em; }
    .margin-bottom-thin-small { margin-bottom: 0.75em; }
    .margins-all-thick-small { margin: 3em; }
    .margins-vertical-thick-small { margin-top: 3em; margin-bottom: 3em; }
    .margins-horizontal-thick-small { margin-left: 3em; margin-right: 3em; }
    .margin-left-thick-small { margin-left: 3em; }
    .margin-right-thick-small { margin-right: 3em; }
    .margin-top-thick-small { margin-top: 3em; }
    .margin-bottom-thick-small { margin-bottom: 3em; }
    .margins-all-thicker-small { margin: 6em; }
    .margins-vertical-thicker-small { margin-top: 6em; margin-bottom: 6em; }
    .margins-horizontal-thicker-small { margin-left: 6em; margin-right: 6em; }
    .margin-left-thicker-small { margin-left: 6em; }
    .margin-right-thicker-small { margin-right: 6em; }
    .margin-top-thicker-small { margin-top: 6em; }
    .margin-bottom-thicker-small { margin-bottom: 6em; }
    .margins-none-small,.margins-all-none-small { margin: 0; }
    .margin-left-none-small { margin-left: 0; }
    .margin-right-none-small { margin-right: 0; }
    .margin-top-none-small { margin-top: 0; }
    .margin-bottom-none-small { margin-bottom: 0; }
    .margins-vertical-none-small { margin-top: 3em; margin-bottom: 3em; }
    .margins-horizontal-none-small { margin-left: 3em; margin-right: 3em; }

    .align-items-top-small { align-items: flex-start; }
    .align-items-center-small { align-items: center; }
    .align-items-bottom-small { align-items: flex-end; }
    .align-items-stretch-small { align-items: stretch; }
    .align-items-baseline-small { align-items: baseline; }

    .width-full-small {
        display: inline-block !important; 
        box-sizing: border-box; 
        width: 100% !important; 
        float: none !important; 
        margin-right: 0 !important; 
        margin-left: 0 !important;
    }
    .maxwidth-contain-small { max-width: 100% !important; }

    .hide-small { display: none; }

}

@media screen and (min-width: 376px) and (max-width: 600px) {

    .text-left-tiny-only { text-align: left !important; }
    .text-center-tiny-only { text-align: center !important; }
    .text-right-tiny-only { text-align: right !important; }

    .padding-all-tiny-only { padding: 30px !important; }
    .padding-left-tiny-only { padding-left: 30px !important; }
    .padding-right-tiny-only { padding-right: 30px !important; }
    .padding-top-tiny-only { padding-top: 30px !important; }
    .padding-bottom-tiny-only { padding-bottom: 30px !important; }
    .padding-vertical-tiny-only { padding-top: 30px !important; padding-bottom: 30px !important; }
    .padding-horizontal-tiny-only { padding-left: 30px !important; padding-right: 30px !important; }
    .padding-all-thin-tiny-only { padding: 15px !important; }
    .padding-left-thin-tiny-only { padding-left: 15px !important; }
    .padding-right-thin-tiny-only { padding-right: 15px !important; }
    .padding-top-thin-tiny-only { padding-top: 15px !important; }
    .padding-bottom-thin-tiny-only { padding-bottom: 15px !important; }
    .padding-vertical-thin-tiny-only { padding-top: 15px !important; padding-bottom: 15px !important; }
    .padding-horizontal-thin-tiny-only { padding-left: 15px !important; padding-right: 15px !important; }
    .padding-thick-all-tiny-only { padding: 60px !important; }
    .padding-left-thick-tiny-only { padding-left: 60px !important; }
    .padding-right-thick-tiny-only { padding-right: 60px !important; }
    .padding-top-thick-tiny-only { padding-top: 60px !important; }
    .padding-bottom-thick-tiny-only { padding-bottom: 60px !important; }
    .padding-vertical-thick-tiny-only { padding-top: 60px !important; padding-bottom: 60px !important; }
    .padding-horizontal-thick-tiny-only { padding-left: 60px !important; padding-right: 60px !important; }
    .padding-all-none-tiny-only { padding: 0 !important; }
    .padding-left-none-tiny-only { padding-left: 0 !important; }
    .padding-right-none-tiny-only { padding-right: 0 !important; }
    .padding-top-none-tiny-only { padding-top: 0 !important; }
    .padding-bottom-none-tiny-only { padding-bottom: 0 !important; }
    .padding-vertical-none-tiny-only { padding-top: 0 !important; padding-bottom: 0 !important; }
    .padding-horizontal-none-tiny-only { padding-left: 0 !important; padding-right: 0 !important; }

    .margins-all-tiny-only { margin: 1.5em; }
    .margins-vertical-tiny-only { margin-top: 1.5em; margin-bottom: 1.5em; }
    .margins-horizontal-tiny-only { margin-left: 1.5em; margin-right: 1.5em; }
    .margin-left-tiny-only { margin-left: 1.5em; }
    .margin-right-tiny-only { margin-right: 1.5em; }
    .margin-top-tiny-only { margin-top: 1.5em; }
    .margin-bottom-tiny-only { margin-bottom: 1.5em; }
    .margins-all-thin-tiny-only { margin: 0.75em; }
    .margins-vertical-thin-tiny-only { margin-top: 0.75em; margin-bottom: 0.75em; }
    .margins-horizontal-thin-tiny-only { margin-left: 0.75em; margin-right: 0.75em; }
    .margin-left-thin-tiny-only { margin-left: 0.75em; }
    .margin-right-thin-tiny-only { margin-right: 0.75em; }
    .margin-top-thin-tiny-only { margin-top: 0.75em; }
    .margin-bottom-thin-tiny-only { margin-bottom: 0.75em; }
    .margins-all-thick-tiny-only { margin: 3em; }
    .margins-vertical-thick-tiny-only { margin-top: 3em; margin-bottom: 3em; }
    .margins-horizontal-thick-tiny-only { margin-left: 3em; margin-right: 3em; }
    .margin-left-thick-tiny-only { margin-left: 3em; }
    .margin-right-thick-tiny-only { margin-right: 3em; }
    .margin-top-thick-tiny-only { margin-top: 3em; }
    .margin-bottom-thick-tiny-only { margin-bottom: 3em; }
    .margins-all-thicker-tiny-only { margin: 6em; }
    .margins-vertical-thicker-tiny-only { margin-top: 6em; margin-bottom: 6em; }
    .margins-horizontal-thicker-tiny-only { margin-left: 6em; margin-right: 6em; }
    .margin-left-thicker-tiny-only { margin-left: 6em; }
    .margin-right-thicker-tiny-only { margin-right: 6em; }
    .margin-top-thicker-tiny-only { margin-top: 6em; }
    .margin-bottom-thicker-tiny-only { margin-bottom: 6em; }
    .margins-none-tiny-only,.margins-all-none-tiny-only { margin: 0; }
    .margin-left-none-tiny-only { margin-left: 0; }
    .margin-right-none-tiny-only { margin-right: 0; }
    .margin-top-none-tiny-only { margin-top: 0; }
    .margin-bottom-none-tiny-only { margin-bottom: 0; }
    .margins-vertical-none-tiny-only { margin-top: 3em; margin-bottom: 3em; }
    .margins-horizontal-none-tiny-only { margin-left: 3em; margin-right: 3em; }

    .align-items-top-tiny-only { align-items: flex-start; }
    .align-items-center-tiny-only { align-items: center; }
    .align-items-bottom-tiny-only { align-items: flex-end; }
    .align-items-stretch-tiny-only { align-items: stretch; }
    .align-items-baseline-tiny-only { align-items: baseline; }

    .width-full-tiny-only {
        display: inline-block !important; 
        box-sizing: border-box; 
        width: 100% !important; 
        float: none !important; 
        margin-right: 0 !important; 
        margin-left: 0 !important;
    }
    .maxwidth-contain-tiny-only { max-width: 100% !important; }

    .hide-tiny-only { display: none; }
}

@media screen and (max-width: 600px) {

    .text-left-tiny { text-align: left !important; }
    .text-center-tiny { text-align: center !important; }
    .text-right-tiny { text-align: right !important; }

    .padding-all-tiny { padding: 30px !important; }
    .padding-left-tiny { padding-left: 30px !important; }
    .padding-right-tiny { padding-right: 30px !important; }
    .padding-top-tiny { padding-top: 30px !important; }
    .padding-bottom-tiny { padding-bottom: 30px !important; }
    .padding-vertical-tiny { padding-top: 30px !important; padding-bottom: 30px !important; }
    .padding-horizontal-tiny { padding-left: 30px !important; padding-right: 30px !important; }
    .padding-all-thin-tiny { padding: 15px !important; }
    .padding-left-thin-tiny { padding-left: 15px !important; }
    .padding-right-thin-tiny { padding-right: 15px !important; }
    .padding-top-thin-tiny { padding-top: 15px !important; }
    .padding-bottom-thin-tiny { padding-bottom: 15px !important; }
    .padding-vertical-thin-tiny { padding-top: 30px !important; padding-bottom: 30px !important; }
    .padding-horizontal-thin-tiny { padding-left: 30px !important; padding-right: 30px !important; }
    .padding-thick-all-tiny { padding: 60px !important; }
    .padding-left-thick-tiny { padding-left: 60px !important; }
    .padding-right-thick-tiny { padding-right: 60px !important; }
    .padding-top-thick-tiny { padding-top: 60px !important; }
    .padding-bottom-thick-tiny { padding-bottom: 60px !important; }
    .padding-vertical-thick-tiny { padding-top: 60px !important; padding-bottom: 60px !important; }
    .padding-horizontal-thick-tiny { padding-left: 60px !important; padding-right: 60px !important; }
    .padding-all-none-tiny { padding: 0 !important; }
    .padding-left-none-tiny { padding-left: 0 !important; }
    .padding-right-none-tiny { padding-right: 0 !important; }
    .padding-top-none-tiny { padding-top: 0 !important; }
    .padding-bottom-none-tiny { padding-bottom: 0 !important; }
    .padding-vertical-none-tiny { padding-top: 0 !important; padding-bottom: 0 !important; }
    .padding-horizontal-none-tiny { padding-left: 0 !important; padding-right: 0 !important; }

    .margins-all-tiny { margin: 1.5em; }
    .margins-vertical-tiny { margin-top: 1.5em; margin-bottom: 1.5em; }
    .margins-horizontal-tiny { margin-left: 1.5em; margin-right: 1.5em; }
    .margin-left-tiny { margin-left: 1.5em; }
    .margin-right-tiny { margin-right: 1.5em; }
    .margin-top-tiny { margin-top: 1.5em; }
    .margin-bottom-tiny { margin-bottom: 1.5em; }
    .margins-all-thin-tiny { margin: 0.75em; }
    .margins-vertical-thin-tiny { margin-top: 0.75em; margin-bottom: 0.75em; }
    .margins-horizontal-thin-tiny { margin-left: 0.75em; margin-right: 0.75em; }
    .margin-left-thin-tiny { margin-left: 0.75em; }
    .margin-right-thin-tiny { margin-right: 0.75em; }
    .margin-top-thin-tiny { margin-top: 0.75em; }
    .margin-bottom-thin-tiny { margin-bottom: 0.75em; }
    .margins-all-thick-tiny { margin: 3em; }
    .margins-vertical-thick-tiny { margin-top: 3em; margin-bottom: 3em; }
    .margins-horizontal-thick-tiny { margin-left: 3em; margin-right: 3em; }
    .margin-left-thick-tiny { margin-left: 3em; }
    .margin-right-thick-tiny { margin-right: 3em; }
    .margin-top-thick-tiny { margin-top: 3em; }
    .margin-bottom-thick-tiny { margin-bottom: 3em; }
    .margins-all-thicker-tiny { margin: 6em; }
    .margins-vertical-thicker-tiny { margin-top: 6em; margin-bottom: 6em; }
    .margins-horizontal-thicker-tiny { margin-left: 6em; margin-right: 6em; }
    .margin-left-thicker-tiny { margin-left: 6em; }
    .margin-right-thicker-tiny { margin-right: 6em; }
    .margin-top-thicker-tiny { margin-top: 6em; }
    .margin-bottom-thicker-tiny { margin-bottom: 6em; }
    .margins-none-tiny,.margins-all-none-tiny { margin: 0; }
    .margin-left-none-tiny { margin-left: 0; }
    .margin-right-none-tiny { margin-right: 0; }
    .margin-top-none-tiny { margin-top: 0; }
    .margin-bottom-none-tiny { margin-bottom: 0; }
    .margins-vertical-none-tiny { margin-top: 3em; margin-bottom: 3em; }
    .margins-horizontal-none-tiny { margin-left: 3em; margin-right: 3em; }

    .align-items-top-tiny { align-items: flex-start; }
    .align-items-center-tiny { align-items: center; }
    .align-items-bottom-tiny { align-items: flex-end; }
    .align-items-stretch-tiny { align-items: stretch; }
    .align-items-baseline-tiny { align-items: baseline; }

    .width-full-tiny {
        display: inline-block !important; 
        box-sizing: border-box; 
        width: 100% !important; 
        float: none !important; 
        margin-right: 0 !important; 
        margin-left: 0 !important;
    }
    .maxwidth-contain-tiny { max-width: 100% !important; }

    .hide-tiny { display: none; }

}

@media screen and (max-width: 375px) {

    .text-left-micro { text-align: left !important; }
    .text-center-micro { text-align: center !important; }
    .text-right-micro { text-align: right !important; }

    .padding-all-micro { padding: 30px !important; }
    .padding-left-micro { padding-left: 30px !important; }
    .padding-right-micro { padding-right: 30px !important; }
    .padding-top-micro { padding-top: 30px !important; }
    .padding-bottom-micro { padding-bottom: 30px !important; }
    .padding-vertical-micro { padding-top: 30px !important; padding-bottom: 30px !important; }
    .padding-horizontal-micro { padding-left: 30px !important; padding-right: 30px !important; }
    .padding-all-thin-micro { padding: 15px !important; }
    .padding-left-thin-micro { padding-left: 15px !important; }
    .padding-right-thin-micro { padding-right: 15px !important; }
    .padding-top-thin-micro { padding-top: 15px !important; }
    .padding-bottom-thin-micro { padding-bottom: 15px !important; }
    .padding-vertical-thin-micro { padding-top: 15px !important; padding-bottom: 15px !important; }
    .padding-horizontal-thin-micro { padding-left: 15px !important; padding-right: 15px !important; }
    .padding-thick-all-micro { padding: 60px !important; }
    .padding-left-thick-micro { padding-left: 60px !important; }
    .padding-right-thick-micro { padding-right: 60px !important; }
    .padding-top-thick-micro { padding-top: 60px !important; }
    .padding-bottom-thick-micro { padding-bottom: 60px !important; }
    .padding-vertical-thick-micro { padding-top: 60px !important; padding-bottom: 60px !important; }
    .padding-horizontal-thick-micro { padding-left: 60px !important; padding-right: 60px !important; }
    .padding-all-none-micro { padding: 0 !important; }
    .padding-left-none-micro { padding-left: 0 !important; }
    .padding-right-none-micro { padding-right: 0 !important; }
    .padding-top-none-micro { padding-top: 0 !important; }
    .padding-bottom-none-micro { padding-bottom: 0 !important; }
    .padding-vertical-none-micro { padding-top: 0 !important; padding-bottom: 0 !important; }
    .padding-horizontal-none-micro { padding-left: 0 !important; padding-right: 0 !important; }

    .margins-all-micro { margin: 1.5em; }
    .margins-vertical-micro { margin-top: 1.5em; margin-bottom: 1.5em; }
    .margins-horizontal-micro { margin-left: 1.5em; margin-right: 1.5em; }
    .margin-left-micro { margin-left: 1.5em; }
    .margin-right-micro { margin-right: 1.5em; }
    .margin-top-micro { margin-top: 1.5em; }
    .margin-bottom-micro { margin-bottom: 1.5em; }
    .margins-all-thin-micro { margin: 0.75em; }
    .margins-vertical-thin-micro { margin-top: 0.75em; margin-bottom: 0.75em; }
    .margins-horizontal-thin-micro { margin-left: 0.75em; margin-right: 0.75em; }
    .margin-left-thin-micro { margin-left: 0.75em; }
    .margin-right-thin-micro { margin-right: 0.75em; }
    .margin-top-thin-micro { margin-top: 0.75em; }
    .margin-bottom-thin-micro { margin-bottom: 0.75em; }
    .margins-all-thick-micro { margin: 3em; }
    .margins-vertical-thick-micro { margin-top: 3em; margin-bottom: 3em; }
    .margins-horizontal-thick-micro { margin-left: 3em; margin-right: 3em; }
    .margin-left-thick-micro { margin-left: 3em; }
    .margin-right-thick-micro { margin-right: 3em; }
    .margin-top-thick-micro { margin-top: 3em; }
    .margin-bottom-thick-micro { margin-bottom: 3em; }
    .margins-all-thicker-micro { margin: 6em; }
    .margins-vertical-thicker-micro { margin-top: 6em; margin-bottom: 6em; }
    .margins-horizontal-thicker-micro { margin-left: 6em; margin-right: 6em; }
    .margin-left-thicker-micro { margin-left: 6em; }
    .margin-right-thicker-micro { margin-right: 6em; }
    .margin-top-thicker-micro { margin-top: 6em; }
    .margin-bottom-thicker-micro { margin-bottom: 6em; }
    .margins-none-micro,.margins-all-none-micro { margin: 0; }
    .margin-left-none-micro { margin-left: 0; }
    .margin-right-none-micro { margin-right: 0; }
    .margin-top-none-micro { margin-top: 0; }
    .margin-bottom-none-micro { margin-bottom: 0; }
    .margins-vertical-none-micro { margin-top: 3em; margin-bottom: 3em; }
    .margins-horizontal-none-micro { margin-left: 3em; margin-right: 3em; }

    .align-items-top-micro { align-items: flex-start; }
    .align-items-center-micro { align-items: center; }
    .align-items-bottom-micro { align-items: flex-end; }
    .align-items-stretch-micro { align-items: stretch; }
    .align-items-baseline-micro { align-items: baseline; }

    .width-full-micro {
        display: inline-block !important; 
        box-sizing: border-box; 
        width: 100% !important; 
        float: none !important; 
        margin-right: 0 !important; 
        margin-left: 0 !important;
    }
    .maxwidth-contain-micro { max-width: 100% !important; }

    .hide-micro { display: none; }

}

/*
    Helpers
*/

.hidden {
    display: none;
}

.hiddenFromSight {
    display: block !important;
    position: relative !important;
    height: 0 !important;
    margin: 0 !important;
    text-indent: -10000em !important;
}

.text-wrap {
    overflow-wrap: break-word; /* v- These are technically the same, but use both */
    word-wrap: break-word; /* ^- These are technically the same, but use both */
    -ms-word-break: break-all;
    word-break: break-all; /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-word; /* Instead use this non-standard one: */
}

    .text-wrap.hyphens {
        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }

/*
    Blockquotes
*/

blockquote {
    position: relative;
    margin: 0 0 1.5em 0;
    padding: 0;
    color: #909090;
    font-family: georgia,'times new roman',times,serif;
    font-size: 125%;
    line-height: 125%;
    quotes: "\201C" "\201D";
}

    blockquote::before {
        content: open-quote;
        font-weight: bold;
    }

    blockquote::after {
        content: close-quote;
        font-weight: bold;
    }

    blockquote p {
        display: inline;
        line-height: 1.3em;
    }

    blockquote :first-child {
        margin-top: 0;
    }

    blockquote :last-child {
        margin-bottom: 0;
    }

    blockquote.padding-all-none,
    blockquote.padding-all-none-large,
    blockquote.padding-all-none-medium,
    blockquote.padding-all-none-small,
    blockquote.padding-left-none,
    blockquote.padding-left-none-large,
    blockquote.padding-left-none-medium,
    blockquote.padding-left-none-small {
        /* Make room for the open quote even when no padding is specified, otherwise it disappears off the left of the screen in small screen views. */
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    blockquote[style*="float"] {
        padding: 1.5em;
        max-width: 50%;
    }

    blockquote[style*="float:left"],
    blockquote[style*="float: left"],
    blockquote.align-left {
        margin-top: 1.5em;
        margin-right: 20px;
        border-right: #e0dcdb 2px solid;
    }

    blockquote[style*="float:right"],
    blockquote[style*="float: right"],
    blockquote.align-right {
        margin-top: 1.5em;
        margin-left: 20px;
        border-left: #e0dcdb 2px solid;
    }

@media screen and (max-width: 600px) { /* Could consider going to 375px - It depends on the site content and design. */

    blockquote[style*="float:left"],
    blockquote[style*="float: left"],
    blockquote[style*="float:right"],
    blockquote[style*="float: right"],
    blockquote.align-left,
    blockquote.align-right {
        float: none !important;
        max-width: none !important;
        margin-top: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding: 0 20px !important;
        border: 0 !important;
    }
}

/*
    Address blocks
*/

address {
}

/*
    Pre-formatted blocks
*/

pre {
}

/*
    Experimental: Reset styles at different screen sizes
*/

@media screen and (max-width: 1200px) {

    .reset-medium {
        display: unset;
        margin: unset;
        padding: 0;
        font-size: inherit;
        font: inherit;
        color: inherit;
        line-height: inherit;
        vertical-align: baseline;
        background: inherit;
        border: inherit;
    }

}

@media screen and (max-width: 900px) {

    .reset-small {
        display: unset;
        margin: unset;
        padding: 0;
        font-size: inherit;
        font: inherit;
        color: inherit;
        line-height: inherit;
        vertical-align: baseline;
        background: inherit;
        border: inherit;
    }

}

@media screen and (max-width: 600px) {

    .reset-tiny {
        display: unset;
        margin: unset;
        padding: 0;
        font-size: inherit;
        font: inherit;
        color: inherit;
        line-height: inherit;
        vertical-align: baseline;
        background: inherit;
        border: inherit;
    }

}

@media screen and (max-width: 375px) {

    .reset-nano {
        display: unset;
        margin: unset;
        padding: 0;
        font-size: inherit;
        font: inherit;
        color: inherit;
        line-height: inherit;
        vertical-align: baseline;
        background: inherit;
        border: inherit;
    }

}