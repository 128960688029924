// ##############################
//
// Buttons
//
// ##############################

/* Ionic Buttons */

ion-button {
    text-transform: uppercase;
}

ion-button::part(native) {
    text-transform: capitalize;
    border-radius: 0;
    font-size: 16px;
}

ion-button.button-clear::part(native) {
    background-color: transparent;
    color: var(--cap-primary-color);
}

ion-button.rounded::part(native) {
    border-radius: 50px;
}

ion-button.menuButton::part(native) {
    font-size: 16px;
    font-weight: 400;
}

ion-button.logOutButton::part(native) {
    background: transparent;
    border: 2px solid #fff;
}

button {
    background: none;
}

button[disabled] {
    opacity: 0.5;
}

/* Acora Theme Buttons */

.button {
    -webkit-appearance: none; /* Stop IOS taking over when it's a form control. */
    position: relative;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    padding: 15px 30px;
    margin: 2px; /* When buttons wrap around lines or sit right next to each other we want some space between them. */
    max-width: 100%;
    color: #ffffff !important;
    line-height: 1;
    font-size: 100%;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none !important;
    text-align: center;
    white-space: normal;
    background-color: #358366;/* #0ba374; #358366; */
    background-repeat: no-repeat;
    border: 0;
    border-radius: 3px;
    cursor: pointer; /* Make sure all buttons show the same pointer, regardless of what HTML tag the button uses. */
    transition: 100ms all ease-in-out;
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
}

    .button:hover {
        text-decoration: underline !important;
        background-color: #181008;
    }

    .button:active {
        /*margin: 3px 0 1px 1px;*/ /* Nudge it to make it look like it's being pressed. */
    }

    fieldset.submitButtons input.button {
        margin: 0; /* Buttons in forms don't end up wrapping in standard scenarios, so the margin just messes up their layout a bit. */
    }

        fieldset.submitButtons input.button:active {
            margin: 1px 0 -1px 1px; /* Nudge. */
        }

    button.button,
    input.button {
        /* Can't use :before or :after on elements that are replaceable by the operating system, e.g. buttons and form controls. */
    }

.headerTools .button {
    padding: 5px 7px;
    background-color: transparent;
    font-size: 230% !important;
}

@media screen and (max-width: 600px) {

    /*.button {
        box-sizing: border-box;
        width: 100%;
        text-align: center;
        font-size: 100%;
    }*/

}

/* 
    Sizes
    - Some buttons are different sizes. If background images are used, these classes can be useful.
*/

    .button-short {
    }

    .button-long {
    }

    .button-huge {
    }

    .button-large {
        padding: 18px 45px;
        font-size: 175%;
        font-weight: normal;
    }

        div.cols-2 > .col .button-large {
            width: 40%;
        }

        div.cols-3 > .col .button-large {
            width: 45%;
        }
        
        div.cols-4 > .col .button-large {
            width: 50%;
        }

    .button-small {
        padding: 8px 15px;
        font-size: 75%;
    }

    .button-tiny {
    }

/*
@media screen and (max-width: 600px) {

    .button-large {
        padding: 15px 30px;
        font-size: 125%;
    }

    div.cols-2 > .col .button-large,
    div.cols-3 > .col .button-large,
    div.cols-4 > .col .button-large {
        box-sizing: border-box;
        width: 100%;
    }

}
*/

/* 
    Shapes 
*/

.button-rounded {
    border-radius: 30px;
}

/* 
Functions 
*/

.button-next::after {
    content: "\f105";
    display: inline-block;
    margin-left: 15px;
    font-family: "Font Awesome 5 Pro";
    font-weight: 700;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.button-back::before {
    content: "\f104";
    display: inline-block;
    margin-right: 15px;
    font-family: "Font Awesome 5 Pro";
    font-weight: 700;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

/*
    Alternate Button Styles
*/

.button-secondary {
    padding: 13px 30px;
    color: #358366 !important;
    background-color: #fff;
    border: #358366 2px solid;
}

    .button-secondary:hover {
        color: #fff !important;
        background-color: #358366;
    }

    .button-secondary.button-next:after {
        border-color: transparent #358366;
    }

        .button-next.button-next:hover:after {
            border-color: transparent #fff;
        }

    .button-secondary.button-back:before {
        border-color: transparent #358366;
    }

        .button-secondary.button-back:hover:before {
            border-color: transparent #fff;
        }

.button-bright {
    font-weight: bold;
    color: #0088b2 !important;
    background: rgb(248, 248, 251);
    background: linear-gradient(0deg, rgb(240, 240, 243) 0%, rgb(248, 248, 251) 10%, rgb(248, 248, 251) 90%, rgb(255, 255, 255) 100%);
    border-radius: 3px;
    box-shadow: 0 0 2px rgba(0,0,0,0.25);
    &:hover {
        text-decoration: none !important;
        color: #fff !important;
        background: #358366;
        /*background: linear-gradient(0deg, rgba(0,144,97,1) 0%, rgba(11,163,116,1) 10%, rgba(11,163,116,1) 90%, rgba(43,195,148,1) 100%);*/
        /*background: linear-gradient(0deg, rgba(31,111,82,1) 0%, rgba(53,131,102,1) 7.5%, rgba(53,131,102,1) 92.5%, rgba(123,201,172,1) 100%);*/
        transition: 100ms all ease-in-out;
        * {
            color: #fff !important;
        }
    }
}