/* ############################################################################
   ## 
   ##  TABLE STYLES
   ##
   ##  - Critical Rendering Path: Recommended
   ##
   ######################################################################### */

/*
    Standard Table Functionality
    
    - Don't create detailed visual table styles here, just set up some useful default behaviour. 
    - CMS authors should be free to use the CMS to style standard tables (so don't create default styles that won't be overridden by the visual editor) and developers should be free to create custom style classes.
*/

table {
    width: 100%; /* Easily overridden locally on each table, but this makes sure tables are easier to edit in the CMS visual editor. */
    empty-cells: show;
    margin-bottom: 1.5em;
    border-collapse: collapse;
    border-style: hidden; /* "hidden" is a soft version of "none" that will be overridden by any styling conflict. */
}

    table caption {
        /* Default table captions should have minimal styling because it's valid to have normal HTML inside. */
        padding: 15px 0;
        margin: 15px 0;
        text-align: left;
        line-height: 135%;
        /*border-top: #d0cccb 1px solid;*/ /* Not sure about this... */
    }

        table caption > :first-child {
            margin-top: 0 !important;
        }

        table caption > :last-child {
            margin-bottom: 0 !important;
        }

    thead {
    }

        thead th {
            vertical-align: bottom;
        }

    tbody {
    }

    tfoot {
    }

    th {
        text-align: left;
        font-weight: bold;
    }

        th > :first-child {
            margin-top: 0 !important; /* !important is very opinionated, but it seems important that the cell padding takes precedence over what's in it. Local styles could override this. */
        }

        th > :last-child {
            margin-bottom: 0 !important;
        }

    td {
    }

        td > :first-child {
            margin-top: 0 !important;
        }

        td > :last-child {
            margin-bottom: 0 !important;
        }

        tr:nth-child(2n) td {
        }

    tr {
        /* Define rows after cells in case we want extra specificity for cells in special types of rows. */
    }

/* 
    Specialised Table Styles

    - Whatever you do with table styling, think about how it'll work via the responsive table layout system too. 
    - The standard table layouts consider editing in the CMS visual editor. Think about this when modifying CSS or creating new styles.
    - In 9.6.3 the default table classes available in the CMS visual editor class dropdown are .dataTable1, .dataTable2, .dataTable3, .dataTable4, .simple and .plain
    - Helper classes are also included, which may or may not be relevant depending on your core table design:
        - .responsive - Tables are "stacked" into nice looking list-like layouts on small screens.
        - .scroll-all|{screensize} - Tables get a horizontal scrollbar at the specified break point, only if the content is wider than the screen.
        - .alternating-rows and .alternating-columns
        - .alternate-1|2|3|4 - Different row colours/styles
        - .hide-vertical-borders and .show-vertical-boders - Can be used on entire tables or individual rows
    - The .plain and .simple classes are defined first in classes are used on the same table, which can happen depending on how the user selects options in the CMS visual editor.
    - .dataTable1 is an example of quite advanced table layout - See the standard CSS for detailed technical and design notes, which haven't been included with the other .dataTable layouts (to reduce noise). Replicate the code layout for new table designs if you want to update code more easily based on core system design principles in the future.
    - The .dataTable1 section also includes various helper classes that target all tables - e.g. highlight and alternate rows/cells and a hover effect. The location of this code might change in the fuure, but the concept won't.
    - Remember that tables can appear on both light and dark backgrounds. You can target rules to specific backgrounds of course, but that'll cause headaches. Think about things like colours and borders and try to create rules that work everywhere without special context targeting.
*/

/*
    Tables - General Rules
    - In the default stylesheet some general rules for tables and rows and cells are marked up to apply to all tables by default. 
    - Since .dataTable1 is the default table style selected when a table is added to the visual editor, the default styles suit .dataTable1 and should in turn suit the overall standard design theme of the site.
    - You might see !important rules below. They're only used on elements that should be very opinionated, e.g. highlighted cells. You can always override them on individual table layouts. 
*/

    /* 
        Highlight and Alternate Rows/Cells
    */

        table tbody tr.highlight-1 {
        }

            table tbody tr.highlight-1 th {
                /*color: #165A26 !important;*/
                background-color: #E0F1E4 !important;
            }

            table tbody tr.highlight-1 td {
                color: #165A26 !important;
                background-color: #E0F1E4 !important;
            }

        table tbody tr.highlight-2 {
        }

            table tbody tr.highlight-2 th {
                /*color: #721c24 !important;*/
                background-color: #F9D7D8 !important;
            }

            table tbody tr.highlight-2 td {
                color: #721c24 !important;
                background-color: #F9D7D8 !important;
            }

        table tbody tr.highlight-3 {
        }

            table tbody tr.highlight-3 th {
                /*color: #0c5460 !important;*/
                background-color: #D4EFF2 !important;
            }

            table tbody tr.highlight-3 td {
                color: #0c5460 !important;
                background-color: #D4EFF2 !important;
            }

        table tbody tr.highlight-4 {
        }

            table tbody tr.highlight-4 th {
                /*color: #684018 !important;*/
                background-color: #FFF7E0 !important;
            }

            table tbody tr.highlight-4 td {
                color: #684018 !important;
                background-color: #FFF7E0 !important;
            }

        table tbody tr.alternate-1 {
            /*
                Note: Design alternate rows/cells the way you want, but one good way is to use a basic colour gradient. In this case,
                it's a good idea to move from light to dark colours in light tables, and the opposite - dark to light - in dark tables.
            */
        }

            table tbody tr.alternate-1 th,
            table tbody tr.alternate-1 td,
            table tbody tr td.alternate-1 {
                color: #000;
                background-color: #f2f9fb;
            }

        table tbody tr.alternate-2 {
        }

            table tbody tr.alternate-2 th,
            table tbody tr.alternate-2 td,
            table tbody tr td.alternate-2 {
                color: #000;
                background-color: #e5f3f7;
            }

        table tbody tr.alternate-3 {
        }

            table tbody tr.alternate-3 th,
            table tbody tr.alternate-3 td,
            table tbody tr td.alternate-3 {
                color: #fff;
                background-color: #0088b2;
            }

        table tbody tr.alternate-4 {
        }

            table tbody tr.alternate-4 th,
            table tbody tr.alternate-4 td,
            table tbody tr td.alternate-4 {
                color: #fff;
                background-color: #005e7a;
            }

        table tbody tr.highlight-text-1 {
        }

            table tbody tr.highlight-text-1 td,
            table tbody tr th.highlight-text-1,
            table tbody tr td.highlight-text-1 {
                color: #1F7F35 !important;
            }

        table tbody tr.highlight-text-2 {
        }

            table tbody tr.highlight-text-2 td,
            table tbody tr th.highlight-text-2,
            table tbody tr td.highlight-text-2 {
                color: #CF3A49 !important;
            }

        table tbody tr.highlight-text-3 {
        }

            table tbody tr.highlight-text-3 td,
            table tbody tr th.highlight-text-3,
            table tbody tr td.highlight-text-3 {
                color: #0F7B8A !important;
            }

        table tbody tr.highlight-text-4 {
        }

            table tbody tr.highlight-text-4 td,
            table tbody tr th.highlight-text-4,
            table tbody tr td.highlight-text-4 {
                color: #A16326 !important;
            }

        table tbody tr.inactive {
        }

            table tbody tr.inactive th {
                font-style: italic;
                color: #686058;
            }

            table tbody tr.inactive td,
            table tbody tr td.inactive {
                font-style: italic;
                opacity: 0.5;
            }

    /*
        Highlight Cells 
        - Define these last so we can override stuff if we don't want to use !important hacks
        - But also, !important hacks are okay if we ignore the ability for CMS authors to easily define styles too - Highlighted cells should be highlighted if they marked up to be!
    */

        table tbody tr td.highlight-1,
        table tbody tr[class] td.highlight-1 {
            color: #165A26 !important;
            background-color: #E0F1E4 !important;
        }

        table tbody tr td.highlight-2,
        table tbody tr[class] td.highlight-2 {
            color: #721c24 !important;
            background-color: #F9D7D8 !important;
        }

        table tbody tr td.highlight-3,
        table tbody tr[class] td.highlight-3 {
            color: #0c5460 !important;
            background-color: #D4EFF2 !important;
        }

        table tbody tr td.highlight-4,
        table tbody tr[class] td.highlight-4 {
            color: #684018 !important;
            background-color: #FFF7E0 !important;
        }

    /* 
        Row Hover Effect
        - A basic row hover effect for all tables in the standard CSS. This may or may not suit your design.
        - Don't apply it in small screen views
    */

    @media only screen and (min-width: 600px) {

        table.table-hover tbody tr:hover {
            transition: 50ms all ease-in-out;
        }

            table.table-hover tbody tr:hover th,
            table.table-hover tbody tr:hover td:not([class*=highlight]):not([class*=alternate]) {
                color: #000 !important;
                background-color: #faf8f7 !important;
            }

            table.table-hover tbody tr:hover td[class*=highlight-text] {
                background-color: #faf8f7 !important;
            }

    }

/* 
    Plain Table 
    - Plain tables are available to authors who want to style everything in the CMS visual editor so they should provide only barebones styling. 
    - Plain tables can be used for layout so we don't change the font size. Using tables for layout is generally a bad idea for accessibility, but there are rare scenarios in which it might be semantically correct to use a table. E.g. a collection of profiles of people in a company with images and information could be properly represented as tabular data.
*/

table.plain {
    border: 0;
    border-collapse: collapse;
    border-spacing: 0;
}

    table.plain thead,
    table.plain tbody,
    table.plain tfoot {
        border: 0;
    }

    table.plain tr {
        background: none;
    }

        table.plain tr th {
            text-align: left;
            font-size: inherit;
            vertical-align: top;
            background: none;
            border: 0;
        }

        table.plain tr td,
        table.plain tr:nth-child(2n) td,
        table.plain tr td:last-child {
            text-align: left;
            font-size: inherit;
            font-weight: inherit;
            vertical-align: top;
            padding: 0;
            background: none;
            border: 0;
        }

/* 
    Simple Table
    - A nice looking minimalist table design.
    - Minimal layout also means authors retain a lot of control over how to lay out the table.
*/

table.simple {
    font-size: 84.21%;
    border-collapse: collapse;
    border: #e0dcdb 1px solid;
}

    table.simple tr th {
        padding: 15px;
        /*background: #dee3e6;*/
        background: #fff;
        border: #e0dcdb 1px solid;
    }

    table.simple tr td,
    table.simple tr:nth-child(2n) td,
    table.simple tr td:last-child {
        padding: 15px;
        background: #fff;
        border: #e0dcdb 1px solid;
    }

/* 
    Data Table #1
    - .dataTable1 is used in standard CMS transactional emails and some CMS widgets. Think of it as "the default CMS Table" style. Email styles are created in the emails of course - 
        not here - but keep consistency in mind.
    - Detailed design and technical notes are included for .dataTable1 that haven't been included with the other standard table layouts to reduce noise.
    - Avoid setting a background colour and font size at the table level, because it has knock-on effects for captions and responsive layouts.
*/

table.dataTable1 {
    color: #000; /* Use a strong default colour for content in tables, to promote accessible colour contrast even when cells and rows use background colours. */
    border-collapse: separate; /* So we can do rounded corners on the table, which is handled at the cell level to to CSS constraints. The table tag does not support border-radius. */
    border-spacing: 0; /* CSS version of cellspacing. */
    border: 0;
}

    table.dataTable1 caption {
    }

    /* Font Size */

        table.dataTable1 th,
        table.dataTable1 td {
            /* If the site base font size is 118.75% of the browser default (19px), this knocks tables back to 16px to fit more data in, but be careful about accessibility because the text size is now "standard" rather than "large" according to W3C rules. */
            font-size: 84.21%; 
        }

    /* 
        Cells
        - Highlighted cells are defined last in case we want to override stuff.
        - It's better to set background colours on cells rather than rows to provide maximum flexibility for rounded corners and other special shapes/layouts (especially in responsive views) - but keep cascading and specificity in mind.
        - To support possible use of rounded corners on the table itself, we need to use border-collapse: separate and so borders must be carefully defined on each cell edge.
    */

        /* Header Cells */

            table.dataTable1 tr th { 
                /* Headers in all rows. */
                padding: 15px;
                background: #fff;
                border-bottom: 2px solid #fff; 
                border-right: 2px solid #fff; 
	        }

            table.dataTable1 thead tr th { 
                /* Headers in header sections. Note: Headers in the first row override this in many tables, in which case this is a bit like a sub header style that only appears in a thead. */
                background-color: #f2f9fb; 
                border-bottom: 1px solid #d5e3e7;
	        }
 
            table.dataTable1 > tr:first-child > th, /* Headers in the first row of a table where rows are direct descendants of the table. */
            table.dataTable1 thead:first-child > tr:first-child > th, /* Headers in the first row when a thead comes first. */
            table.dataTable1 tbody:first-child > tr:first-child > th, /* Headers in the first row when a tbody comes first. */
            table.dataTable1 caption + thead > tr:first-child > th, /* Headers in the first row when there's a caption and a thead next. */
            table.dataTable1 caption + tbody > tr:first-child > th /* Headers in the first row when there's a caption and a tbody next. */ {
                /* Header cells in the first row of a table. */
                color: #fff;
                background-color: #0576b0; 
                border-right: 2px solid #fff;
            }

            table.dataTable1 > tr:first-child > th:first-child,
            table.dataTable1 thead:first-child > tr > th:first-child, 
            table.dataTable1 tbody:first-child > tr > th:first-child,
            table.dataTable1 caption + thead > tr:first-child > th:first-child,
            table.dataTable1 caption + tbody > tr:first-child > th:first-child {
                /* The first header cell in a header row or first row. */
                border-left: 0;
            }

            table.dataTable1 > tr:first-child > th:last-child,
            table.dataTable1 thead:first-child > tr > th:last-child,
            table.dataTable1 tbody:first-child > tr > th:last-child,
            table.dataTable1 caption + thead > tr:first-child > th:last-child,
            table.dataTable1 caption + tbody > tr:first-child > th:last-child {
                /* The last header cell in a header row or first row. */
                border-right: 0;
            }
          
            table.dataTable1 > tr > th:first-child,
            table.dataTable1 tbody tr th:first-child {
                /* The first header cell in a data row. */
                border-left: 0; 
                border-right: 2px solid #d5e3e7;
            }
                
            table.dataTable1 > tr > th:last-child,
            table.dataTable1 tbody tr th:last-child {
                /* The last header cell in a data row. */
                border-right: 0; 
            }

            table.dataTable1 > tr > th,
            table.dataTable1 tbody tr th {
                /* Headers in data rows. */
                border-bottom: 1px solid #d5e3e7; 
            }
                
            table.dataTable1 > tr:first-child > th, 
            table.dataTable1 tbody:first-child > tr > th, 
            table.dataTable1 caption + tbody > tr:first-child > th {
                /* Headers in the first row if there's no thead at the top. Should these look like headers in a thead, or sub headers, or something else? Perhaps it depends on the table design. */
            }

        /* Data Cells */

            table.dataTable1 tr td  {
                /* Data cells in all rows. */
                padding: 15px;
                background-color: #fff;
                border-bottom: 1px solid #d5e3e7; 
                border-right: 2px solid #fff; 
            }

            table.dataTable1 > tr:first-child > td, /* Data cells in the first row of a table where rows are direct descendants of the table. */
            table.dataTable1 thead:first-child > tr > td, /* Data cells in the first row when it's a thead. */
            table.dataTable1 tbody:first-child > tr > td, /* Data cells in the first row when it's a tbody. */
            table.dataTable1 caption + thead > tr:first-child > td, /* Data cells in the first row when there's a caption and a thead next. */
            table.dataTable1 caption + tbody > tr:first-child > td /* Data cells in the first row when there's a caption and a tbody next. */ {
                /* Data cells in the first row in every condition. */
                border-top: 2px solid #fff; 
            }

            table.dataTable1 > tr > td:first-child, 
            table.dataTable1 tbody tr td:first-child {
                /* The first data cell in a data row. */
                border-left: 0;
            }
        
            table.dataTable1 > tr > td:last-child, 
            table.dataTable1 tbody tr td:last-child {
                /* The last cell in a data row. */
                border-right: 0;
            }

        /* Dark Background Sections */

            [class*=background-dark] {

                table.dataTable1 > tr:first-child > th, 
                table.dataTable1 thead:first-child > tr:first-child > th, 
                table.dataTable1 tbody:first-child > tr:first-child > th, 
                table.dataTable1 caption + thead > tr:first-child > th, 
                table.dataTable1 caption + tbody > tr:first-child > th {
                    border-top: 1px solid #fff;
                }

                table.dataTable1 > tr > :first-child,
                table.dataTable1 thead tr > :first-child,
                table.dataTable1 tbody tr > :first-child {
                    border-left: 1px solid #fff !important;
                }
    
                table.dataTable1 > tr > :last-child,
                table.dataTable1 thead tr > :last-child,
                table.dataTable1 tbody tr > :last-child {
                    border-right: 1px solid #fff !important;
                }

            }

    /*
        Rows
        - Define rows after cells in case we want extra specificity for cells in special types of rows.
    */
    
        table.dataTable1 tr {
        }

        /* 
            Alternating Rows
            - In this layout we include headers in table body sections. That doesn't necessarily suit all tables.
        */

            table.dataTable1[class*=alternating-rows] tr th,
            table.dataTable1[class*=alternating-rows] tr td {
                border-bottom: 2px solid #fff;
            }

            table.dataTable1.alternating-rows tbody tr:nth-child(2n) th,
            table.dataTable1.alternating-rows tr:nth-child(2n) td {
                background-color: #f2f9fb;
            }

            table.dataTable1.alternating-rows-shift tbody tr:nth-child(2n-1) th,
            table.dataTable1.alternating-rows-shift tr:nth-child(2n-1) td {
                /* The "shift" provides some flexibility depending on where header and sub header rows are placed. */
                background-color: #f2f9fb;
            }

        /*
            Alternating Columns
            - In this layout we include headers in table body sections. That doesn't necessarily suit all tables.
        */

            table.dataTable1[class*=alternating-columns] tr th,
            table.dataTable1[class*=alternating-columns] tr td {
                border-bottom: 2px solid #fff;
            }

            table.dataTable1.alternating-columns tbody tr th:nth-child(2n),
            table.dataTable1.alternating-columns tr td:nth-child(2n) {
                background-color: #f2f9fb;
            }

            table.dataTable1.alternating-columns-shift tbody tr th:nth-child(2n-1),
            table.dataTable1.alternating-columns-shift tr td:nth-child(2n-1) {
                /* The "shift" provides some flexibility depending on where header cells are placed. */
                background-color: #f2f9fb;
            }

        /* Last row */

            table.dataTable1 > tr:last-child th,
            table.dataTable1 > tr:last-child td,
            table.dataTable1 tbody tr:last-child th,
            table.dataTable1 tbody tr:last-child td {
                /* Last row in the body of a table. Note that we might need some extra rules if tfoot elements are used. */
            }

        /* 
            Special Rows
            - thead or tbody are not targeted by default, all feature headers nd sub headers look the same. Expand this rule if needed. 
        */

            table.dataTable1 tr.feature-header {
            }

                table.dataTable1 tr.feature-header th {
                }

                table.dataTable1 tr.feature-header td {
                }

            table.dataTable1 tr.sub-header {
            }

                table.dataTable1 tr.sub-header th,
                table.dataTable1 tr.sub-header td {
                    vertical-align: bottom;
                    padding-top: 30px;
                    color: #000 !important;
                    background-color: #fff;
                    border-right-width: 0 !important;
                    border-left-width: 0 !important;
                    border-bottom: 2px solid #0576b0 !important;
                }
                
        /* 
            Highlight and Alternate Rows/Cells
            - The system defaults suit .dataTabe1 in the standard CSS, but you can do more work or implement overrides here if needed.
            - Ful blank CSS rules are included in the standard CSS in case you want to copy them when creating new table layouts.
        */

            table.dataTable1 tbody tr.highlight-1 {
            }

                table.dataTable1 tbody tr.highlight-1 th {
                }

                table.dataTable1 tbody tr.highlight-1 td {
                }

            table.dataTable1 tbody tr.highlight-2 {
            }

                table.dataTable1 tbody tr.highlight-2 th {
                }

                table.dataTable1 tbody tr.highlight-2 td {
                }

            table.dataTable1 tbody tr.highlight-3 {
            }

                table.dataTable1 tbody tr.highlight-3 th {
                }

                table.dataTable1 tbody tr.highlight-3 td {
                }

            table.dataTable1 tbody tr.highlight-4 {
            }

                table.dataTable1 tbody tr.highlight-4 th {
                }

                table.dataTable1 tbody tr.highlight-4 td {
                }

            table.dataTable1 tbody tr.alternate-1 {
            }

                table.dataTable1 tbody tr.alternate-1 th,
                table.dataTable1 tbody tr.alternate-1 td,
                table.dataTable1 tbody tr td.alternate-1 {
                }

            table.dataTable1 tbody tr.alternate-2 {
            }

                table.dataTable1 tbody tr.alternate-2 th,
                table.dataTable1 tbody tr.alternate-2 td,
                table.dataTable1 tbody tr td.alternate-2 {
                }

            table.dataTable1 tbody tr.alternate-3 {
            }

                table.dataTable1 tbody tr.alternate-3 th,
                table.dataTable1 tbody tr.alternate-3 td,
                table.dataTable1 tbody tr td.alternate-3 {
                }

            table.dataTable1 tbody tr.alternate-4 {
            }

                table.dataTable1 tbody tr.alternate-4 th,
                table.dataTable1 tbody tr.alternate-4 td,
                table.dataTable1 tbody tr td.alternate-4 {
                }

            table.dataTable1 tbody tr.highlight-text-1 {
            }

                table.dataTable1 tbody tr.highlight-text-1 td,
                table.dataTable1 tbody tr th.highlight-text-1,
                table.dataTable1 tbody tr td.highlight-text-1 {
                }

            table.dataTable1 tbody tr.highlight-text-2 {
            }

                table.dataTable1 tbody tr.highlight-text-2 td,
                table.dataTable1 tbody tr th.highlight-text-2,
                table.dataTable1 tbody tr td.highlight-text-2 {
                }

            table.dataTable1 tbody tr.highlight-text-3 {
            }

                table.dataTable1 tbody tr.highlight-text-3 td,
                table.dataTable1 tbody tr th.highlight-text-3,
                table.dataTable1 tbody tr td.highlight-text-3 {
                }

            table.dataTable1 tbody tr.highlight-text-4 {
            }

                table.dataTable1 tbody tr.highlight-text-4 td,
                table.dataTable1 tbody tr th.highlight-text-4,
                table.dataTable1 tbody tr td.highlight-text-4 {
                }

            table.dataTable1 tbody tr.inactive {
            }

                table.dataTable1 tbody tr.inactive th {
                }

                table.dataTable1 tbody tr.inactive td,
                table.dataTable1 tbody tr td.inactive {
                }

        /* Highlight Cells */

            table.dataTable1 tbody tr td.highlight-1,
            table.dataTable1 tbody tr[class] td.highlight-1 {
            }

            table.dataTable1 tbody tr td.highlight-2,
            table.dataTable1 tbody tr[class] td.highlight-2 {
            }

            table.dataTable1 tbody tr td.highlight-3,
            table.dataTable1 tbody tr[class] td.highlight-3 {
            }

            table.dataTable1 tbody tr td.highlight-4,
            table.dataTable1 tbody tr[class] td.highlight-4 {
            }

        /* Row Hover Effect */

        @media only screen and (min-width: 600px) {

            table.table-hover tbody tr:hover {
            }

                table.table-hover tbody tr:hover th,
                table.table-hover tbody tr:hover td:not([class*=highlight]):not([class*=alternate]) {
                    background-color: #e8e8ef !important;
                }

                table.table-hover tbody tr:hover td[class*=highlight-text] {
                    background-color: #e8e8ef !important;
                }

        }

    /*
        Themes
        - The idea of themes is just for changing colours. Significant table layout changes should get a different table class name.
    */

        table.dataTable1.theme-1 {
        }

            table.dataTable1.theme-1 thead tr th {
                /* Headers in header sections. */
            }

            table.dataTable1.theme-1 > tr:first-child > th,
            table.dataTable1.theme-1 thead:first-child > tr:first-child > th,
            table.dataTable1.theme-1 tbody:first-child > tr:first-child > th,
            table.dataTable1.theme-1 caption + thead > tr:first-child > th,
            table.dataTable1.theme-1 caption + tbody > tr:first-child > th {
                /* Header cells in the first row of a table. */
                background-color: #358366;
            }

            /* Alternating Rows */

                table.dataTable1.theme-1.alternating-rows tbody tr:nth-child(2n) th,
                table.dataTable1.theme-1.alternating-rows tr:nth-child(2n) td {
                    background-color: #f6fbf8;
                }

                table.dataTable1.theme-1.alternating-rows-shift tbody tr:nth-child(2n-1) th,
                table.dataTable1.theme-1.alternating-rows-shift tr:nth-child(2n-1) td {
                    background-color: #f6fbf8;
                }

            /* Alternating Columns */

                table.dataTable1.theme-1.alternating-columns tbody tr th:nth-child(2n),
                table.dataTable1.theme-1.alternating-columns tr td:nth-child(2n) {
                    background-color: #f6fbf8;
                }

                table.dataTable1.theme-1.alternating-columns-shift tbody tr th:nth-child(2n-1),
                table.dataTable1.theme-1.alternating-columns-shift tr td:nth-child(2n-1) {
                    background-color: #f6fbf8;
                }

            /* Special Rows */

                table.dataTable1.theme-1 tr.sub-header {
                }

                    table.dataTable1.theme-1 tr.sub-header th,
                    table.dataTable1.theme-1 tr.sub-header td {
                        border-bottom: 2px solid #358366 !important;
                    }

            /* Highlight and Alternate Rows/Cells */

                table.dataTable1.theme-1 tbody tr.alternate-1 {
                }

                    table.dataTable1.theme-1 tbody tr.alternate-1 th,
                    table.dataTable1.theme-1 tbody tr.alternate-1 td,
                    table.dataTable1.theme-1 tbody tr td.alternate-1 {
                        color: #000;
                        background-color: #f6fbf8;
                    }

                table.dataTable1.theme-1 tbody tr.alternate-2 {
                }

                    table.dataTable1.theme-1 tbody tr.alternate-2 th,
                    table.dataTable1.theme-1 tbody tr.alternate-2 td,
                    table.dataTable1.theme-1 tbody tr td.alternate-2 {
                        color: #000;
                        background-color: #e5f3e6;
                    }

                table.dataTable1.theme-1 tbody tr.alternate-3 {
                }

                    table.dataTable1.theme-1 tbody tr.alternate-3 th,
                    table.dataTable1.theme-1 tbody tr.alternate-3 td,
                    table.dataTable1.theme-1 tbody tr td.alternate-3 {
                        color: #fff;
                        background-color: #558b66;
                    }

                table.dataTable1.theme-1 tbody tr.alternate-4 {
                }

                    table.dataTable1.theme-1 tbody tr.alternate-4 th,
                    table.dataTable1.theme-1 tbody tr.alternate-4 td,
                    table.dataTable1.theme-1 tbody tr td.alternate-4 {
                        color: #fff;
                        background-color: #156346;
                    }

        table.dataTable1.theme-2 {
        }

            table.dataTable1.theme-2 thead tr th {
                /* Headers in header sections. */
                background-color: #d9d9df;
            }

            table.dataTable1.theme-2 > tr:first-child > th,
            table.dataTable1.theme-2 thead:first-child > tr:first-child > th,
            table.dataTable1.theme-2 tbody:first-child > tr:first-child > th,
            table.dataTable1.theme-2 caption + thead > tr:first-child > th,
            table.dataTable1.theme-2 caption + tbody > tr:first-child > th {
                /* Header cells in the first row of a table. */
                color: #fff;
                background-color: #081018;
            }

            /* Alternating Rows */
    
                /*table.dataTable1.theme-3[class*=alternating-rows] tr th,
                table.dataTable1.theme-3[class*=alternating-rows] tr td {
                    border-bottom: 1px solid #d5e3e7;
                }*/

                table.dataTable1.theme-2.alternating-rows tbody tr:nth-child(2n) th,
                table.dataTable1.theme-2.alternating-rows tr:nth-child(2n) td {
                    background-color: #f9f9fb;
                }

                table.dataTable1.theme-2.alternating-rows-shift tbody tr:nth-child(2n-1) th,
                table.dataTable1.theme-2.alternating-rows-shift tr:nth-child(2n-1) td {
                    background-color: #f9f9fb;
                }

            /* Alternating Columns */
    
                /*table.dataTable1.theme-3[class*=alternating-columns] tr th,
                table.dataTable1.theme-3[class*=alternating-columns] tr td {
                    border-bottom: 1px solid #d5e3e7;
                }*/

                table.dataTable1.theme-2.alternating-columns tbody tr th:nth-child(2n),
                table.dataTable1.theme-2.alternating-columns tr td:nth-child(2n) {
                    background-color: #f9f9fb;
                }

                table.dataTable1.theme-2.alternating-columns-shift tbody tr th:nth-child(2n-1),
                table.dataTable1.theme-2.alternating-columns-shift tr td:nth-child(2n-1) {
                    background-color: #f9f9fb;
                }

            /* Special Rows */

                table.dataTable1.theme-2 tr.sub-header {
                }

                    table.dataTable1.theme-2 tr.sub-header th {
                        vertical-align: bottom;
                        background-color: #fff;
                        border-bottom: 2px solid #081018 !important;
                    }

                    table.dataTable1.theme-2 tr.sub-header td {
                        vertical-align: bottom;
                        background-color: #fff;
                        border-bottom: 2px solid #081018 !important;
                    }

            /* Highlight and Alternate Rows/Cells */

                table.dataTable1.theme-2 tbody tr.alternate-1 {
                }

                    table.dataTable1.theme-2 tbody tr.alternate-1 th,
                    table.dataTable1.theme-2 tbody tr.alternate-1 td,
                    table.dataTable1.theme-2 tbody tr td.alternate-1 {
                        color: #000;
                        background-color: #f9f9fb;
                    }

                table.dataTable1.theme-2 tbody tr.alternate-2 {
                }

                    table.dataTable1.theme-2 tbody tr.alternate-2 th,
                    table.dataTable1.theme-2 tbody tr.alternate-2 td,
                    table.dataTable1.theme-2 tbody tr td.alternate-2 {
                        color: #000;
                        background-color: #f3f3f7;
                    }

                table.dataTable1.theme-2 tbody tr.alternate-3 {
                }

                    table.dataTable1.theme-2 tbody tr.alternate-3 th,
                    table.dataTable1.theme-2 tbody tr.alternate-3 td,
                    table.dataTable1.theme-2 tbody tr td.alternate-3 {
                        color: #fff;
                        background-color: #787880;
                    }

                table.dataTable1.theme-2 tbody tr.alternate-4 {
                }

                    table.dataTable1.theme-2 tbody tr.alternate-4 th,
                    table.dataTable1.theme-2 tbody tr.alternate-4 td,
                    table.dataTable1.theme-2 tbody tr td.alternate-4 {
                        color: #fff;
                        background-color: #4e4e56;
                    }

        table.dataTable1.theme-3 {
            @extend .theme-2;
        }

            table.dataTable1.theme-3 thead tr th {
                /* Headers in header sections. */
                background-color: #f0f0f8;
            }

            table.dataTable1.theme-3 > tr:first-child > th,
            table.dataTable1.theme-3 thead:first-child > tr:first-child > th,
            table.dataTable1.theme-3 tbody:first-child > tr:first-child > th,
            table.dataTable1.theme-3 caption + thead > tr:first-child > th,
            table.dataTable1.theme-3 caption + tbody > tr:first-child > th {
                /* Header cells in the first row of a table. */
                color: #000;
                background-color: #cce7f0;
                border-bottom: 0;
            }

        table.dataTable1.theme-4 {
            @extend .theme-3;
        }

            table.dataTable1.theme-4 thead tr th {
                /* Headers in header sections. */
            }

            table.dataTable1.theme-4 > tr:first-child > th,
            table.dataTable1.theme-4 thead:first-child > tr:first-child > th,
            table.dataTable1.theme-4 tbody:first-child > tr:first-child > th,
            table.dataTable1.theme-4 caption + thead > tr:first-child > th,
            table.dataTable1.theme-4 caption + tbody > tr:first-child > th {
                /* Header cells in the first row of a table. */
                color: #000;
                background-color: #dee3e6;
            }
            

table.dataTable2 {
    color: #000;
    border-collapse: separate; 
    border-spacing: 0; 
    border: 0;
}

    table.dataTable2 caption {
    }

    /* Font Size */

        table.dataTable2 th,
        table.dataTable2 td {
            font-size: 84.21%;
        }

    /* 
        Cells
    */

        /* Header Cells */

            table.dataTable2 tr th {
                /* Headers in all rows. */
                padding: 15px;
                color: #000;
                background-color: #fff;
            }

            table.dataTable2 thead tr th { 
                /* Headers in header sections. */
                background-color: #fff;
	        }

            table.dataTable2 > tr:first-child > th,
            table.dataTable2 thead:first-child > tr:first-child > th,
            table.dataTable2 tbody:first-child > tr:first-child > th,
            table.dataTable2 caption + thead > tr:first-child > th,
            table.dataTable2 caption + tbody > tr:first-child > th {
                /* Header cells in the first row of a table. */
                color: #000;
                background-color: #fff;
                border-right: 2px solid #d5e3e7;
                border-bottom: 1px solid #d5e3e7;
            }
        
            table.dataTable2 tr th:last-child {
                /* Last header in all rows. */
                border-right: 0 !important;
            }

            table.dataTable2 > tr > th,
            table.dataTable2 tbody tr th {
                /* Headers in data rows. */
                color: #fff;
                background-color: #006188;
                border-right: 2px solid #c5d3d7; 
                border-bottom: 1px solid #c5d3d7; 
            }

        /* Data Cells */

            table.dataTable2 tr td  {
                /* Data cells in all rows. */
                padding: 15px;
                color: #fff;
                background-color: #0088b2;
                border-right: 1px solid #c5d3d7; 
                border-bottom: 1px solid #c5d3d7; 
            }

            table.dataTable2 > tr > *:first-child, 
            table.dataTable2 tbody tr > *:first-child {
                /* The first cell in a data row. */
                border-left: 0;
            }
        
            table.dataTable2 > tr > *:last-child, 
            table.dataTable2 tbody tr > *:last-child {
                /* The last cell in a data row. */
                border-right: 0;
            }v

    /*
        Rows
    */
    
        /* Alternating Rows */

            table.dataTable2.alternating-rows tr:nth-child(2n) td {
                background-color: #00749e;
            }

            table.dataTable2.alternating-rows-shift tr:nth-child(2n-1) td {
                background-color: #00749e;
            }

        /* Alternating Columns */

            table.dataTable2.alternating-columns tr td:nth-child(2n) {
                background-color: #00749e;
            }

            table.dataTable2.alternating-columns-shift tr td:nth-child(2n-1) {
                background-color: #00749e;
            }

        /* Last Row */

            table.dataTable2 > tr:last-child th,
            table.dataTable2 > tr:last-child td,
            table.dataTable2 tbody tr:last-child th,
            table.dataTable2 tbody tr:last-child td {
            }

        /* Special Rows */

            table.dataTable2 tr.feature-header {
            }

                table.dataTable2 tr.feature-header th {
                }

                table.dataTable2 tr.feature-header td {
                }

            table.dataTable2 tr.sub-header {
            }

                table.dataTable2 tr.sub-header th,
                table.dataTable2 tr.sub-header td {
                    vertical-align: bottom;
                    color: #fff;
                    background-color: #006188 !important;
                    border-right: 0;
                    border-bottom: 1px solid #c5d3d7; 
                }

        /* Highlight and Alternate Rows/Cells */

            table.dataTable2 tbody tr.highlight-1 {
            }

                table.dataTable2 tbody tr.highlight-1 th,
                table.dataTable2 tbody tr.highlight-1 td {
                    color: #E0F1E4 !important;
                    background-color: #568A56 !important;
                    border-color: rgba(255,255,255,0.65) !important;
                }

            table.dataTable2 tbody tr.highlight-2 {
            }

                table.dataTable2 tbody tr.highlight-2 th,
                table.dataTable2 tbody tr.highlight-2 td {
                    color: #F9D7D8 !important;
                    background-color: #923c44 !important;
                    border-color: rgba(255,255,255,0.65) !important;
                }

            table.dataTable2 tbody tr.highlight-3 {
            }

                table.dataTable2 tbody tr.highlight-3 th,
                table.dataTable2 tbody tr.highlight-3 td {
                    color: #D4EFF2 !important;
                    background-color: #4c94a0 !important;
                    border-color: rgba(255,255,255,0.65) !important;
                }

            table.dataTable2 tbody tr.highlight-4 {
            }

                table.dataTable2 tbody tr.highlight-4 th,
                table.dataTable2 tbody tr.highlight-4 td {
                    color: #FFF7E0 !important;
                    background-color: #987848 !important;
                    border-color: rgba(255,255,255,0.65) !important;
                }

            table.dataTable2 tbody tr.highlight-1 th,
            table.dataTable2 tbody tr.highlight-2 th,
            table.dataTable2 tbody tr.highlight-3 th,
            table.dataTable2 tbody tr.highlight-4 th {
                color: #fff !important;
            }

            table.dataTable2 tbody tr.alternate-1 {
            }

                table.dataTable2 tbody tr.alternate-1 th,
                table.dataTable2 tbody tr.alternate-1 td,
                table.dataTable2 tbody tr td.alternate-1 {
                    color: #fff;
                    background-color: #006188;
                }

            table.dataTable2 tbody tr.alternate-2 {
            }

                table.dataTable2 tbody tr.alternate-2 th,
                table.dataTable2 tbody tr.alternate-2 td,
                table.dataTable2 tbody tr td.alternate-2 {
                    color: #fff;
                    background-color: #0088b2;
                }

            table.dataTable2 tbody tr.alternate-3 {
            }

                table.dataTable2 tbody tr.alternate-3 th,
                table.dataTable2 tbody tr.alternate-3 td,
                table.dataTable2 tbody tr td.alternate-3 {
                    color: #000;
                    background-color: #e5f3f7;
                }

            table.dataTable2 tbody tr.alternate-4 {
            }

                table.dataTable2 tbody tr.alternate-4 th,
                table.dataTable2 tbody tr.alternate-4 td,
                table.dataTable2 tbody tr td.alternate-4 {
                    color: #000;
                    background-color: #f2f9fb;
                }

            table.dataTable2 tbody tr.inactive {
            }

                table.dataTable2 tbody tr.inactive th {
                    color: #c8c0b8 !important;
                }

                table.dataTable2 tbody tr.inactive td,
                table.dataTable2 tbody tr td.inactive {
                }

            /* Just Highlighted Text */

            /* Note: Accessibility warning! Some of these fail AA for normal size text. */

                table.dataTable2 tbody tr.highlight-text-1 {
                }

                    table.dataTable2 tbody tr.highlight-text-1 td,
                    table.dataTable2 tbody tr th.highlight-text-1,
                    table.dataTable2 tbody tr td.highlight-text-1 {
                        color: #b0F1b4 !important;
                    }

                table.dataTable2 tbody tr.highlight-text-2 {
                }

                    table.dataTable2 tbody tr.highlight-text-2 td,
                    table.dataTable2 tbody tr th.highlight-text-2,
                    table.dataTable2 tbody tr td.highlight-text-2 {
                        color: #F9D7D8 !important;
                    }

                table.dataTable2 tbody tr.highlight-text-3 {
                }

                    table.dataTable2 tbody tr.highlight-text-3 td,
                    table.dataTable2 tbody tr th.highlight-text-3,
                    table.dataTable2 tbody tr td.highlight-text-3 {
                        color: #D4EFF2 !important;
                    }

                table.dataTable2 tbody tr.highlight-text-4 {
                }

                    table.dataTable2 tbody tr.highlight-text-4 td,
                    table.dataTable2 tbody tr th.highlight-text-4,
                    table.dataTable2 tbody tr td.highlight-text-4 {
                        color: #FFF7b0 !important;
                    }

    /*
        Highlighted Cells 
    */

        table.dataTable2 tbody tr td.highlight-1,
        table.dataTable2 tbody tr[class] td.highlight-1 {
            color: #E0F1E4 !important;
            background-color: #568A56 !important;
        }

        table.dataTable2 tbody tr td.highlight-2,
        table.dataTable2 tbody tr[class] td.highlight-2 {
            color: #F9D7D8 !important;
            background-color: #923c44 !important;
        }

        table.dataTable2 tbody tr td.highlight-3,
        table.dataTable2 tbody tr[class] td.highlight-3 {
            color: #D4EFF2 !important;
            background-color: #4c94a0 !important;
        }

        table.dataTable2 tbody tr td.highlight-4,
        table.dataTable2 tbody tr[class] td.highlight-4 {
            color: #FFF7E0 !important;
            background-color: #987848 !important;
        }

    /*
        Themes
    */

        table.dataTable2.theme-1 {
        }

            /* Header Cells */

                table.dataTable2.theme-1 tr th {
                    /* Headers in all rows. */
                }

                table.dataTable2.theme-1 thead tr th {
                    /* Headers in header sections. */
                }

                table.dataTable2.theme-1 > tr:first-child > th,
                table.dataTable2.theme-1 thead:first-child > tr:first-child > th,
                table.dataTable2.theme-1 tbody:first-child > tr:first-child > th,
                table.dataTable2.theme-1 caption + thead > tr:first-child > th,
                table.dataTable2.theme-1 caption + tbody > tr:first-child > th {
                    /* Header cells in the first row of a table. */
                }
            
                table.dataTable2.theme-1 tr th:last-child {
                    /* Last header in all rows. */
                    border-right: 0 !important;
                }

                table.dataTable2.theme-1 > tr > th,
                table.dataTable2.theme-1 tbody tr th {
                    /* Headers in data rows. */
                    background-color: #156346;
                    border-color: #d5e7e3;
                }

            /* Data Cells */

                table.dataTable2.theme-1 tr td {
                    background-color: #358366;
                    border-color: #d5e7e3;
                }

                    table.dataTable2.theme-1
                    table.dataTable2.theme-1 tr th,
                    table.dataTable2.theme-1 tr td,
                    table.dataTable2.theme-1 thead tr th:first-child,
                    table.dataTable2.theme-1 tbody tr th:first-child,
                    table.dataTable2.theme-1 tr td:first-child,
                    table.dataTable2.theme-1 > tr:first-child > *,
                    table.dataTable2.theme-1 thead:first-child > tr > *,
                    table.dataTable2.theme-1 tbody:first-child > tr > *,
                    table.dataTable2.theme-1 caption + thead > tr:first-child > *,
                    table.dataTable2.theme-1 caption + tbody > tr:first-child > *,
                    table.dataTable2.theme-1 tbody tr[class*=highlight] > * {
                        /* Cells in all rows including highlight rows. */
                    }

            /* Alternating Rows */

                table.dataTable2.theme-1.alternating-rows tr:nth-child(2n) td {
                    background-color: #257356;
                }

                table.dataTable2.theme-1.alternating-rows-shift tr:nth-child(2n-1) td {
                    background-color: #257356;
                }

            /* Alternating Columns */

                table.dataTable2.theme-1.alternating-columns tr td:nth-child(2n) {
                    background-color: #257356;
                }

                table.dataTable2.theme-1.alternating-columns-shift tr td:nth-child(2n-1) {
                    background-color: #257356;
                }

            /* Special Rows */

                table.dataTable2.theme-1 tr.sub-header {
                }

                    table.dataTable2.theme-1 tr.sub-header th {
                        background-color: #156346 !important;
                    }

                    table.dataTable2.theme-1 tr.sub-header td {
                        background-color: #156346 !important;
                    }

            /* Highlight and Alternate Rows/Cells */

                table.dataTable2.theme-1 tbody tr.alternate-1 {
                }

                    table.dataTable2.theme-1 tbody tr.alternate-1 th,
                    table.dataTable2.theme-1 tbody tr.alternate-1 td,
                    table.dataTable2.theme-1 tbody tr td.alternate-1 {
                        background-color: #156346;
                    }

                table.dataTable2.theme-1 tbody tr.alternate-2 {
                }

                    table.dataTable2.theme-1 tbody tr.alternate-2 th,
                    table.dataTable2.theme-1 tbody tr.alternate-2 td,
                    table.dataTable2.theme-1 tbody tr td.alternate-2 {
                        background-color: #558b66;
                    }

                table.dataTable2.theme-1 tbody tr.alternate-3 {
                }

                    table.dataTable2.theme-1 tbody tr.alternate-3 th,
                    table.dataTable2.theme-1 tbody tr.alternate-3 td,
                    table.dataTable2.theme-1 tbody tr td.alternate-3 {
                        background-color: #e5f3e6;
                    }

                table.dataTable2.theme-1 tbody tr.alternate-4 {
                }

                    table.dataTable2.theme-1 tbody tr.alternate-4 th,
                    table.dataTable2.theme-1 tbody tr.alternate-4 td,
                    table.dataTable2.theme-1 tbody tr td.alternate-4 {
                        color: #000;
                        background-color: #f6fbf8;
                    }

                table.dataTable2.theme-1 tbody tr.inactive {
                }

                    table.dataTable2.theme-1 tbody tr.inactive th {
                    }

            /* Just Highlighted Text */

            /* Note: Accessibility warning! Some of these fail AA for normal size text. */

                table.dataTable2.theme-1  tbody tr.highlight-text-1 {
                }

                    table.dataTable2.theme-1  tbody tr.highlight-text-1 td,
                    table.dataTable2.theme-1  tbody tr th.highlight-text-1,
                    table.dataTable2.theme-1  tbody tr td.highlight-text-1 {
                        color: #c4Ffc8 !important;
                    }

                table.dataTable2.theme-1  tbody tr.highlight-text-2 {
                }

                    table.dataTable2.theme-1  tbody tr.highlight-text-2 td,
                    table.dataTable2.theme-1  tbody tr th.highlight-text-2,
                    table.dataTable2.theme-1  tbody tr td.highlight-text-2 {
                        color: #Ffd7d8 !important;
                    }

                table.dataTable2.theme-1  tbody tr.highlight-text-3 {
                }

                    table.dataTable2.theme-1  tbody tr.highlight-text-3 td,
                    table.dataTable2.theme-1  tbody tr th.highlight-text-3,
                    table.dataTable2.theme-1  tbody tr td.highlight-text-3 {
                        color: #d4dFff !important;
                    }

                table.dataTable2.theme-1  tbody tr.highlight-text-4 {
                }

                    table.dataTable2.theme-1  tbody tr.highlight-text-4 td,
                    table.dataTable2.theme-1  tbody tr th.highlight-text-4,
                    table.dataTable2.theme-1  tbody tr td.highlight-text-4 {
                        color: #FFF7b0 !important;
                    }

            /*  Row Hover Effect */
    
            @media only screen and (min-width: 600px) {
    
                table.dataTable2.theme-1.table-hover tbody tr:hover {
                }
    
                    table.dataTable2.theme-1.table-hover tbody tr:hover th,
                    table.dataTable2.theme-1.table-hover tbody tr:hover td:not([class*=highlight]):not([class*=alternate]) {
                        background-color: #e8efe8 !important;
                    }
    
                    table.dataTable2.theme-1.table-hover tbody tr:hover td[class*=alternate],
                    table.dataTable2.theme-1.table-hover tbody tr:hover td[class*=highlight-text] {
                        background-color: #e8efe8 !important;
                    }
    
            }
            
        table.dataTable2.theme-2 {
        }

            table.dataTable2.theme-2 thead tr th {
                /* Headers in header sections. */
            }

            table.dataTable2.theme-2 > tr:first-child > th,
            table.dataTable2.theme-2 thead:first-child > tr:first-child > th,
            table.dataTable2.theme-2 tbody:first-child > tr:first-child > th,
            table.dataTable2.theme-2 caption + thead > tr:first-child > th,
            table.dataTable2.theme-2 caption + tbody > tr:first-child > th {
                /* Header cells in the first row of a table. */
                color: #fff;
                background-color: #081018;
                border-right-color: rgba(255,255,255,0.4);
                border-bottom: 0;
            }

            table.dataTable2.theme-2 > tr > th,
            table.dataTable2.theme-2 tbody tr:not([class*=alternate]) th {
                /* Headers in data rows excluding alternate rows. */
                color: #fff;
            }

            table.dataTable2.theme-2 tr td {
                border-right-width: 2px;
            }

        /* Special Rows */

            table.dataTable2.theme-2 tr.sub-header {
            }

                table.dataTable2.theme-2 tr.sub-header th {
                }

                table.dataTable2.theme-2 tr.sub-header td {
                }
                    
        table.dataTable2.theme-3 {
        }

            table.dataTable2.theme-3 thead tr th {
                /* Headers in header sections. */
            }

            table.dataTable2.theme-3 > tr:first-child > th,
            table.dataTable2.theme-3 thead:first-child > tr:first-child > th,
            table.dataTable2.theme-3 tbody:first-child > tr:first-child > th,
            table.dataTable2.theme-3 caption + thead > tr:first-child > th,
            table.dataTable2.theme-3 caption + tbody > tr:first-child > th {
                /* Header cells in the first row of a table. */
                background-color: #cce7f0;
                border-right-width: 1px;
                border-bottom: 0;
            }

            table.dataTable2.theme-3 > tr > th,
            table.dataTable2.theme-3 tbody tr th,
            table.dataTable2.theme-3 tbody tr[class*=highlight] th,
            table.dataTable2.theme-3 tbody tr[class*=alternate] th {
                /* Headers in data rows including highlight and alternate rows. */
                color: #000;
                background-color: #fff;
                border-right: 0;
            }

        /* Special Rows */

            table.dataTable2.theme-3 tr.sub-header {
            }

                table.dataTable2.theme-3 tr.sub-header th {
                    color: #fff !important;
                }

                table.dataTable2.theme-3 tr.sub-header td {
                }

        table.dataTable2.theme-4 {
        }
            /* Header Cells */

                table.dataTable2.theme-4 thead tr th {
                    /* Headers in header sections. */
                }

                table.dataTable2.theme-4 > tr:first-child > th,
                table.dataTable2.theme-4 thead:first-child > tr:first-child > th,
                table.dataTable2.theme-4 tbody:first-child > tr:first-child > th,
                table.dataTable2.theme-4 caption + thead > tr:first-child > th,
                table.dataTable2.theme-4 caption + tbody > tr:first-child > th {
                    /* Header cells in the first row of a table. */
                    background-color: #dee3e6;
                }

                table.dataTable2.theme-4  > tr > th,
                table.dataTable2.theme-4  tbody tr th {
                    /* Headers in data rows. */
                    color: #fff;
                    background-color: #fff;
                    border-right: 0;
                }

            /* Data Cells */

                table.dataTable2.theme-4 tr td {
                    background-color: #4e4e56;
                }

            /* Alternating Rows */

                table.dataTable2.theme-4.alternating-rows tr:nth-child(2n) td {
                    background-color: #787880;
                }

                table.dataTable2.theme-4.alternating-rows-shift tr:nth-child(2n-1) td {
                    background-color: #787880;
                }

            /* Alternating Columns */

                table.dataTable2.theme-4.alternating-columns tr td:nth-child(2n) {
                    background-color: #787880;
                }

                table.dataTable2.theme-4.alternating-columns-shift tr td:nth-child(2n-1) {
                    background-color: #787880;
                }

            /* Special Rows */

                table.dataTable2.theme-4 tr.sub-header {
                }

                    table.dataTable2.theme-4 tr.sub-header th {
                        color: #000 !important;
                        background-color: #bec3c6 !important;
                    }

                    table.dataTable2.theme-4 tr.sub-header td {
                        color: #000 !important;
                        background-color: #bec3c6 !important;
                    }

            /* Highlight and Alternate Rows/Cells */

                table.dataTable2.theme-4 tbody tr.alternate-1 {
                }

                    table.dataTable2.theme-4 tbody tr.alternate-1 th,
                    table.dataTable2.theme-4 tbody tr.alternate-1 td,
                    table.dataTable2.theme-4 tbody tr td.alternate-1 {
                        color: #fff;
                        background-color: #4e4e56;
                    }

                table.dataTable2.theme-4 tbody tr.alternate-2 {
                }

                    table.dataTable2.theme-4 tbody tr.alternate-2 th,
                    table.dataTable2.theme-4 tbody tr.alternate-2 td,
                    table.dataTable2.theme-4 tbody tr td.alternate-2 {
                        color: #fff;
                        background-color: #787880;
                    }

                table.dataTable2.theme-4 tbody tr.alternate-3 {
                }

                    table.dataTable2.theme-4 tbody tr.alternate-3 th,
                    table.dataTable2.theme-4 tbody tr.alternate-3 td,
                    table.dataTable2.theme-4 tbody tr td.alternate-3 {
                        color: #000;
                        background-color: #f3f3f7;
                    }

                table.dataTable2.theme-4 tbody tr.alternate-4 {
                }

                    table.dataTable2.theme-4 tbody tr.alternate-4 th,
                    table.dataTable2.theme-4 tbody tr.alternate-4 td,
                    table.dataTable2.theme-4 tbody tr td.alternate-4 {
                        color: #000;
                        background-color: #f9f9fb;
                    }

table.dataTable3 {
    color: #000;
    border-collapse: separate;
    border-spacing: 0;
    border: 0;
}

    table.dataTable3 caption {
    }

    /* Font Size */

        table.dataTable3 th,
        table.dataTable3 td {
            font-size: 84.21%;
        }

    /*
        Cells
    */

    /* Header Cells */

        table.dataTable3 tr th,
        table.dataTable3 thead tr th {
            padding: 15px;
            color: #fff;
            background-color: #484038;
            border-bottom: 1px solid #fff;
        }

        table.dataTable3 tbody tr th {
            padding: 15px;
            color: #000;
            background-color: #d8d0c8;
            border-right: 1px solid #fff;
        }

        table.dataTable3 > tr:first-child > th, /* Headers in the first row of a table where rows are direct descendants of the table. */
        table.dataTable3 thead:first-child > tr > th, /* Headers in the first row when it's a thead. */
        table.dataTable3 tbody:first-child > tr > th, /* Headers in the first row when it's a tbody. */
        table.dataTable3 caption + thead > tr:first-child > th, /* Headers in the first row when there's a caption and a thead next. */
        table.dataTable3 caption + tbody > tr:first-child > th /* Headers in the first row when there's a caption and a tbody next. */ {
            /* Header cells in the first row in every condition. */
            /*border-top: 1px solid #fff;*/
        }

    /* Data Cells */

        table.dataTable3 tr td {
                /* Data cells in all rows. */
            padding: 15px;
            background-color: #ece6e0;
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
        }
        
    /* Cells in Fixed Locations */

        table.dataTable3 tr > :last-child {
            /* The last cell in all rows. */
            border-right: 0;
        }

        table.dataTable3 > tr:last-child > *,
        table.dataTable3 tbody tr:last-child > * {
            /* All cells in the last row of a table or tbody section. */
            border-bottom: 0;
        }

    /*
        Rows
    */

        /* Alternating Rows */
    
            table.dataTable3.alternating-rows tbody tr:nth-child(2n) th,
            table.dataTable3.alternating-rows tr:nth-child(2n) td {
                background-color: #faf8f7;
            }

            table.dataTable3.alternating-rows-shift tbody tr:nth-child(2n-1) th,
            table.dataTable3.alternating-rows-shift tr:nth-child(2n-1) td {
                background-color: #faf8f7;
            }

        /* Alternating Columns */

            table.dataTable3.alternating-columns tbody tr th:nth-child(2n),
            table.dataTable3.alternating-columns tr td:nth-child(2n) {
                background-color: #faf8f7;
            }

            table.dataTable3.alternating-columns-shift tbody tr th:nth-child(2n-1),
            table.dataTable3.alternating-columns-shift tr td:nth-child(2n-1) { 
                background-color: #faf8f7;
            }

        /* Special Rows */

            table.dataTable3 tr.feature-header {
            }

                table.dataTable3 tr.feature-header th {
                }

                table.dataTable3 tr.feature-header td {
                }

            table.dataTable3 tr.sub-header {
            }

                table.dataTable3 tr.sub-header th {
                    vertical-align: bottom;
                    color: #fff;
                    background-color: #6c6862 !important;
                    border-right: 1px solid #fff;
                }

                table.dataTable3 tr.sub-header td {
                    vertical-align: bottom;
                    color: #fff;
                    background-color: #6c6862 !important;
                    border-right: 1px solid #fff;
                }

        /* Highlight and Alternate Rows/Cells */

            table.dataTable3 tbody tr.highlight-1 {
            }

                table.dataTable3 tbody tr.highlight-1 th,
                table.dataTable3 tbody tr.highlight-1 td {
                    border-right-color: #fff !important;
                }

            table.dataTable3 tbody tr.highlight-2 {
            }

                table.dataTable3 tbody tr.highlight-2 th,
                table.dataTable3 tbody tr.highlight-2 td {
                    border-right-color: #fff !important;
                }

            table.dataTable3 tbody tr.highlight-3 {
            }

                table.dataTable3 tbody tr.highlight-3 th,
                table.dataTable3 tbody tr.highlight-3 td {
                    border-right-color: #fff !important;
                }

            table.dataTable3 tbody tr.highlight-4 {
            }

                table.dataTable3 tbody tr.highlight-4 th,
                table.dataTable3 tbody tr.highlight-4 td {
                    border-right-color: #fff !important;
                }

            table.dataTable3 tbody tr.inactive {
            }

                table.dataTable3 tbody tr.inactive th {
                    color: #585048 !important;
                }

                table.dataTable3 tbody tr.inactive td,
                table.dataTable3 tbody tr td.inactive {
                }

table.dataTable4 {
    color: #fff;
    border-collapse: separate;
    border-spacing: 0;
    border: 0;
}

    table.dataTable4 caption {
    }

    /* Font Size */

        table.dataTable1 th,
        table.dataTable1 td {
            font-size: 84.21%;
        }

    /*
        Cells
    */

        /* Header Cells */

            table.dataTable4 tr th {
                /* Headers in all rows. */
                padding: 15px;
                color: #fff;
                background-color: #181008;
                border: 0;
            }

            table.dataTable4 thead tr th {
                /* Headers in the header row. */
                padding: 30px 15px 15px;
                font-size: 118.75%;
            }

            table.dataTable4 > tr > th,
            table.dataTable4 tbody tr th,
            table.dataTable4 tbody tr[class*=highlight] th,
            table.dataTable4 tbody tr[class*=alternate] th {
                /* Headers in data rows including highlight and alternate rows. */
                padding: 15px;
                color: #fff;
                background-color: #484038 !important;
                border: 0;
                border-bottom: rgba(0,0,0,0.5) 1px solid;
            }

        /* Data Cells */

            table.dataTable4 tr td {
                /* Data cells in all rows. */
                padding: 10px 15px;
                background-color: #6c6862;
                border: 0;
                border-bottom: rgba(255,255,255,0.25) 1px solid;
            }

        /* Cells in Fixed Locations */

            table.dataTable4 tr:last-child th,
            table.dataTable4 tr:last-child td {
                /* Cells in the last row. */
                border-bottom: 0;
            }

    /* 
        Rows
    */

        /* Alternating Rows */

            table.dataTable4.alternating-rows tbody tr:nth-child(2n) th,
            table.dataTable4.alternating-rows tr:nth-child(2n) td {
                background-color: #7c7872;
            }

            table.dataTable4.alternating-rows-shift tbody tr:nth-child(2n-1) th,
            table.dataTable4.alternating-rows-shift tr:nth-child(2n-1) td { 
                background-color: #7c7872;
            }

        /* Alternating Columns */

            table.dataTable4.alternating-columns tbody tr th:nth-child(2n),
            table.dataTable4.alternating-columns tr td:nth-child(2n) {
                background-color: #7c7872;
            }

            table.dataTable4.alternating-columns-shift tbody tr th:nth-child(2n-1),
            table.dataTable4.alternating-columns-shift tr td:nth-child(2n-1) { 
                background-color: #7c7872;
            }

        table.dataTable4 tr.feature-header {
        }

            table.dataTable4 tr.feature-header th {
            }

            table.dataTable4 tr.feature-header td {
            }

        /* Special Rows */

            table.dataTable4 tr.sub-header {
            }

                table.dataTable4 tr.sub-header th {
                    vertical-align: bottom;
                    color: #fff;
                    background-color: #484038 !important;
                }

                table.dataTable4 tr.sub-header td {
                    padding: 15px 10px;
                    vertical-align: bottom;
                    color: #fff;
                    background-color: #484038 !important;
                }

        /* Highlight and Alternate Rows/Cells */

            table.dataTable4 tbody tr.highlight-1 {
            }

                table.dataTable4 tbody tr.highlight-1 th {
                }

                table.dataTable4 tbody tr.highlight-1 td {
                    color: #fff !important;
                    background-color: #007500 !important;
                }

            table.dataTable4 tbody tr.highlight-2 {
            }

                table.dataTable4 tbody tr.highlight-2 th {
                }

                table.dataTable4 tbody tr.highlight-2 td {
                    color: #fff !important;
                    background-color: #721c24 !important;
                }

            table.dataTable4 tbody tr.highlight-3 {
            }

                table.dataTable4 tbody tr.highlight-3 th {
                }

                table.dataTable4 tbody tr.highlight-3 td {
                    color: #fff !important;
                    background-color: #0c5460 !important;
                }

            table.dataTable4 tbody tr.highlight-4 {
            }

                table.dataTable4 tbody tr.highlight-4 th {
                }

                table.dataTable4 tbody tr.highlight-4 td {
                    color: #fff !important;
                    background-color: #684018 !important;
                }

            table.dataTable4 tbody tr.highlight-text-1 {
            }

                table.dataTable4 tbody tr.highlight-text-1 td,
                table.dataTable4 tbody tr th.highlight-text-1,
                table.dataTable4 tbody tr td.highlight-text-1 {
                    color: #b0F1b4 !important;
                }

            table.dataTable4 tbody tr.highlight-text-2 {
            }

                table.dataTable4 tbody tr.highlight-text-2 td,
                table.dataTable4 tbody tr th.highlight-text-2,
                table.dataTable4 tbody tr td.highlight-text-2 {
                    color: #F99798 !important;
                }

            table.dataTable4 tbody tr.highlight-text-3 {
            }

                table.dataTable4 tbody tr.highlight-text-3 td,
                table.dataTable4 tbody tr th.highlight-text-3,
                table.dataTable4 tbody tr td.highlight-text-3 {
                    color: #b4cFF2 !important;
                }

            table.dataTable4 tbody tr.highlight-text-4 {
            }

                table.dataTable4 tbody tr.highlight-text-4 td,
                table.dataTable4 tbody tr th.highlight-text-4,
                table.dataTable4 tbody tr td.highlight-text-4 {
                    color: #FFF7b0 !important;
            }

            table.dataTable4 tbody tr.inactive {
            }

                table.dataTable4 tbody tr.inactive th {
                    color: #c8c0b8;
                    opacity: 1;
                }

                table.dataTable4 tbody tr.inactive td,
                table.dataTable4 tbody tr td.inactive {
                    opacity: 0.9;
                    color: #000;
                    border-bottom: #918e8a;
                }

    /* Highlight Cells */
    
        table.dataTable4 tbody tr:last-child th,
        table.dataTable4 tbody tr:last-child td {
        }

        table.dataTable4 tbody tr td.highlight-1,
        table.dataTable4 tbody tr[class] td.highlight-1 {
            color: #fff !important;
            background-color: #007500 !important;
        }

        table.dataTable4 tbody tr td.highlight-2,
        table.dataTable4 tbody tr[class] td.highlight-2 {
            color: #fff !important;
            background-color: #721c24 !important;
        }

        table.dataTable4 tbody tr td.highlight-3,
        table.dataTable4 tbody tr[class] td.highlight-3 {
            color: #fff !important;
            background-color: #0c5460 !important;
        }

        table.dataTable4 tbody tr td.highlight-4,
        table.dataTable4 tbody tr[class] td.highlight-4 {
            color: #fff !important;
            background-color: #684018 !important;
        }

/*
    Schedule Table
*/

table.scheduleTable {
}

	table.scheduleTable tr th,
    table.scheduleTable thead tr th, 
    table.scheduleTable tr.header th { 
	}

	table.scheduleTable tbody tr th, 
    table.scheduleTable tr.data th { 
	}

	table.scheduleTable tr td { 
	}

/* 
    Rounded Corners
    - By default we set up rounded corners on all tables, assuming that's how the general site design works. Alter this to target individual table layouts if needed.
    - Note that this will only apply to tables that set border-collapse:separate, so you can ask individual tables to override this by setting border-collapse differently.
    - Supports tables with and without thead and tbody markup, and captions.
    - Does not specify th or td - These should always be direct descendents of tr in validly marked up tables.
*/

table > tr:first-child > :first-child,
table thead:first-child tr:first-child > :first-child,
table tbody:first-child tr:first-child > :first-child,
table caption + thead > tr:first-child > :first-child,
table caption + tbody > tr:first-child > :first-child {
    border-top-left-radius: 3px;
}

table > tr:first-child > :last-child,
table thead:first-child tr:first-child > :last-child,
table tbody:first-child tr:first-child > :last-child,
table caption + thead > tr:first-child > :last-child,
table caption + tbody > tr:first-child > :last-child {
    border-top-right-radius: 3px;
}

table > tr:last-child > :first-child, /* When rows are direct descendants of the table tag. */
table > :last-child > tr:last-child > :first-child /* Only the last thead, tbody, or tfoot element. */ {
    border-bottom-left-radius: 3px;
}

table > tr:last-child > :last-child,
table > :last-child > tr:last-child > :last-child {
    border-bottom-right-radius: 3px;
}

/*
    Hide vertical borders on any table or on a single row or in header or body rows
*/

    table.hide-vertical-borders,
    table.hide-vertical-borders th,
    table.hide-vertical-borders td {
        border-left-width: 0 !important;
        border-right-width: 0 !important;
    }

    table tr.hide-vertical-borders th,
    table tr.hide-vertical-borders td {
        border-left-width: 0 !important;
        border-right-width: 0 !important;
    }

    table thead.hide-vertical-borders tr th,
    table thead.hide-vertical-borders tr td,
    table tbody.hide-vertical-borders tr th,
    table tbody.hide-vertical-borders tr td {
        border-left-width: 0 !important;
        border-right-width: 0 !important;
    }

/*
    Show vertical borders on any table or on a single row or in header or body rows
*/

    /*table.show-vertical-borders,*/
    table.show-vertical-borders th,
    table.show-vertical-borders td {
        border-left: 1px solid #dee3e6 !important;
        border-right: 1px solid #dee3e6 !important;
    }

    table tr.show-vertical-borders th,
    table tr.show-vertical-borders td {
        border-left: 1px solid #dee3e6 !important;
        border-right: 1px solid #dee3e6 !important;
    }

    table thead.show-vertical-borders tr th,
    table thead.show-vertical-borders tr td,
    table tbody.show-vertical-borders tr th,
    table tbody.show-vertical-borders tr td {
        border-left: 1px solid #dee3e6 !important;
        border-right: 1px solid #dee3e6 !important;
    }

/*
    Horizontal Scrolling
    - Make sure there's a horizontal scrollbar to assure content can be accessed in a basic way if the table content pushes the table wider than the screen.
*/

table.scroll-all { display: block; overflow-x: auto; }

@media only screen and (max-width: 1920px) {
    table.scroll-larger { display: block; overflow-x: auto; }
}

@media only screen and (max-width: 1400px) {
    table.scroll-large { display: block; overflow-x: auto; }
}

@media only screen and (max-width: 1200px) {
    table.scroll-medium { display: block; overflow-x: auto; }
}

@media only screen and (max-width: 900px) {
    table.scroll-small { display: block; overflow-x: auto; }
}

@media only screen and (max-width: 600px) {
    table.scroll-micro { display: block; overflow-x: auto; }
}

@media only screen and (max-width: 375px) {
    table.scroll-none { display: block; overflow-x: auto; }
}

/* 
    Responsive Tables

    Notes: 
    - These rules work alongside our special javascript that calculates the content of simulated header cells.
    - Overall these should work fairly well for common table types. Don't expect complex tables to play nicely with these rules! You're on your own with complex tables.
    - Rules without !important are overridden by rules set on tables with special classes or inline rules, so they only apply to basic tables.
    - To do: The responsive breakpoint is currently hard coded. Give the author some control over when the table stacks, and perhaps rename the class to "stack". We'll need SASS for this so the code doesn't have to be duplicated.
*/

@media only screen and (max-width: 600px) {

    table caption {
        display: block;
    }

    /*
        Non-Responsive Tables
    */

        table:not(.responsive) {
            display: block; /* Note: An outside border on a table will expand to the scope of the block. */
            overflow-x: auto;
            border: 0; /* Otherwise tables with borders expand the scope of the border outside captions due to display:block; */
            th, td {
                /* This assures the table is at least 100% as wide as the page but it's possible it affects certain pre-defined cell widths aversely; you might need to set them inline at the content level, or with !important. */
                width: 100%;
            }
        }

    /*
        Responsive Tables
        - General logic and layout for all "responsive" tables.
    */

        /* Force table to not be like tables any more. */
        table.responsive,
        table.responsive thead,
        table.responsive tbody,
        table.responsive th,
        table.responsive td,
        table.responsive tr {
            display: block !important;
            width: auto !important;
        }

            /* 
                Hide table header rows (but not display: none;, for accessibility). 
                The .headersRow class is added by our Javascript to rows that consist entirely of header cells, whether in the thead or elsewhere in the table.
	        */
            table.responsive thead tr,
            table.responsive tr.headersRow {
                position: absolute !important;
                top: -99999px !important;
                left: -99999px !important;
            }

            table.responsive tr {
            }

            table.responsive th {
            }

            table.responsive tbody th:not([colspan]):not(:first-child) {
                /* 
                    - Assuming authors don't put table headers inside <thead> even though they can and should, hide table header cells (but not display: none;, for accessibility) unless it's the first cell in a row. 
                    - Note that the visual editor will always add a tbody tag, so we can detect table headers in the wrong place like this.  
                    - This will leave behind the first th in a row of th's, that's unfortunate. But please author, put your header rows in the right spot.
                    - Assume th cells with colspans are not individual column headings but rather section headings or something, so don't hide them. 
                */
                /* 
                    RW 20191211 For now, header cells are handled by a combination of our javascript and the header row rules above so maybe we don't need this, and of course it's better to leave the contents of a table alone wherever we can to avoid unexpected behavours with more complex tables.
                */
                /*
                    position: absolute !important;
                    top: -99999px !important;
                    left: -99999px !important;
                */
            }

            table.responsive td {
                /* Behave  like a "row". */
                display: flex !important;
                position: relative !important;
                overflow: auto !important; /* Table cells don't behave as you might expect with overflow. Without an overflow setting, ::before content tends to move outside the cell boundaries and get obscured so we explicitly define "auto" to avoid that. */
            }

                table.responsive td[style*="text-align:center"],
                table.responsive td[style*="text-align: center"] {
                    /* Centred cells and simulated headers within them generally look bad with these kinds of responsive tables, but consider this based on your design. Or override on individual cells using !important. */
                    text-align: left !important;
                }

                table.responsive td:not([colspan]) {
                }

                    table.responsive td:not([colspan])::before {
                        /* Behave like a table header. Note that the ::before content is populated by our javascript with a copy of what was in the associated header cell. */
                        position: relative !important;
                        flex-shrink: 0 !important;
                        flex-grow: 0 !important;
                        font-weight: bold;
                        width: 30%;
                        height: 100%;
                        margin-right: 10px;
                    }

                    table.responsive td:not([colspan]):first-child {
                        /* Table cells look like rows and might all look the same, so we generally want to highlight the first cell in each data row (e.g. making it look like a header cell) to make it clear where new records begin. This is a general setting that applies to all tables just in case, but it's usually better to design this separately for each of your table layouts. */
                        font-weight: bold;
                    }

                table.responsive td[colspan]::before {
                    /* Cells with colspans are probably special, don't put a label before them. */
                    display: none;
                }

    /*
        Individual Tables
    */

    table.dataTable1.responsive {
    }

        table.dataTable1.responsive caption {
        }

        table.dataTable1.responsive tbody > tr:first-child > :first-child {
            /* The first cell in the first row (because table headers are visually hidden for responsive tables). */
            border-top: 1px solid #fff;
        }

        table.dataTable1.responsive tr + tr {
            /*margin-top: 15px;*/ /* Separating rows like this can help the table feel like a fancy list. */
        }

        table.dataTable1.responsive th,
        table.dataTable1.responsive td {
            /* All headers and data cells. */
            border: 1px solid #d0cccb;
            border-bottom: 0;
        }

            table.dataTable1.responsive td::before {
                /* Cell labels (virtual header cells). */
            }

            table.dataTable1.responsive th:not([colspan]):first-child,
            table.dataTable1.responsive td:not([colspan]):first-child {
                /* The first cell in each row should look different (e.g. like a table header), to visually separate the rows. We ignore cells with colspans because they're probably special. */
                color: #fff !important;
                background-color: #0576b0 !important;
                border: 0 !important;
            }

            table.dataTable1.responsive th:not([colspan]):last-child,
            table.dataTable1.responsive td:not([colspan]):last-child {
                /* The last cell in each row. Sometimes it's a good idea to style this to help visually separate rows. */
                border-bottom: 1px solid #d0cccb;
                border-right: 1px solid #d0cccb;
            }
            
        table.dataTable1.theme-1.responsive {
        }

            table.dataTable1.theme-1.responsive th:not([colspan]):first-child,
            table.dataTable1.theme-1.responsive td:not([colspan]):first-child {
                /* The first cell in each row should look different (e.g. like a table header), to visually separate the rows. We ignore cells with colspans because they're probably special. */
                background-color: #358366 !important;
            }
            
        table.dataTable1.theme-2.responsive {
        }

            table.dataTable1.theme-2.responsive th:not([colspan]):first-child,
            table.dataTable1.theme-2.responsive td:not([colspan]):first-child {
                /* The first cell in each row should look different (e.g. like a table header), to visually separate the rows. We ignore cells with colspans because they're probably special. */
                background-color: #081018 !important;
            }
        
        table.dataTable1.theme-3.responsive {
        }

            table.dataTable1.theme-3.responsive th:not([colspan]):first-child,
            table.dataTable1.theme-3.responsive td:not([colspan]):first-child {
                /* The first cell in each row should look different (e.g. like a table header), to visually separate the rows. We ignore cells with colspans because they're probably special. */
                color: #000 !important;
                background-color: #cce7f0 !important;
            }
            
        table.dataTable1.theme-4.responsive {
        }

            table.dataTable1.theme-4.responsive th:not([colspan]):first-child,
            table.dataTable1.theme-4.responsive td:not([colspan]):first-child {
                /* The first cell in each row should look different (e.g. like a table header), to visually separate the rows. We ignore cells with colspans because they're probably special. */
                color: #000  !important;
                background-color: #dee3e6 !important;
            }

    table.dataTable2.responsive {
    }

        table.dataTable2.responsive caption {
        }

        table.dataTable2.responsive tbody > tr:first-child > :first-child {
            /* The first cell in the first row. */
        }

        table.dataTable2.responsive th,
        table.dataTable2.responsive td {
            /* All headers and data cells. */
            border-right: 0;
        }

            table.dataTable2.responsive td::before {
                /* Cell labels (virtual header cells). */
            }

            table.dataTable2.responsive th:not([colspan]):first-child,
            table.dataTable2.responsive td:not([colspan]):first-child {
                /* The first cell in each row. */
                color: #181008 !important;
                background-color: #fff !important;
                border-top: 0;
            }

            table.dataTable2.responsive th:not([colspan]):last-child,
            table.dataTable2.responsive td:not([colspan]):last-child {
                /* The last cell in each row. */
            }

        table.dataTable2.theme-2.responsive th,
        table.dataTable2.theme-2.responsive td {
            /* All headers and data cells. */
        }

            table.dataTable2.theme-2.responsive th:not([colspan]):first-child,
            table.dataTable2.theme-2.responsive td:not([colspan]):first-child {
                /* The first cell in each row. */
                color: #fff !important;
                background-color: #181008 !important;
            }

        table.dataTable2.theme-3.responsive th,
        table.dataTable2.theme-3.responsive td {
            /* All headers and data cells. */
        }

            table.dataTable2.theme-3.responsive th:not([colspan]):first-child,
            table.dataTable2.theme-3.responsive td:not([colspan]):first-child {
                /* The first cell in each row. */
                color: #000 !important;
                background-color: #cce7f0 !important;
            }

        table.dataTable2.theme-4.responsive th,
        table.dataTable2.theme-4.responsive td {
            /* All headers and data cells. */
        }

            table.dataTable2.theme-4.responsive th:not([colspan]):first-child,
            table.dataTable2.theme-4.responsive td:not([colspan]):first-child {
                /* The first cell in each row. */
                color: #000 !important;
                background-color: #dee3e6 !important;
            }

    table.dataTable3.responsive {
    }

        table.dataTable3.responsive caption {
        }

        table.dataTable3.responsive tbody > tr:first-child > :first-child {
            /* The first cell in the first row (because table headers are visually hidden for responsive tables). */
        }

        table.dataTable3.responsive th,
        table.dataTable3.responsive td {
            /* All headers and data cells. */
        }

            table.dataTable3.responsive td::before {
                /* Cell labels (virtual header cells) */
                position: relative;
                margin-top: -8px;
                margin-bottom: -8px;
                padding-top: 8px;
                padding-bottom: 8px;
                border-right: #fff 2px solid;
            }

            table.dataTable3.responsive th:not([colspan]):first-child,
            table.dataTable3.responsive td:not([colspan]):first-child {
                /* The first cell in each row. */
                color: #fff;
                background-color: #484038;
            }

            table.dataTable3.responsive th:not([colspan]):last-child,
            table.dataTable3.responsive td:not([colspan]):last-child {
                /* The last cell in each row. */
            }

    table.dataTable4.responsive {
    }

        table.dataTable4.responsive caption {
        }

        table.dataTable4.responsive tbody > tr:first-child > :first-child {
            /* The first cell in the first row. */
        }

        table.dataTable4.responsive th,
        table.dataTable4.responsive td {
            /* All headers and data cells. */
        }

            table.dataTable4.responsive td::before {
                /* Cell labels (virtual header cells) */
            }

            table.dataTable4.responsive th:not([colspan]):first-child,
            table.dataTable4.responsive td:not([colspan]):first-child {
                /* The first cell in each row. */
                color: #fff;
                background-color: #181008 !important;
            }

            table.dataTable4.responsive th:not([colspan]):last-child,
            table.dataTable4.responsive td:not([colspan]):last-child {
                /* The last cell in each row. */
            }

    table.simple.responsive {
        border: 0;
    }

        table.simple.responsive caption {
        }

        table.simple.responsive tr th,
        table.simple.responsive tr td {
            /* All headers and data cells. */
            border-bottom: 0;
        }

            table.simple.responsive td::before {
                /* Cell labels (virtual header cells) */
                font-weight: normal;
            }

            table.simple.responsive th:not([colspan]):first-child,
            table.simple.responsive td:not([colspan]):first-child,
            table.simple.responsive td:not([colspan]):first-child::before  {
                /* The first cell in each row. */
                font-weight: bold;
            }

            table.simple.responsive th:not([colspan]):last-child,
            table.simple.responsive td:not([colspan]):last-child {
                /* The last cell in each row. */
            }

    table.plain.responsive {
    }

        table.plain.responsive td:not([colspan]):first-child {
            /* Reset rules set for default tables, because plain tables should be plain. */
            font-weight: inherit;
            background: inherit;
        }

        table.plain.responsive td::before {
            display: none;
        }

    /* 
        Rounded Corners
        - By default we set up rounded corners on all tables, assuming that's how the general site design works. Alter this to target individual table layouts if needed.
        - Remember that header rows (e.g. thead full of th cells) are hidden by being moved off screen by the responsive tables Javascript plugin.
    */

        table.responsive > tr:first-child > :first-child,
        table.responsive thead:first-child tr:first-child > :first-child,
        table.responsive tbody:first-child tr:first-child > :first-child,
        table.responsive thead:first-child + tbody tr:first-child > :first-child,
        table.responsive caption + thead > tr:first-child > :first-child,
        table.responsive caption + thead + tbody > tr:first-child > :first-child,
        table.responsive caption + tbody > tr:first-child > :first-child {
            border-top-left-radius: 3px; /* Set to the design's border-radius. */
            border-top-right-radius: 3px; /* Set to the design's border-radius. */
        }

        table.responsive > tr:first-child > :last-child,
        table.responsive thead:first-child tr:first-child > :last-child,
        table.responsive tbody:first-child tr:first-child > :last-child,
        table.responsive thead:first-child + tbody tr:first-child > :last-child,
        table.responsive caption + thead > tr:first-child > :last-child,
        table.responsive caption + thead + tbody > tr:first-child > :last-child,
        table.responsive caption + tbody > tr:first-child > :last-child {
            border-top-right-radius: 0; /* Always set to 0. */
        }

        table.responsive > tr:last-child > :first-child,
        table.responsive > :last-child > tr:last-child > :first-child {
            border-bottom-left-radius: 0; /* Always set to 0. */
        }

        table.responsive > tr:last-child > :last-child,
        table.responsive > :last-child > tr:last-child > :last-child {
            border-bottom-left-radius: 3px; /* Set to the design's border-radius. */
            border-bottom-right-radius: 3px; /* Set to the design's border-radius. */
        }

    /* 
        Label the data 
        - This needs to be done for individual tables (and should be auto-calculated using Javascript tricks), but set up some defaults for nice layout, just in case.
    */

        table.responsive td:nth-of-type(1)::before { content: "Data 1"; }
        table.responsive td:nth-of-type(2)::before { content: "Data 2"; }
        table.responsive td:nth-of-type(3)::before { content: "Data 3"; }
        table.responsive td:nth-of-type(4)::before { content: "Data 4"; }
        table.responsive td:nth-of-type(5)::before { content: "Data 5"; }
        table.responsive td:nth-of-type(6)::before { content: "Data 6"; }
        table.responsive td:nth-of-type(7)::before { content: "Data 7"; }
        table.responsive td:nth-of-type(8)::before { content: "Data 8"; }
        table.responsive td:nth-of-type(9)::before { content: "Data 9"; }
        table.responsive td:nth-of-type(10)::before { content: "Data 10"; }
        table.responsive td:nth-of-type(11)::before { content: "Data 11"; }
        table.responsive td:nth-of-type(12)::before { content: "Data 12"; }
        table.responsive td:nth-of-type(13)::before { content: "Data 13"; }
        table.responsive td:nth-of-type(14)::before { content: "Data 14"; }
        table.responsive td:nth-of-type(15)::before { content: "Data 15"; }
        table.responsive td:nth-of-type(16)::before { content: "Data 16"; }
        table.responsive td:nth-of-type(17)::before { content: "Data 17"; }
        table.responsive td:nth-of-type(18)::before { content: "Data 18"; }
        table.responsive td:nth-of-type(19)::before { content: "Data 19"; }
        table.responsive td:nth-of-type(20)::before { content: "Data 20"; }

}
