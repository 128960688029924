/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
//@import "~@ionic/angular/css/typography.css";
//@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
/*@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";*/

/* 
    Acora App Theme 
    - The order matters. Cascading and overrides have been considered.

    1. Core 
    2. Priority Elements
    3. Other Elements
    4. Modules
    5. Custom Elements
    6. General Page Layout
*/
@import "./theme/mixins.scss";

/* Acora App Theme - Core */
@import "./theme/General/Fonts.scss";
@import "./theme/General/Typography.scss";
@import "./theme/General/Forms.scss";
@import "./theme/General/ContentCollections.scss";

/* Acora App Theme - Priority Elements */
@import "./theme/General/Blocks.scss";
@import "./theme/General/Boxes.scss";

/* Acora App Theme - Other Elements */
@import "./theme/General/Animations.scss";
@import "./theme/General/AlertBoxes.scss";
@import "./theme/General/Buttons.scss";
@import "./theme/General/Columns.scss";
@import "./theme/General/Icons.scss";
@import "./theme/General/Images.scss";
@import "./theme/General/Lists.scss";
@import "./theme/General/Tables.scss";

@import "./theme/General/ContentElements.scss";

/* Acora App Theme - Modules */
@import "./theme/Modules/ContentExpander.scss";
@import "./theme/Modules/Countdown.scss";
@import "./theme/Modules/Counter.scss";
@import "./theme/Modules/Ratings.scss";
@import "./theme/Modules/Tabs.scss";

/* Acora App Theme - Custom Elements */
//@import "./theme/Custom/CustomElements.scss";

/* Acora App Theme - General Page Layout */
@import "./theme/PageLayout.scss";
