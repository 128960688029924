// ###############################
//
// Icons
//
// ###############################

/* ####################################
   Icon Fonts
   ################################## */

/*@import url('/assets/icon/fontawesome-free/css/all.css');*/ /* Free version of Font Awesome. */
@import './assets/icon/fontawesome-pro/css/all.css'; /* Font Awesome Pro is much nicer than the free version but must be licensed if you want to use it in an app. */

/* ####################################
   Icon Configuration & Design

   - Note: Ems are used for dynamic sizing. Change this at your own risk.
   ################################## */

.icon {
}

    .icon::before,
    .icon::after {
        position: relative;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        vertical-align: middle;
        height: 1.25em; /* Must use dynamic units to help the text sizer. */
        width: 1.25em; /* Must use dynamic units to help the text sizer. */
        margin-right: 0.25em;
        text-align: center;
        font-family: 'Font Awesome 5 Pro'; /* The default font collection for the site. The collection can be changed manually using secondary classes - e.g. .fa, .fas, .far, .fal, .fad, .fab for Font Awesome. */
        font-weight: 500; /* The default icon weight for the site. It can be changed manually on each icon if your icon font supports it. Note: Font Awesome free version only supports 700 for the full icon set. */
        font-size: 1em; /* Bigger than the text in the associated element is a more */
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1.25em;
        /*border: #f0eceb 1px dashed;*/
    }

i.icon, 
span.icon {
    /* An inline tag with only an icon in it. We only include the "i" tag because some icon libraries encourage it, even if they shouldn't. */
}

/* Icons in buttons often need a slightly different treatment. */

.button.icon::before {
    color: inherit;
    margin-right: 0.25em;
    margin-top: -0.125em;
}

.button-large.icon::before {
    margin-top: -0.25em;
}

.button.icon:empty::before {
    margin-right: 0;
    margin-top: 0;
}

/* Some icon fonts support different styles at different font weights. */

.icon-style-thin::before,
.icon-style-thin::after {
    font-weight: 100;
}

.icon-style-line::before,
.icon-style-line::after {
    font-weight: 300;
}

.icon-style-standard::before,
.icon-style-standard::after {
    font-weight: 500;
}

.icon-style-solid::before,
.icon-style-solid::after {
    font-weight: 700;
}

/* 
    Icon sizes are relative to the element's font size.
    - Note: Vertical alignment gets a bit hairy at larger sizes... The top/bottom margins are designed to try to avoid affecting line heights if used inline within text in standard layouts, and also to "fix" the strange visual effect of vertical-align:middle aligning to the middle of the lower case letters in the text of the element. They need adjusting depending on the site's base font and size or in individual contexts.
*/

.icon-small::before,
.icon-small::after {
    font-size: 0.5em;
    margin-top: -0.25em;
}

.icon-medium::before,
.icon-medium::after {
    font-size: 1em;
    margin-top: -0.375em;
}

.icon-large::before,
.icon-large::after {
    font-size: 2em;
    margin-top: -0.075em;
    margin-right: 0.125em;
}

.icon-larger::before,
.icon-larger::after {
    font-size: 3em;
    margin-top: -0.075em;
    margin-right: 0.125em;
}

.icon-huge::before,
.icon-huge::after {
    font-size: 4.5em;
    margin-right: 0.075em;
}

.icon-mega::before,
.icon-mega::after {
    font-size: 6em;
    margin-right: 0.025em;
}

/* Icon layouts - Main color */

.icon-circle-white::before {
    padding: 0.5em;
    color: #0081A8 !important;
    background-color: #fff;
    border-radius: 100%;
}

.icon-circle-light::before {
    padding: 0.5em;
    color: #0081A8 !important;
    background-color: #f8fbfc;
    border-radius: 100%;
}

.icon-circle-dark::before {
    padding: 0.5em;
    color: #fff !important;
    background-color: #0081A8;
    border-radius: 100%;
}

.icon-circle-black::before {
    padding: 0.5em;
    color: #fff !important;
    background-color: #000;
    border-radius: 100%;
}

.icon-box-white::before {
    padding: 0.5em;
    color: #0081A8 !important;
    background-color: #fff;
}

.icon-box-light::before {
    padding: 0.5em;
    color: #0081A8 !important;
    background-color: #f8fbfc;
}

.icon-box-dark::before {
    padding: 0.5em;
    color: #fff !important;
    background-color: #0081A8;
}

.icon-box-black::before {
    padding: 0.5em;
    color: #fff !important;
    background-color: #000;
}

/* Icon layouts - Alternate color */

.icon-circle-white-alternate::before {
    padding: 0.5em;
    color: #358366 !important;
    background-color: #fff;
    border-radius: 100%;
}

.icon-circle-light-alternate::before {
    padding: 0.5em;
    color: #358366 !important;
    background-color: #f6fbf8;
    border-radius: 100%;
}

.icon-circle-dark-alternate::before {
    padding: 0.5em;
    color: #fff !important;
    background-color: #358366;
    border-radius: 100%;
}

.icon-circle-black-alternate::before {
    padding: 0.5em;
    color: #fff !important;
    background-color: #000;
    border-radius: 100%;
}

.icon-box-white-alternate::before {
    padding: 0.5em;
    color: #358366 !important;
    background-color: #fff;
}

.icon-box-light-alternate::before {
    padding: 0.5em;
    color: #358366 !important;
    background-color: #f6fbf8;
}

.icon-box-dark-alternate::before {
    padding: 0.5em;
    color: #fff !important;
    background-color: #358366;
}

.icon-box-white-alternate::before {
    padding: 0.5em;
    color: #fff !important;
    background-color: #000;
}

/* Icon styles */

.icon-outline::before {
    border: #358366 2px solid;
}

.icon-stacked {
}

    .icon-stacked::before,
    .icon-stacked::after {
        display: block;
        margin-bottom: 0.25em;
    }

        .text-center .icon-stacked::before,
        .text-center .icon-stacked::after,
        [style*="text-align:center"] .icon-stacked::before,
        [style*="text-align:center"] .icon-stacked::after,
        [style*="text-align: center"] .icon-stacked::before,
        [style*="text-align: center"] .icon-stacked::after,
        [style*="text-align:center"].icon-stacked::before,
        [style*="text-align:center"].icon-stacked::after,
        [style*="text-align: center"].icon-stacked::before,
        [style*="text-align: center"].icon-stacked::after {
            margin-left: auto;
            margin-right: auto;
        }

        .text-left .icon-stacked::before,
        .text-left .icon-stacked::after,
        [style*="text-align:left"] .icon-stacked::before,
        [style*="text-align:left"] .icon-stacked::after,
        [style*="text-align: left"] .icon-stacked::before,
        [style*="text-align: left"] .icon-stacked::after,
        [style*="text-align:left"].icon-stacked::before,
        [style*="text-align:left"].icon-stacked::after,
        [style*="text-align: left"].icon-stacked::before,
        [style*="text-align: left"].icon-stacked::after {
            margin-left: inherit;
        }

        .text-right .icon-stacked::before,
        .text-right .icon-stacked::after,
        [style*="text-align:right"] .icon-stacked::before,
        [style*="text-align:right"] .icon-stacked::after,
        [style*="text-align: right"] .icon-stacked::before,
        [style*="text-align: right"] .icon-stacked::after,
        [style*="text-align:right"].icon-stacked::before,
        [style*="text-align:right"].icon-stacked::after,
        [style*="text-align: right"].icon-stacked::before,
        [style*="text-align: lerightft"].icon-stacked::after {
            margin-right: inherit;
        }
   
/*
    Duotone icon colors
    - Only relevant if a duotone icon set is used
    - If settign colours declaratively, remember they will take effect within other elemtns including buttons, boxes, etc.
*/

.icon::before { color: inherit; }
.icon::after { opacity: 0.5; }

/*
    Colors will probably be custom for each site but we include the standard theme palette colours as a starting point.
*/

.icon-white::before { color: #fff; } /* Note: Icons adopt the font colour so they'll be white on dark backgrounds already. This is only needed for special scenarios. */
.icon-lighter::before { color: #faf8f7; }
.icon-light::before { color: #f0eceb; }
.icon-penumbra::before { color: #6c6866; }
.icon-dark::before { color: #484038; }
.icon-darker::before { color: #181008; }

/*
    "Only" an icon: hide everything in the tag except the icon.
    - Be mindful of accessibility when using this, extra content markup might be required
*/

.icon.icon-only {
    display: inline-block;
    overflow: hidden;
    width: 1.25em;
    height: 1.25em;
    padding: 0.5em;
}

    .icon.icon-only[class*=icon-circle],
    .icon.icon-only[class*=icon-box] {
    }

        .icon.icon-only[class*=icon-circle]::before,
        .icon.icon-only[class*=icon-circle]::after,
        .icon.icon-only[class*=icon-box]::before,
        .icon.icon-only[class*=icon-box]::after {
            margin-top: -0.5em;
            margin-left: -0.5em;
        }

    .icon.icon-only.icon-small {
        width: 0.625em;
        height: 0.625em;
        padding: 0.25em;
    }

        .icon.icon-only.icon-small[class*=icon-circle]::before,
        .icon.icon-only.icon-small[class*=icon-circle]::after,
        .icon.icon-only.icon-small[class*=icon-box]::before,
        .icon.icon-only.icon-small[class*=icon-box]::after {
            margin-top: -1.75em;
        }

    .icon.icon-only.icon-medium {
        width: 1.25em;
        height: 1.25em;
        padding: 0.5em;
    }

    .icon.icon-only.icon-large {
        width: 2.5em;
        height: 2.5em;
        padding: 1em;
    }

    .icon.icon-only.icon-larger {
        width: 3.75em;
        height: 3.75em;
        padding: 1.5em;
    }

    .icon.icon-only.icon-huge {
        width: 5.625em;
        height: 5.625em;
        padding: 2.25em;
    }

    .icon.icon-only.icon-mega {
        width: 7.5em;
        height: 7.5em;
        padding: 3em;
    }

    .icon.icon-only::before,
    .icon.icon-only::after {
        margin-right: 0;
    }

    a.icon.icon-only:link {
    }

        a.icon.icon-only:link:hover {
            background-color: inherit;
        }

            a.icon.icon-only[class*=icon-circle]:link:hover::before,
            a.icon.icon-only[class*=icon-circle]:link:hover::after,
            a.icon.icon-only[class*=icon-box]:link:hover::before,
            a.icon.icon-only[class*=icon-box]:link:hover::after,
            a.icon.icon-only[class*=icon-circle]:visited:hover::before,
            a.icon.icon-only[class*=icon-circle]:visited:hover::after,
            a.icon.icon-only[class*=icon-box]:visited:hover::before,
            a.icon.icon-only[class*=icon-box]:visited:hover::after {
                background-color: #c23000;
            }

/*
    Custom icons or specialised styles
*/

.icon-style-1 {
    position: relative;
    z-index: 2;
    display: inline-block;
    vertical-align: middle;
    width: 6.5em;
    height: 1em;
    margin-top: 4.75em;
    margin-bottom: 1.25em;
    transform: rotate( -7.5deg);
    font-size: 1.75em;
    font-weight: bold;
    font-family: Cambria, Arial, Arial, Helvetica, sans-serif;
    font-style: italic;
    color: #4B0082;
    text-shadow: 1px 1px #fff;
    background: rgb(224,220,219);
    border: #fff 5px solid;
    border-radius: 100%;
    box-shadow: rgb(0 153 253 / 25%) 0 0 25px;
    background: rgba(0,153,253,0.25);
    cursor: pointer;
    transition: all 150ms ease-in-out;
}

    .icon-style-1::before {
        position: relative;
        z-index: -1;
        margin-top: -2.25em;
        margin-bottom: -0.7em;
        padding: 0.4em 0.5em 0.6em;
        transform: rotate(7.5deg);
        font-size: 2em;
        color: #800080;
        text-shadow: -3px -3px #d8bfd8;
        background: linear-gradient(40deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
        border: #fff 0.1875em solid;
        box-shadow: 0px 0px 15px rgba(53,42,31,0.25);
        transition: all 150ms ease-in-out;
    }

    .icon-style-1:nth-of-type(3n-1) {
        transform: rotate(0);
    }

        .icon-style-1:nth-of-type(3n-1)::before {
            transform: rotate(0);
        }

    .icon-style-1:nth-of-type(3n) {
        transform: rotate(7.5deg);
    }

        .icon-style-1:nth-of-type(3n)::before {
            transform: rotate(-7.5deg);
        }

    .icon-style-1:hover {
        transform: scale(1.25) translateX(-0.125em) translateY(0.25em);
    }

        .icon-style-1:hover::before {
            transform: rotate(0deg);
            filter: blur(3px);
            opacity: 0.75;
        }
   
/* ####################################
    Icons
    ################################## */

/*
    Common CMS Icons
    - Always populate these elements, no matter what icon collection is used. That way the core CMS remains fully supported.
    - Note: You can also use names from the icon's font specification, but these might not be supported across different CMS versions or font sets so you will need to manage them yourself.

    To consider:
        Arrows
        Chevrons
        Carets
        Plus Minus
        Tick Cross
        Desktop f108
        Laptop f109
        Mobile f3cd
        Tablet f3fa
        Star
        Quotes
        Spinners?
        Thumbs up/down?

*/

.icon-alert::before { content: "\f06a"; }
.icon-attachment::before { content: "\f0c6"; }
.icon-audio::before { content: "\f028"; }
.icon-audio-description::before { content: "\f29e"; }
.icon-bell::before { content: "\f0f3"; }
.icon-blog::before { content: "\f781"; }
.icon-book::before { content: "\f02d"; }
.icon-book-reader::before { content: "\f5da"; }
.icon-bookmark::before { content: "\f02e"; }
.icon-box::before { content: "\f466"; }
.icon-boxes::before { content: "\f468"; }
.icon-calendar::before { content: "\f073"; }
.icon-calendar-add::before { content: "\f271"; }
.icon-camera::before { content: "\f083"; }
.icon-cart::before { content: "\f07a"; }
.icon-cart-add::before { content: "\f217"; }
.icon-clock::before { content: "\f017"; }
.icon-closed-captioning::before { content: "\f20a"; }
.icon-comment::before { content: "\f4ad"; }
.icon-contact::before { content: "\f095"; }
.icon-copyright::before { content: "\f1f9"; }
.icon-credit-card::before { content: "\f09d"; }
.icon-download::before { content: "\f019"; }
.icon-edit::before { content: "\f044"; }
.icon-email::before { content: "\f0e0"; }
.icon-eye::before { content: "\f06e"; }
.icon-external-link::before { content: "\f360"; }
.icon-feedback::before { content: "\f086"; }
.icon-globe::before { content: "\f0ac"; }
.icon-help::before { content: "\f059"; }
.icon-home::before { content: "\f015"; }
.icon-image::before { content: "\f03e"; }
.icon-images::before { content: "\f302"; }
.icon-link::before { content: "\f0c1"; }
.icon-lock::before { content: "\f023"; }
.icon-map::before { content: "\f279"; }
.icon-map-marked::before { content: "\f5a0"; }
.icon-map-marker::before { content: "\f3c5"; }
.icon-microphone::before { content: "\f3c9"; }
.icon-paperclip::before { content: "\f0c6"; }
.icon-pencil::before { content: "\f303"; }
.icon-play::before { content: "\f04b"; }
.icon-podcast::before { content: "\f2ce"; }
.icon-presentation::before { content: "\f26c"; }
.icon-print::before { content: "\f02f"; }
.icon-rss::before { content: "\f09e"; }
.icon-search::before { content: "\f002"; }
.icon-share::before { content: "\f14d"; }
.icon-shipping::before { content: "\f48b"; }
.icon-sign-in::before { content: "\f2f6"; }
.icon-sign-out::before { content: "\f2f5"; }
.icon-sitemap::before { content: "\f0e8"; }
.icon-spreadsheet::before { content: "\f0ce"; }
.icon-tools::before { content: "\f7d9"; }
.icon-trademark::before { content: "\f25c"; }
.icon-trash::before { content: "\f2ed"; }
.icon-user::before { content: "\f007"; }
.icon-user-check::before { content: "\f4fc"; }
.icon-user-circle::before { content: "\f2bd"; }
.icon-user-lock::before { content: "\f502"; }
.icon-user-profile::before { content: "\f2bb"; }
.icon-user-shield::before { content: "\f505"; }
.icon-users::before { content: "\f500"; }
.icon-users2::before { content: "\f0c0"; }
.icon-universal-access::before { content: "\f29a"; }
.icon-video::before { content: "\f03d"; }
.icon-warning::before { content: "\f071"; }

/*
    Documents
*/

.icon-csv::before { content: "\f6dd"; }
.icon-file::before { content: "\f15c"; }
.icon-folder::before { content: "\f07b"; }
.icon-folder-open::before { content: "\f07c"; }
.icon-pdf::before { content: "\f1c1"; }
.icon-powerpoint::before { content: "\f1c4"; }
.icon-word::before { content: "\f1c2"; }
.icon-xls::before { content: "\f1c3"; }
.icon-zip::before { content: "\f1c6"; }

/*
    Social Icons
*/

.icon-brand::before { font-family: 'Font Awesome 5 Brands';}
.icon-facebook::before { font-family: 'Font Awesome 5 Brands'; content: "\f39e"; } /* Alternates: f082 (square), f09a (circle) */
.icon-facebook-messenger::before { font-family: 'Font Awesome 5 Brands'; content: "\f39f"; }
.icon-instagram::before { font-family: 'Font Awesome 5 Brands'; content: "\f16d"; } /* Alternates: e055 (square) */
.icon-linkedin::before { font-family: 'Font Awesome 5 Brands'; content: "\f0e1"; } /* Alternates: f08c (square) */
.icon-pinterest::before { font-family: 'Font Awesome 5 Brands'; content: "\f231"; } /* Alternates: f0d3 (square), f0d2 (circle) */
.icon-twitter::before { font-family: 'Font Awesome 5 Brands'; content: "\f099"; } /* Alternates: f081 (square) */
.icon-vimeo::before { font-family: 'Font Awesome 5 Brands'; content: "\f27d"; } /* Alternates: f194 (square) */
.icon-youtube::before { font-family: 'Font Awesome 5 Brands'; content: "\f167"; } /* Alternates: f432 (square) */

/*
    App Stuff
*/

[class*=icon-news]::before,
[class*=icon-blog]::before {
    content: "\f1ea";
}
[class*=icon-contact]::before {
    content: "\f87a";
}
[class*=icon-search]::before {
    content: "\f1ea";
}
[class*=icon-form]::before {
    content: "\f733"; /* Ballot Check */
    content: "\f56c"; /* File Contract */
}
[class*=icon-survey]::before {
    content: "\f733"; /* Ballot Check */
    content: "\f681"; /* Poll */
}
[class*=icon-vote]::before {
    content: "\f759";
}
[class*=icon-forms]::before {
    content: "\f573"; /* File Signature */
    content: "\f56c"; /* File Contract */
    content: "\f570"; /* Invoice */
}
[class*=icon-legal]::before,
[class*=icon-contract]::before {
    content: "\f56c"; /* File Contract */
    content: "\f515"; /* Balance Scale Left */
}
[class*=icon-search]::before {
    content: "\f002";
}
[class*=icon-login]::before {
    content: "\f2f6";
}
[class*=icon-logout]::before {
    content: "\f2f5";
}
[class*=icon-inbox]::before {
    content: "\f310"; /* Inbox */
}
[class*=icon-notifications]::before {
    content: "\f310"; /* Inbox */
    content: "\f813"; /* Mailbox */
    content: "\f310"; /* Bell */
    content: "\f4ad"; /* Comments - Lines */
}
[class*=icon-email]::before {
    content: "\f199"; /* Envelope Square */
    content: "\f1d8"; /* Paper Plane */
    content: "\f674"; /* Mail Bulk */
    content: "\f0e0"; /* Email */
}
[class*=icon-dining]::before,
[class*=icon-current-meal]::before,
[class*=icon-meal-current]::before {
    content: "\f2e7";
}
[class*=icon-credits]::before,
[class*=icon-][class*=-credits]::before {
    content: "\f788";
}
[class*=icon-portal]::before,
[class*=icon-student]::before {
    content: "\f2c2";
}
[class*=icon-website]::before {
    content: "\f0ac";
}
[class*=icon-user]::before {
    content: "\f0c0";
}
[class*=icon-users]::before {
    content: "\f63d";
}
[class*=icon-profile]::before {
    content: "\f007"; /* User */
    content: "\f2bd"; /* User Circle */
}
[class*=icon-account]::before {
    content: "\f007"; /* User */
    content: "\f2bd"; /* User Circle */
    content: "\f4fe"; /* User Cog */
}
[class*=icon-settings]::before {
    content: "\f013";
}
[class*=icon-activate]::before,
[class*=icon-register]::before {
    content: "\f4fc"; /* User Tick */
}
[class*=icon-page]::before,
[class*=icon-knowledge]::before {
    content: "\f15c";
}
[class*=icon-archive]::before {
    content: "\f4a1"; /* Boxes Alt */
    content: "\f187"; /* Archive Box */
}
[class*=icon-history]::before {
    content: "\f1da";
}
[class*=icon-presentation]::before,
[class*=icon-webinar]::before {
    content: "\f685"; /* Presentation */
    content: "\f63d"; /* Users Class */
    content: "\f51c"; /* Chalkboard Teacher */
}
[class*=icon-dashboard]::before {
    content: "\f5fd";
}
[class*=icon-alumni]::before,
[class*=icon-graduate]::before {
    content: "\f501"; /* User Graduate */
    content: "\f19d"; /* Graduation Cap */
}
[class*=icon-jobs]::before,
[class*=icon-posiions]::before {
    content: "\f0c0"; /* Users */
    content: "\f500"; /* User Friends */
    content: "\e068"; /* People Arrows */
}
[class*=icon-tool]::before {
    content: "\f0ad"; /* Wrench */
    content: "\f7d9"; /* Tools */
}
[class*=icon-calendar]::before,
[class*=icon-events]::before,
[class*=icon-roster]::before,
[class*=icon-schedule]::before,
[class*=-schedule]::before {
    content: "\f073";
}
[class*=icon-calculator]::before,
[class*=icon-salary]::before {
    content: "\f1ec";
}
[class*=icon-benefits]::before,
[class*=icon-privileges]::before {
    content: "\f830";
}
[class*=icon-factsheets]::before,
[class*=icon-guides]::before {
    content: "\f15c"; /* File Alt */
    content: "\f65b"; /* File Spreadsheet - There are plenty of others */
}
[class*=icon-video]::before {
    content: "\f03d";
}
[class*=icon-audio]::before {
    content: "\f028";
}
[class*=icon-podcast]::before {
    content: "\f130"; /* Microphone */
    content: "\f2ce"; /* Podcast */
}
[class*=icon-music]::before,
[class*=icon-playlist]::before {
    content: "\f8c9"; /* List Music */
}
[class*=icon-livecam]::before,
[class*=icon-live-cam]::before {
    content: "\f8fe"; /* Camera Home */
    content: "\f832"; /* Webcam */
}
[class*=icon-images]::before {
    content: "\f302"; /* Images */
    content: "\f8c4"; /* Polarid */
}
[class*=icon-media]::before,
[class*=icon-gallery]::before {
    content: "\f87c";
}
[class*=icon-contacts]::before,
[class*=icon-directory]::before {
    content: "\f2b9"; /* Address Bok */
    content: "\f2bb"; /* Address Card */
}
[class*=icon-sitemap]::before,
[class*=icon-content]::before {
    content: "\f0e8"; 
}
[class*=icon-folders]::before,
[class*=icon-files]::before {
    content: "\f802"; /* Folder Tree */
    content: "\f07c"; /* Open Folder */
}
[class*=icon-database]::before {
    content: "\f1c0";
}
[class*=icon-map]::before,
[class*=icon-locations]::before {
    content: "\f5a0"; /* Map Marked Alt */
    content: "\f279"; /* Open Folder */
}
[class*=icon-directions]::before,
[class*=icon-locations]::before {
    content: "\f85a"; /* Car Bus */
    content: "\f5eb"; /* Directions */
}
[class*=icon-security]::before,
[class*=icon-locations]::before {
    content: "\f505"; /* User Shield */
    content: "\f30d"; /* Lock */
    content: "\f3ed"; /* Shield */
}
[class*=icon-lock]::before {
    content: "\f30d"; 
}
[class*=icon-info]::before {
    content: "\f05a"; 
}
[class*=icon-help]::before {
    content: "\f059"; 
}
[class*=icon-health]::before,
[class*=icon-wellbeing]::before {
    content: "\f4be"; /* Hand Holding Heart */
    content: "\f164"; /* Thumbs Up */
    content: "\f809"; /* Head Side Medical */
    content: "\f4c3"; /* Hands Heart */
}
[class*=icon-incident]::before,
[class*=icon-injury]::before {
    content: "\f728"; /* User Injured */
}
[class*=icon-library]::before,
[class*=icon-resources]::before {
    content: "\f02d"; /* Book */
    content: "\f5db"; /* Books */
}
[class*=icon-download]::before {
    content: "\f019"; /* Download */
    content: "\f0ed"; /* Cloud Download */
    content: "\f381"; /* Cloud Download Alt */
}
[class*=icon-upload]::before {
    content: "\f093"; /* Upload */
    content: "\f033"; /* Cloud Upload */
    content: "\f382"; /* Cloud Upload Alt */
}
[class*=icon-book]::before,
[class*=icon-handbook]::before {
    content: "\f02d"; 
}
[class*=icon-support]::before {
    content: "\e05c"; /* Hand Holding Medical */
    content: "\f82d"; /* User Headset */
}
[class*=icon-share]::before {
    content: "\f1e1"; /* Share Alt Square */
    content: "\f14d"; /* Share square */
    content: "\f14d"; /* Share square */
}
[class*=icon-link]::before,
[class*=icon-outlink]::before {
    content: "\f14c"; /* External Link Square */
    content: "\f0c1"; /* Link */
}
[class*=icon-analytics]::before,
[class*=icon-reports]::before,
[class*=icon-charts]::before  {
    content: "\f080"; /* Chart Bar */
    content: "\f200"; /* Chart Pie */
    content: "\f201"; /* Chart Line */
    content: "\f6a3"; /* User Chart */
    content: "\f643"; /* Analytics */
}
[class*=icon-shop]::before,
[class*=icon-cart]::before,
[class*=icon-checkout]::before  {
    content: "\f07a"; 
}
[class*=icon-order]::before,
[class*=icon-past-orders]::before  {
    content: "\f847"; /* Bags Shopping */
    content: "\f4de"; /* Truck Loading */
    content: "\f88e"; /* Sort Size Up */
}
[class*=icon-shipping]::before,
[class*=icon-delivery]::before,
[class*=icon-track]::before ,
[class*=icon-order-track]::before   {
    content: "\f8f4dc47"; /* Truck Container */
    content: "\f0d1"; /* Truck */
    content: "\f48c"; /* Shipping Timed (Truck) */
    content: "\f48b"; /* Shipping Fast (Truck) */
}
[class*=icon-packing]::before  {
    content: "\f49c"; /* Box Full */ 
}
[class*=icon-payment]::before  {
    content: "\f09d"; /* Credit Card - There are plenty of others */ 
}
[class*=icon-gift]::before  {
    content: "\f06b"; 
}
[class*=icon-donate]::before,
[class*=icon-donation]::before,
[class*=icon-bequest]::before {
    content: "\f004"; /* Heart */ 
    content: "\f4bc"; /* Hand Heart */ 
    content: "\f4c0"; /* Hand Holding USD */ 
    content: "\f4c5"; /* Hands USD */ 
    content: "\f4b9"; /* Donate */ 
    content: "\e05c"; /* Hand Holding Medical */ 
}
[class*=icon-inventory]::before,
[class*=icon-stock]::before {
    content: "\f482"; /* Pallet */ 
    content: "\f480"; /* Inventory (Shelves) */ 
    content: "\f49f"; /* Box Up */ 
}
[class*=icon-forums]::before,
[class*=icon-chat]::before,
[class*=icon-social]::before {
    content: "\f4b6"; 
}
[class*=icon-like]::before,
[class*=icon-favourite]::before,
[class*=icon-favorite]::before {
    content: "\f005"; /* Start - There are plenty of others */ 
}
[class*=icon-tag]::before {
    content: "\f02c"; 
}
[class*=icon-emergency]::before,
[class*=icon-alert]::before,
[class*=icon-critical]::before {
    content: "\f071"; 
}
[class*=icon-cloud]::before {
    content: "\f0c2"; 
}
[class*=icon-view]::before {
    content: "\f06e"; 
}
[class*=icon-edit]::before,
[class*=icon-manage]::before {
    content: "\f303";  /* Pencil Alt */
    content: "\f044";  /* Edit (pencil in box) */
}
[class*=icon-delete]::before,
[class*=icon-cancel]::before,
[class*=icon-remove]::before {
    content: "\f057";  /* Times Circle */
    content: "\f2ed";  /* Trash Alt */
}
[class*=icon-print]::before {
    content: "\f02f"; 
}
[class*=icon-test]::before {
    content: "\f0c3"; /* Flask */
    content: "\f5d3"; /* Atom Alt */
}