/* ####################################
   Tabs

   - Critical Rendering Path: Optional
   ################################## */

.tabs {
    position: relative;
    clear: both;
    padding-bottom: 34px; /* Addition of top and bottom vertical padding + border of .tab */
    margin-bottom: 1.5em;
}

    .tabs ul.tabList {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        margin: 0 !important;
        padding: 0 !important;
    }

        .tabs ul.tabList li {
            list-style: none;
            box-sizing: border-box;
            margin: 0 !important;
            padding-left: 2px;
        }

            .tabs ul.tabList li:first-child {
                padding-left: 0;
            }

            .tabs ul.tabList li a:link {
                display: inline-flex;
                align-items: center;
                box-sizing: border-box;
                width: 100%;
                padding: 15px 30px;
                font-size: 115.79%; /* 22px if the site started at 19px. */
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

                .tabs ul.tabList li a:link:not(:hover) {
                    text-decoration: none !important;
                    color: #c23000;
                    background: #f0eceb;
                }

            .tabs ul.tabList li.selected {
                padding-right: 0;
            }

                .tabs ul.tabList li.selected a:link {
                    position: relative;
                    z-index: 3;
                    margin-bottom: -1px;
                    /*font-size: 1.125rem;*/
                    color: inherit;
                    background-color: #fff;
                    border: #c0bcbb 1px solid;
                    border-bottom: 0;
                }

    /*
    .tabs.tabCount2 ul.tabList li { max-width: 50%; }
    .tabs.tabCount3 ul.tabList li { max-width: 33.33%; }
    .tabs.tabCount4 ul.tabList li { max-width: 25%; }
    .tabs.tabCount5 ul.tabList li { max-width: 20%; }
    .tabs.tabCount6 ul.tabList li { max-width: 16.67%; }
    .tabs.tabCount7 ul.tabList li { max-width: 14.28%; }
    .tabs.tabCount8 ul.tabList li { max-width: 12.5%; }
    .tabs.tabCount9 ul.tabList li { max-width: 11.11%; }
    .tabs.tabCount10 ul.tabList li { max-width: 10%; }
    */
    
    .tabs.space-evenly.tabCount2 ul.tabList li { width: 50%; }
    .tabs.space-evenly.tabCount3 ul.tabList li { width: 33.33%; }
    .tabs.space-evenly.tabCount4 ul.tabList li { width: 25%; }
    .tabs.space-evenly.tabCount5 ul.tabList li { width: 20%; }
    .tabs.space-evenly.tabCount6 ul.tabList li { width: 16.67%; }
    .tabs.space-evenly.tabCount7 ul.tabList li { width: 14.28%; }
    .tabs.space-evenly.tabCount8 ul.tabList li { width: 12.5%; }
    .tabs.space-evenly.tabCount9 ul.tabList li { width: 11.11%; }
    .tabs.space-evenly.tabCount10 ul.tabList li { width: 10%; }

    .tabs .tabContent {
        position: relative;
        display: block;
        overflow: hidden;
    }

        .tabs .tab { /* Matches tabs in the CMS visual editor and on the front end. */
            box-sizing: border-box;
            width: 100%;
            padding: 30px;
            border: #c0bcbb 1px solid;
        }

        .tabs .tabContent .tab { /* Matches tabs on the front end only. */
            position: absolute;
            opacity: 0;
        }

            .tabs .tab > :first-child {
                margin-top: 0 !important;
            }

            .tabs .tab > :last-child {
                margin-bottom: 0 !important;
            }

.tabs[class*=background-] {
    background-color: inherit;
    color: inherit;
}

    .tabs[class*=background-] ul.tabList li.selected a:link {
        border: 0;
    }

    .tabs[class*=background-] .tab {
        border: 0;
    }

.tabs.background-lighter {
}

    .tabs.background-lighter .tab {
        background-color: #faf8f7;
    }

    .tabs.background-lighter ul.tabList li.selected a:link {
        background-color: #faf8f7;
    }

.tabs.background-light {
}

    .tabs.background-light .tab {
        background-color: #dcd6d0;
        background-color: #f0eceb;
    }

    .tabs.background-light ul.tabList li a:link:not(:hover) {
        background-color: #faf8f7;
    }

    .tabs.background-light ul.tabList li.selected a:link {
        background-color: #dcd6d0;
        background-color: #f0eceb;
    }

.tabs.background-penumbra {
}

    .tabs.background-penumbra .tab {
        color: #fff;
        background-color: #6c6862;
    }

    .tabs.background-penumbra ul.tabList li.selected a:link {
        color: #fff;
        background-color: #6c6862;
    }

.tabs.background-dark {
}

    .tabs.background-dark .tab {
        color: #fff;
        background-color: #484038;
    }

    .tabs.background-dark ul.tabList li.selected a:link {
        color: #fff;
        background-color: #484038;
    }

.tabs.background-darker {
}

    .tabs.background-darker .tab {
        color: #fff;
        background-color: #181008;
    }

    .tabs.background-darker ul.tabList li.selected a:link {
        color: #fff;
        background-color: #181008;
    }

@media screen and (max-width: 900px) {

    .tabs ul.tabList li a:link {
        padding: 15px;
        font-size: 84.21%; /* 16px if the site started at 19px. */
    }

    .tabs .tabContent .tab {
        padding: 15px;
    }

}

@media screen and (max-width: 600px) {

    .tabs ul.tabList { 
        flex-wrap: wrap;
    }

        .tabs ul.tabList li {
            width: 50% !important;
            max-width: 50% !important;
        }

        .tabs ul.tabList li:nth-child(2n) {
            padding-right: 0;
        }

        .tabs ul.tabList li:nth-child(2n+1) {
            padding-left: 0;
        }

        .tabs ul.tabList li:last-child:not(:nth-child(2n)) {
            width: 100% !important;
            max-width: 100% !important;
        }

            .tabs ul.tabList li a:link {
                margin-bottom: 2px !important;
                border-radius: 0;
            }

            .tabs:not([class*=background-]) ul.tabList li a:link {
                border: #c0bcbb 1px solid;
            }

            .tabs ul.tabList li:first-child a:link {
                border-top-left-radius: 4px;
            }

            .tabs ul.tabList li:nth-child(2) a:link {
                border-top-right-radius: 4px;
            }

}